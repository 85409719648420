import { MouseEvent, useCallback } from "react"

import { Subitem } from "../Subitem"
import { ChevronDownSvg } from "~/ui-rtk/components/ui/svg/arrow"
import { SessionRole } from "~/api/dto/auth/session-role"

import { cn } from "~/ui-rtk/utils/tailwind-utils"

import { useConnect } from "./connect"

import type { TSvgHOCProps } from "~/ui-rtk/components/ui/common/SvgHOC"
import type { NavItem } from "../types"
import { TPosthogFeatureKey } from "~/ui-rtk/constants/feature-flags"
import usePostHogFeatureFlags from "~/ui-rtk/hooks/posthog-feaures"
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid"

export type TSidebarItemProps = NavItem & {
  Icon:
    | React.FC<TSvgHOCProps & React.SVGProps<SVGSVGElement>>
    | typeof QuestionMarkCircleIcon
  items?: NavItem[]
  labelVisible?: boolean
  sessionRole: SessionRole
  onBeforeNavigate?: () => boolean
  onNavigate?: () => void
  onClick?: () => void
  featureFlag?: TPosthogFeatureKey
}

export const SidebarItem: React.FC<TSidebarItemProps> = ({
  label,
  path,
  availableTo,
  Icon,
  items,
  labelVisible,
  onBeforeNavigate,
  onNavigate,
  onClick,
  sessionRole,
  featureFlag,
}) => {
  const { isActive, isExpanded, handleClick, handleNavigate } = useConnect(
    path,
    onBeforeNavigate,
    onNavigate,
    items,
  )

  if (!availableTo?.includes(sessionRole)) {
    return null
  }

  const clickHandler = useCallback(
    (event: MouseEvent) => {
      if (onClick) {
        onClick()
      } else if (handleClick) {
        handleClick(event)
      }
    },
    [onClick, handleClick],
  )

  if (featureFlag && !usePostHogFeatureFlags(featureFlag)) {
    return null
  }

  return (
    <div className="relative">
      <div
        onClick={clickHandler}
        className="flex items-center gap-2 cursor-pointer hover:bg-gray/[.1] hover:text-platinum px-3 py-2 pl-0"
      >
        <div className="flex gap-2">
          <Icon
            size={24}
            className={cn([
              "min-w-5.5",
              isActive ? "text-platinum" : "text-gray",
            ])}
            fill={isActive ? "platinum" : "gray"}
          />
          <p
            className={cn(
              "transition-all duration-300 font-medium whitespace-nowrap",
              labelVisible
                ? "opacity-100 max-w-full"
                : "opacity-0 max-w-0 ml-0",
              isActive ? "text-platinum" : "text-gray",
            )}
          >
            {label}
          </p>
        </div>
        {labelVisible && items?.length ? (
          <ChevronDownSvg
            size={16}
            fill={isActive ? "platinum" : "gray"}
            className={cn("transition-all", isExpanded && "rotate-180")}
          />
        ) : null}
      </div>

      {items?.length ? (
        <ul
          className={cn(
            "flex flex-col gap-1 mt-2 ml-6",
            !(isExpanded && labelVisible) && "hidden",
          )}
        >
          {items?.map(item => (
            <Subitem
              key={item.path}
              onClick={handleNavigate}
              sessionRole={sessionRole}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </div>
  )
}
