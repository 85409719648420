import { usePostHog } from "posthog-js/react"
import { useCallback, useEffect, useMemo, useState } from "react"

import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { useConnectorControllerGetAllConnectorsQuery } from "~/ui-rtk/api/connectorApi"
import {
  useCompanyConnectorControllerCreateCompanyConnectorMutation,
  useCompanyConnectorControllerGetCompanyConnectorsQuery,
} from "~/ui-rtk/api/companyConnectorApi"
import { isObjectsEqual } from "~/ui-rtk/utils/object-utils"

import type { ConnectorItemProps } from "./components"
import {
  isConnectorExcluded,
  isVisibleToCompany,
  mapConnectorStatus,
} from "~/ui-rtk/utils/connector-utils"
import { CategoryConnectorGroup } from "~/ui-rtk/pages/Onboarding/ConnectSources/types"
import { sortArrayWithEssentialFirst } from "./utils"
import {
  ConnectionStatus,
  ConnectorMetadata,
  ReleaseCycle,
} from "~/ui-rtk/api/types"

export type ConnectorItem = Pick<
  ConnectorItemProps,
  | "id"
  | "service"
  | "label"
  | "icon"
  | "status"
  | "releaseCycle"
  | "confirmedAt"
  | "metadata"
>

export const useConnect = () => {
  const [connectors, setConnectors] = useState<ConnectorItem[]>([])
  const {
    data: dataConnectors = [],
    isError: isGetAllConnectorsError,
    isLoading: isGetAllConnectorsLoading,
  } = useConnectorControllerGetAllConnectorsQuery()
  const {
    data: connectedServices,
    refetch: refetchConnectors,
    isError: isGetCompanyConnectorsError,
    isLoading: isGetCompanyConnectorsLoading,
  } = useCompanyConnectorControllerGetCompanyConnectorsQuery()

  const [connectAsync] =
    useCompanyConnectorControllerCreateCompanyConnectorMutation()

  const currentCompany = useAppSelector(selectCurrentCompany)

  const isError = isGetAllConnectorsError || isGetCompanyConnectorsError
  const isLoading = isGetAllConnectorsLoading || isGetCompanyConnectorsLoading

  const posthog = usePostHog()

  const getConnectorById = useCallback(
    (id: string) => {
      const connector = dataConnectors.find(c => c.id === id)
      return connector
    },
    [dataConnectors],
  )

  const { categories, connectorsGroup } = useMemo(() => {
    const connectorsGroup = dataConnectors.reduce(
      (acc: CategoryConnectorGroup, connector) => {
        connector.category.forEach(category => {
          if (!acc[category]) {
            acc[category] = []
          }

          if (!currentCompany) {
            return
          }

          if (!isVisibleToCompany(connector, currentCompany.id)) {
            return
          }

          const connectedService = connectedServices?.find(
            s => s.serviceName === connector.id,
          )

          const status = mapConnectorStatus(connectedService?.status)

          acc[category].push({
            id: connector.id,
            name: connector.name,
            icon: connector.icon,
            category: connector.category,
            confirmedAt: connectedService?.confirmedAt,
            metadata: connectedService?.metadata as ConnectorMetadata,
            status,
          })
        })
        return acc
      },
      {},
    )

    if (connectorsGroup["Essential"]) {
      const essentialConnectorCount = connectorsGroup["Essential"]?.reduce(
        (count, service) => {
          if (service.status === ConnectionStatus.CONNECTED) {
            return count + 1
          }
          return count
        },
        0,
      )

      if (currentCompany) {
        posthog?.group(
          "company",
          currentCompany?.id,
          (connectedServices ?? [])
            .map(connectedService => {
              const key = `${connectedService.serviceName.split(" ").join("_").toLowerCase()}_connector_status`
              const value = mapConnectorStatus(connectedService?.status)
              return { [key]: value }
            })
            .reduce((result, obj) => ({ ...result, ...obj }), {
              essential_connector_count: essentialConnectorCount,
            }),
        )
      } else {
        console.warn(`Current company is missing`)
      }
    }

    const categories = sortArrayWithEssentialFirst(Object.keys(connectorsGroup))
    return {
      categories,
      connectorsGroup,
    }
  }, [dataConnectors, currentCompany, connectedServices])

  const allEssentialConnectorsEnabled = useMemo(() => {
    if (!connectorsGroup["Essential"]) {
      return false
    }
    return connectorsGroup["Essential"].every(
      essentialConnector =>
        essentialConnector.status === ConnectionStatus.CONNECTED,
    )
  }, [connectorsGroup])

  const connect = async (service: string) => {
    try {
      const { uri, companyConnectorId } = await connectAsync({
        createCompanyConnectorDto: {
          service,
        },
      }).unwrap()

      return { uri, companyConnectorId }
    } catch {
      throw new Error("Failed to connect to the service.")
    }
  }

  const getCompanyConnectorByService = useCallback(
    (service: string) => {
      const companyConnector = connectedServices?.find(
        s => s.serviceName === service,
      )
      return companyConnector
    },
    [connectedServices],
  )

  const connectorsMemo = useMemo(() => {
    const filteredConnectors = dataConnectors
      .reduce((acc: ConnectorItem[], connector) => {
        if (isConnectorExcluded(connector.id)) {
          return acc
        }

        if (
          currentCompany &&
          isVisibleToCompany(connector, currentCompany.id)
        ) {
          const connectedService = connectedServices?.find(
            service => service.serviceName === connector.id,
          )

          acc.push({
            id: connectedService?.id || "",
            service: connector.id,
            label: connector.name,
            icon: connector.icon,
            status: mapConnectorStatus(connectedService?.status),
            confirmedAt: connectedService?.confirmedAt,
            releaseCycle: connector.releaseCycle,
            metadata: {
              ...connector.metadata,
            },
          })
        }

        return acc
      }, [])
      .sort((a, _b) => (a.releaseCycle === ReleaseCycle.BETA ? 1 : -1))

    return filteredConnectors
  }, [
    dataConnectors,
    connectedServices,
    currentCompany,
    isGetCompanyConnectorsLoading,
  ])

  useEffect(() => {
    if (!isObjectsEqual(connectors, connectorsMemo)) {
      setConnectors(connectorsMemo)
    }
  }, [connectorsMemo])

  return {
    categories,
    connectorsGroup,
    connectors,
    allEssentialConnectorsEnabled,
    isError,
    isLoading,
    connect,
    getConnectorById,
    getCompanyConnectorByService,
    refetchConnectors,
  }
}
