import { useCallback, useMemo, useState } from "react"

import {
  useCompanyConnectorControllerCreateCompanyConnectorMutation as useCreateCompanyConnectorMutation,
  useCompanyConnectorControllerGetCompanyConnectorsQuery,
} from "~/ui-rtk/api/companyConnectorApi"

import { CONNECTION_FLOW_STEPS } from "../constants"

import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { type Connector, CONNECTORS_IDS } from "~/ui-rtk/constants/sources"
import {
  useCurrentCompanyAsync,
  useAllConnectors,
  useAllConnectorsSkipped,
} from "~/ui-rtk/hooks/onboarding-flow"
import { useCompanyMetadataUpdater } from "~/ui-rtk/hooks/company-updater"

export const UNRESOLVED_CONNECTOR_STATUSES = [
  "RECONNECT",
  "FAILED",
  "NOT_CONNECTED",
]

export type ConnectionStep = {
  title: string
  order: number
  connectors: Connector[]
  completed: boolean
}

export const useConnect = () => {
  const currentCompany = useAppSelector(selectCurrentCompany)

  const {
    updateCompanyMetadata,
    inProgress: isUpdateCompanyMetadataInProgress,
  } = useCompanyMetadataUpdater()
  const getCurrentCompanyAsync = useCurrentCompanyAsync(currentCompany?.id)
  const [connectAsync] = useCreateCompanyConnectorMutation()
  const {
    allConnectors,
    isLoading: isConnectorsInfoLoading,
    refetch: refetchAllConnectors,
  } = useAllConnectors()
  const {
    data: connectedServices = [],
    isError: isGetCompanyConnectorsError,
    isLoading: isGetCompanyConnectorsLoading,
    refetch: refetchConnectedServices,
  } = useCompanyConnectorControllerGetCompanyConnectorsQuery()

  const { isEveryConnectorSkipped } = useAllConnectorsSkipped()

  const [skipping, setSkipping] = useState(false)

  const isDataError = isGetCompanyConnectorsError
  const isDataLoading = isConnectorsInfoLoading || isGetCompanyConnectorsLoading

  const getConnectorsForStep = useCallback(
    (requiredConnectors: string[]) =>
      requiredConnectors
        .map(requiredConnectorId => allConnectors?.[requiredConnectorId])
        .filter(connector => !!connector),
    [allConnectors],
  )

  const connectionFlowDetails = useMemo(() => {
    let totalConnectors = 0
    let totalCompleted = 0

    const steps: ConnectionStep[] = CONNECTION_FLOW_STEPS.map(
      ({ title, order, requiredConnectors }) => {
        const connectors = getConnectorsForStep(requiredConnectors)
        const isAllCompleted = connectors.every(
          c => !UNRESOLVED_CONNECTOR_STATUSES.includes(c.status),
        )

        totalConnectors += connectors.length
        totalCompleted += connectors.filter(
          c =>
            !UNRESOLVED_CONNECTOR_STATUSES.includes(c.status) ||
            Boolean(currentCompany?.metadata.skippedServices?.includes(c.id)),
        ).length

        return {
          title,
          order,
          connectors,
          completed: isAllCompleted,
        }
      },
    )

    return {
      totalConnectors,
      totalCompleted,
      allConnectorsSkipped: isEveryConnectorSkipped,
      steps,
      defaultStep: steps.find(step => !step.completed)?.order || 0,
    }
  }, [
    allConnectors,
    currentCompany,
    getConnectorsForStep,
    isEveryConnectorSkipped,
  ])

  const isGoogleAnalyticsCompleted = useMemo(() => {
    const googleAnalyticsService = connectedServices.find(
      c => c.serviceName === CONNECTORS_IDS.GOOGLE_ANALYTICS_4,
    )

    if (!googleAnalyticsService) {
      return false
    }

    return !UNRESOLVED_CONNECTOR_STATUSES.includes(
      googleAnalyticsService.status,
    )
  }, [connectedServices])

  const connect = async (id: string) => {
    try {
      const { uri, companyConnectorId } = await connectAsync({
        createCompanyConnectorDto: {
          service: id,
        },
      }).unwrap()

      return { uri, companyConnectorId }
    } catch (error) {
      throw new Error("Failed to connect to the service.")
    }
  }

  const skipServiceAsync = async (service: string) => {
    setSkipping(true)
    const company = await getCurrentCompanyAsync()
    const services = company?.metadata.skippedServices ?? []
    const skippedServices = [...services, service]

    await updateCompanyMetadata({
      skippedServices,
    })
    setSkipping(false)
  }

  const undoServiceSkipAsync = async (service: string) => {
    setSkipping(true)
    const company = await getCurrentCompanyAsync()
    const { skippedServices: services = [] } = company.metadata

    const skippedServices = services.filter(s => s !== service)

    await updateCompanyMetadata({
      skippedServices,
    })
    setSkipping(false)
  }

  const refetchEverything = () => {
    void refetchConnectedServices()
    void refetchAllConnectors()
  }

  return {
    connectedServices,
    isSkipInProgress: skipping,
    skipServiceAsync,
    undoServiceSkipAsync,
    isConnectionFlowCompleting: isUpdateCompanyMetadataInProgress,
    isGoogleAnalyticsCompleted,
    connectionFlowDetails,
    connect,
    isDataError,
    isDataLoading,
    refetchEverything,
  }
}
