import qs from "qs"
import { useURLSearchParams } from "./url"
import { Dispatch, SetStateAction, useMemo } from "react"
import { isFunctionAction } from "../utils/function-utils"

export default function useUrlParamsState<T>(urlKey: string, initialState?: T) {
  const { params, setParams } = useURLSearchParams()

  const filtersURLSearchParam = useMemo(() => params[urlKey], [params])

  const state = useMemo(() => {
    const emptyRes = initialState ?? ({} as T)
    return filtersURLSearchParam
      ? (qs.parse(filtersURLSearchParam, {
          arrayLimit: 50,
        }) as T)
      : emptyRes
  }, [filtersURLSearchParam, initialState])

  const setState: Dispatch<SetStateAction<T>> = (action: SetStateAction<T>) => {
    const newState = isFunctionAction<T>(action) ? action(state) : action
    const encodedFilters = qs.stringify(newState, {
      arrayFormat: "indices",
      allowEmptyArrays: true,
    })

    setParams({
      [urlKey]: encodedFilters,
    })
  }

  return { state, setState }
}

export function useUrlParamsStateArray<T>(urlKey: string, initialState?: T) {
  const { state: rawState, setState: setRawState } = useUrlParamsState<T>(
    urlKey,
    initialState,
  )

  const state = useMemo(() => {
    if (!rawState) {
      return []
    }

    const parsedState = Array.isArray(rawState)
      ? rawState
      : Object.keys(rawState).reduce((acc, idx) => {
          acc[parseInt(idx, 10)] = (rawState as Record<string, never>)[idx]
          return acc
        }, [])

    return parsedState
  }, [rawState])

  const setState: Dispatch<SetStateAction<T>> = (action: SetStateAction<T>) => {
    const newState = isFunctionAction(action) ? action(state as T) : action

    setRawState(((newState as Array<unknown>).length > 0 ? newState : []) as T)
  }

  return {
    state,
    setState,
  }
}
