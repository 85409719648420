import useSetupGuide, { type TSetupGuideCard } from "~/ui-rtk/hooks/setup-guide"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import SetupGuideSectionCard from "./SetupGuideSectionCard"
import { H3 } from "~/ui-rtk/components/ui/typography"
import { Card, Tooltip } from "~/ui-rtk/components/ui/common"
import { useMemo, useState } from "react"
import { CheckCircleIcon } from "@heroicons/react/24/outline"

type TSetupGuideSectionProps = {
  className?: string
}

export default function SetupGuideSection({
  className,
}: TSetupGuideSectionProps) {
  const { cardConfig: cards, hideCard } = useSetupGuide()
  const completed = useMemo(
    () => cards.reduce((acc, card) => (card.isCompleted ? acc + 1 : acc), 0),
    [cards],
  )
  const allCompleted = useMemo(
    () => completed === cards.length,
    [completed, cards],
  )

  const [isCollapseOpen, setIsCollapseOpen] = useState(!allCompleted)

  const handleCollapse = () => {
    if (allCompleted) {
      setIsCollapseOpen(!isCollapseOpen)
    }
  }

  const renderCard = () => (
    <Card
      rounded
      className={cn(
        "p-2 text-sm bg-basic-blue/20 border border-basic-blue flex gap-2 items-center",
        {
          "cursor-pointer": allCompleted,
        },
      )}
      onClick={handleCollapse}
    >
      <span>
        {completed} / {cards.length} Complete
      </span>
      {allCompleted && <CheckCircleIcon className="w-4 h-4 text-success" />}
    </Card>
  )

  const renderCardWithTooltip = () => (
    <Tooltip text="Click to see the cards">{renderCard()}</Tooltip>
  )

  return (
    <div className="setup-guide-section">
      <div className="flex justify-start items-center gap-3">
        <H3>Setup Guide</H3>
        <div>{allCompleted ? renderCardWithTooltip() : renderCard()}</div>
      </div>
      <div
        className={cn(
          className,
          "flex gap-2 collapse",
          isCollapseOpen ? "collapse-open" : "collapse-close",
        )}
      >
        <div className="collapse-content p-0 flex gap-3">
          {cards.map((card: TSetupGuideCard, idx: number) => (
            <SetupGuideSectionCard
              key={idx}
              idx={idx + 1}
              card={card}
              onCancel={hideCard}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
