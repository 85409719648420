import React from "react"
import { InfoSvg } from "~/ui-rtk/components/ui/svg/essentials"

type NoteProps = {
  text: string
}

const Note: React.FC<NoteProps> = ({ text }) => (
  <div className="p-2.5 rounded-md w-full bg-basic-dark-blue flex items-center gap-2.5">
    <InfoSvg size={16} fill="white" opacity={75} />
    <p className="text-white/75 text-md">{text}</p>
  </div>
)

export default Note
