import { useState } from "react"
import { Toggle } from "../Toggle"

export type TRadioToggleOption = {
  label: string
  icon?: {
    src: string
    alt?: string
  }
  value: string
  disabled?: boolean
}

type TRadioToggleProps = {
  options: TRadioToggleOption[]
  value?: string
  defaultValue?: string
  labelClassName?: string
  name: string
  onChange: (value: string) => void
}

export default function RadioToggle({
  options,
  value: valueProps,
  defaultValue,
  labelClassName,
  name,
  onChange: onChangeFromProps,
}: TRadioToggleProps) {
  const isControlled = typeof valueProps !== "undefined"
  const hasDefaultValue = typeof defaultValue !== "undefined"

  const [internalValue, setInternalValue] = useState<string | undefined>(
    hasDefaultValue ? defaultValue : undefined,
  )

  const selectedOption = isControlled ? valueProps : internalValue

  const onChange = (e: string) => {
    if (onChangeFromProps) {
      onChangeFromProps(e)
    }

    if (!isControlled) {
      setInternalValue(e)
    }
  }

  return (
    <>
      <input type="hidden" name={name} value={selectedOption} />
      {options.map((option: TRadioToggleOption) => (
        <label className={labelClassName} key={option.value}>
          <div className="flex flex-1 gap-4.5 items-center">
            {option.icon ? (
              <img
                src={option.icon.src}
                alt={option.icon.alt}
                className="h-12 max-w-12"
              />
            ) : null}
            <p className="text-white leading-5 tracking-tighter font-medium">
              {option.label}
            </p>
          </div>
          <Toggle
            id={option.value}
            disabled={option.disabled}
            value={option.value === selectedOption}
            onChange={() => onChange(option.value)}
          />
        </label>
      ))}
    </>
  )
}
