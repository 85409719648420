import { useCallback, useEffect, useState } from "react"
import { useAppSession } from "~/ui-rtk/pages/Auth/useCubeApiToken.ts"

const getFeaturebaseScript = () => {
  const { session, isLoading } = useAppSession()
  const [hasLoaded, setHasLoaded] = useState(false)

  const scriptAlreadyExists = () =>
    document.querySelector("script#featurebase-sdk") !== null

  const appendFeaturebaseScript = () => {
    const script = document.createElement("script")
    script.id = "featurebase-sdk"
    script.src = "https://do.featurebase.app/js/sdk.js"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    script.onload = () => setHasLoaded(true)
    document.head.append(script)
  }

  const removeFeaturebaseScript = () => {
    const script = document.getElementById("featurebase-sdk")
    if (script) {
      document.head.removeChild(script)
    }
  }

  useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendFeaturebaseScript()
    }
    return () => {
      removeFeaturebaseScript()
    }
  }, [])

  useEffect(() => {
    const win = window as any

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        win.Featurebase.q = win.Featurebase.q || []
        // eslint-disable-next-line prefer-rest-params
        win.Featurebase.q.push(arguments)
      }
    }

    if (hasLoaded && !isLoading && session?.featurebase?.token) {
      win.Featurebase("initialize_portal_widget", {
        organization: "marathondata",
        fullScreen: true,
        locale: "en",
        metadata: null,
        jwtToken: session?.featurebase?.token,
      })
    }
  }, [hasLoaded, session, isLoading])

  const identify = useCallback(() => {
    const win = window as any

    if (session) {
      win.Featurebase("identify", {
        organization: "marathondata",
        email: session.user.email,
        name: session.user.name,
        id: session.user.id,
        companies: [
          {
            id: session.currentCompany.id,
            name: session.currentCompany.name,
            createdAt: session.currentCompany.createdAt,
            customFields: {
              industry: session.currentCompany.metadata?.industry || "unknown",
            },
          },
        ],
      })
    }
  }, [session])

  return {
    identify,
  }
}

export default getFeaturebaseScript
