import { FieldProps } from "formik"
import Select, { TSelectProps } from "~/ui-rtk/components/ui/controls/Select"

export default function FormikSelect({
  options,
  field,
  form,
  isError,
}: TSelectProps & FieldProps) {
  const value = options
    ? (options.find(option => option.value === field.value)?.value ?? "")
    : ""
  return (
    <Select
      options={options}
      name={field.name}
      value={value}
      onChange={e => form.setFieldValue(field.name, e.target.value)}
      onBlur={field.onBlur}
      isError={isError}
    />
  )
}
