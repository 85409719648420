import React from "react"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { safeFormat } from "../TableVisualization/utils"
import type {
  ConfigItem,
  TComposedTableComposedValue,
  TComposedTableProps,
} from "./types"
import type { TableColumnType } from "~/ui-rtk/constants/table-visualization-types"
import ValueWithDelta from "~/ui-rtk/components/ui/common/ValueWithDelta/ValueWithDelta"
import { TOTAL_CALCULATED_DATA_KEY } from "~/ui-rtk/constants/charts"

const getPercentageStyles = (
  percentage: number,
  {
    negative,
    positive,
  }: {
    negative?: string
    positive?: string
  },
) => (percentage > 0 ? positive : negative)

const renderCellValue = (
  value: TComposedTableComposedValue | number,
  col: ConfigItem,
) => {
  const mainValue =
    (value as TComposedTableComposedValue).value ?? (value as number)
  const percentage = (value as TComposedTableComposedValue).percentage

  if (!percentage) {
    return safeFormat(mainValue, col.type)
  }

  const style =
    col.percentageStyles && percentage !== 0
      ? getPercentageStyles(percentage, col.percentageStyles)
      : ""

  return (
    <span>
      {safeFormat(mainValue, col.type)}{" "}
      <span className={cn("text-sm", style)}>
        ({safeFormat(percentage, "percent")})
      </span>
    </span>
  )
}

// TODO: clean up, split by components
export const ComposedTable: React.FC<TComposedTableProps> = ({
  metrics,
  className,
  columnConfig: config,
  compareMode = false,
  calculateTotal = false,
}) => {
  const cellClasses = "px-5 py-4 text-ellipsis text-xl"
  const renderCell = (key: string, col: ConfigItem) => (
    <td key={key} className={cellClasses} align={col.align}>
      {renderCellValue(metrics[key], col)}
      {compareMode && (
        <div className={`p-0.5 text-sm flex gap-2`}>
          <ValueWithDelta
            actualValue={
              (metrics[key] as TComposedTableComposedValue).value ??
              (metrics[key] as number)
            }
            comparedValue={
              (metrics[`compared.${key}`] as TComposedTableComposedValue)
                .value ?? (metrics[`compared.${key}`] as number)
            }
            hideActualValue
          />
          <span>
            prev: {renderCellValue(metrics[`compared.${key}`], col) as string}
          </span>
        </div>
      )}
    </td>
  )
  const renderBreakdown = (breakdown: string, colType: TableColumnType) => (
    <td key={breakdown} className={cellClasses}>
      {safeFormat(breakdown, colType) as string}
    </td>
  )

  return (
    <div
      className={cn(
        "h-full max-h-100 px-4 pb-2 space-y-3 border rounded-lg border-basic-blue bg-basic-dark-blue",
        className,
      )}
    >
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="w-full border-b border-b-basic-blue">
            <tr>
              {config.map(col => (
                <th
                  key={col.dataKey}
                  className="px-4 py-5 text-4.5 min-w-35.5 text-white font-bold leading-6 tracking-[-2%]"
                  align={col.align ?? "left"}
                >
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {config[0].keys.map((breakdown, idx) => (
              <tr
                key={breakdown}
                className={idx % 2 !== 1 ? "bg-basic-black" : ""}
              >
                {config.map(col => {
                  if (col.isBreakdown) {
                    return renderBreakdown(breakdown, col.type)
                  }

                  const $key = col.keys.find(key => key.includes(breakdown))
                  if (!$key) {
                    return renderCell(col.header, col)
                  }

                  return renderCell($key, col)
                })}
              </tr>
            ))}
            {calculateTotal && (
              <tr key="total">
                {config.map(col => {
                  if (col.isBreakdown) {
                    return renderBreakdown("Total", col.type)
                  }

                  const $key = `${TOTAL_CALCULATED_DATA_KEY}.${col.dataKey}`
                  if (!$key) {
                    return renderCell(col.header, col)
                  }

                  return renderCell($key, col)
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
