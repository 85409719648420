import { useMemo, useState } from "react"
import { brandRoutes } from "../constants/routes"
import { useConnect as useConnectorsConnect } from "~/ui-rtk/components/features/connectors/ConnectorsTable/connect"
import { selectCurrentCompany } from "../app/selectors/company.selector"
import { useAppSelector } from "../app/hooks"
import usePostHogFeatureFlags from "./posthog-feaures"
import { POSTHOG_FEATURES } from "../constants/feature-flags"
import { useCompanyMetadataUpdater } from "./company-updater"
import useLoadingState from "./loading-state"

// These values should not be modified as we store this in company metadata
const SETUP_GUIDE_CARDS = {
  ESSENTIAL_CONNECTORS: "essential_connectors",
  KEYWORDS_CATEGORIES: "keywords_categories",
  ATTRIBUTION_SETTINGS: "attribution_settings",
  KEYWORDS_COMPETITORS: "keywords_competitors",
}

export type TSetupGuideCard = {
  id: string
  label: string
  description: string
  linkTo: string
  isCompleted: boolean
  isHidden: boolean
}

export default function useSetupGuide() {
  const { allEssentialConnectorsEnabled } = useConnectorsConnect()
  const currentCompany = useAppSelector(selectCurrentCompany)
  const metadata = currentCompany?.metadata ?? {}
  const { keywords, revenueSource, homePage } = currentCompany?.metadata ?? {}
  const isRevenueSourcePageFeatureEnabled = usePostHogFeatureFlags(
    POSTHOG_FEATURES.REVENUE_BREAKDOWN_PAGE,
  )
  const [, rerender] = useState(0)
  const { updateCompanyMetadata } = useCompanyMetadataUpdater()
  const { setIsLoading } = useLoadingState()

  const hiddenCards = homePage?.setupGuideSection?.hiddenCards ?? []

  const hideCard = async (card: TSetupGuideCard) => {
    if ((hiddenCards ?? []).includes(card.id)) {
      return
    }

    const update = {
      ...metadata,
      homePage: {
        ...(metadata?.homePage ?? {}),
        setupGuideSection: {
          ...(metadata?.homePage?.setupGuideSection ?? {}),
          hiddenCards: [...(hiddenCards ?? []), card.id],
        },
      },
    }

    setIsLoading(true)
    await updateCompanyMetadata(update).catch(() => null)
    setIsLoading(false)
    rerender((idx: number) => idx + 1)
  }

  const cardConfig: TSetupGuideCard[] = useMemo(
    () =>
      [
        {
          id: SETUP_GUIDE_CARDS.ESSENTIAL_CONNECTORS,
          label: "Set up Connectors",
          description: "It's an essential action to collect information.",
          linkTo: brandRoutes.SETUP.CONNECTORS,
          isCompleted: allEssentialConnectorsEnabled,
          isHidden: hiddenCards.includes(
            SETUP_GUIDE_CARDS.ESSENTIAL_CONNECTORS,
          ),
        },
        {
          id: SETUP_GUIDE_CARDS.KEYWORDS_CATEGORIES,
          label: "Select the Keywords",
          description: "Required to dive into Mind Share",
          linkTo: brandRoutes.SETUP.KEYWORDS,
          isCompleted: !!keywords?.google?.categories,
          isHidden: hiddenCards.includes(SETUP_GUIDE_CARDS.KEYWORDS_CATEGORIES),
        },
        {
          id: SETUP_GUIDE_CARDS.ATTRIBUTION_SETTINGS,
          label: "Set up Attribution Settings",
          description: "Correct revenue source is crucial",
          linkTo: brandRoutes.SETUP.REVENUE_SOURCE,
          isCompleted: !!revenueSource,
          isHidden:
            !isRevenueSourcePageFeatureEnabled ||
            hiddenCards.includes(SETUP_GUIDE_CARDS.ATTRIBUTION_SETTINGS),
        },
        {
          id: SETUP_GUIDE_CARDS.KEYWORDS_COMPETITORS,
          label: "Connect Social Competitors",
          description: "if you want to compare your brand to others",
          linkTo: brandRoutes.SETUP.KEYWORDS,
          isCompleted: !!keywords?.google?.competitors,
          isHidden: hiddenCards.includes(
            SETUP_GUIDE_CARDS.KEYWORDS_COMPETITORS,
          ),
        },
      ].filter(card => !card.isHidden),
    [
      currentCompany,
      allEssentialConnectorsEnabled,
      isRevenueSourcePageFeatureEnabled,
    ],
  )

  return {
    cardConfig,
    hideCard,
  }
}
