import { ReactElement, useEffect, useMemo } from "react"
import { CubeProvider } from "@cubejs-client/react"
import { usePostHog } from "posthog-js/react"
import { useIdleTimer } from "react-idle-timer"

import useFeaturebase from "~/ui-rtk/hooks/featurebase.tsx"

import Guest from "../Guest"
import Main from "../Main"
import { Onboarding } from "../Onboarding"
import StaticSpinner from "~/ui-rtk/components/layout/StaticSpinner"

import { useAppSession } from "./useCubeApiToken"
import useAccess from "~/ui-rtk/hooks/access"
import useMobileView from "~/ui-rtk/hooks/mobile-view"
import useIntercom from "~/ui-rtk/hooks/intercom"
import {
  useAllConnectorsTouched,
  useConnectionFlowIgnored,
  useOnboardingComplete,
  useOnboardingEnabled,
} from "~/ui-rtk/hooks/onboarding-flow"
import { AccountDetails, CheckInbox } from "../Onboarding/BrandInfo"
import ConfirmEmail from "../ConfirmEmail"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"

const MARATHONDATA_CO_NAME = "MARATHONDATA_CO"

const AuthPage = () => {
  let content = null
  const { session, cubeApi, isLoading, invalidateTokenIfNeeded } =
    useAppSession()
  const { isSuperAdmin, isAccessGranted } = useAccess()
  const posthog = usePostHog()
  const { identify } = useFeaturebase()
  const isMobile = useMobileView()
  const { disableIntercom } = useIntercom()
  const { params } = useURLSearchParams()

  const { user, currentCompany } = session || {}
  const isSetupStatusComplete = useOnboardingComplete()
  const isOnboardingEnabled = useOnboardingEnabled()
  const isConnectionFlowIgnored = useConnectionFlowIgnored()
  const { isEveryConnectorTouched, isLoading: isConnectorTouchedLoading } =
    useAllConnectorsTouched(!!currentCompany?.id)

  const onActive = () => {
    invalidateTokenIfNeeded()?.catch(() => null)
  }

  const isUserActive = useMemo(
    () => user?.isConfirmed || user?.isActive,
    [user],
  )

  useIdleTimer({
    onActive,
    timeout: 30_000, // 30 seconds
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
    name: MARATHONDATA_CO_NAME,
  })

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id, {
        email: user.email.toLowerCase(),
        name: user.name,
      })
      if (currentCompany) {
        posthog?.group("company", currentCompany.id, {
          name: currentCompany.name,
          setupStatus: currentCompany.setupStatus,
          subscription: currentCompany.subscription,
        })
        identify()
      }
    }
  }, [posthog, user?.id, user?.email, currentCompany?.id])

  useEffect(() => {
    if (isMobile) {
      disableIntercom()
    }
  }, [])

  const renderMain: () => ReactElement = () => (
    <CubeProvider cubeApi={cubeApi}>
      <Main />
    </CubeProvider>
  )

  const renderAuthSessionContent = () => {
    if (!isUserActive && params["nonce"]) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <ConfirmEmail />
        </div>
      )
    }
    if (!isUserActive) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <CheckInbox />
        </div>
      )
    }
    if (!currentCompany?.id) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <AccountDetails />
        </div>
      )
    }
    if (!isOnboardingEnabled) {
      return renderMain()
    }
    if (isSetupStatusComplete) {
      return renderMain()
    }
    if (isEveryConnectorTouched) {
      return renderMain()
    }
    if (isConnectionFlowIgnored) {
      return renderMain()
    }
    if (isAccessGranted || isSuperAdmin) {
      return renderMain()
    }

    return <Onboarding />
  }

  if (session) {
    content = renderAuthSessionContent()
  } else if (!session && !isLoading && !isConnectorTouchedLoading) {
    content = <Guest />
  }

  return (
    <>
      <StaticSpinner />
      {content}
    </>
  )
}

export default AuthPage
