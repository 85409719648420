import { toast } from "react-toastify"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import { isFuture } from "date-fns"

import ConnectorLinkDialog from "~/ui-rtk/components/features/connectors/ConnectorLinkDialog"
import {
  StepsList,
  PendingDataConnection,
  SkippedDataConnection,
} from "./components"
import { Button } from "~/ui-rtk/components/ui/controls"
import {
  CamelProgressBar,
  ContentBox,
  Loader,
} from "~/ui-rtk/components/ui/common"

import { UNRESOLVED_CONNECTOR_STATUSES, useConnect } from "./connect"
import { serviceConnectorMap } from "~/ui-rtk/components/features/connectors/forms/utils"
import { useLazyComponent } from "~/ui-rtk/shared/hooks/useLazyComponent/useLazyComponent"
import qs from "qs"
import {
  confirmationDialogPropsMap,
  ConnectorTypeEnum,
} from "~/ui-rtk/components/features/connectors/ConnectorsTable/utils"
import SuccessConnectionDialog from "~/ui-rtk/components/features/connectors/SuccessConnectionDialog"
import { ConfirmConnectionDialog } from "~/ui-rtk/components/features/connectors"
import { useCompleteConnectionFlow } from "~/ui-rtk/hooks/onboarding-flow"
import { useCompanyMetadataUpdater } from "~/ui-rtk/hooks/company-updater"
import { Connector } from "~/ui-rtk/constants/sources"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

export const MIN_CONNECTION_COUNT = 2

export interface ICanRefetchEverything {
  refetchEverything: () => void
}

export const ConnectionFlowPage = forwardRef<ICanRefetchEverything>(
  (_props, ref) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as {
      state?: { companyConnectorId?: string; type?: ConnectorTypeEnum }
    }
    const navigate = useStableNavigate()

    const {
      completeConnectionFlow,
      inProgress: isCompetingConnecitonFlowInProgress,
    } = useCompleteConnectionFlow()

    const { updateCompanyMetadata, inProgress: isCompanyUpdateInProgress } =
      useCompanyMetadataUpdater()

    const {
      connectedServices,
      skipServiceAsync,
      undoServiceSkipAsync,
      isSkipInProgress,
      isGoogleAnalyticsCompleted,
      connect,
      connectionFlowDetails,
      isDataLoading,
      refetchEverything,
    } = useConnect()

    const [stateParam, setStateParam] = useState(params?.state ?? null)
    const [flowState, setFlowState] = useState<"completed" | "skipped" | null>()
    const [onboardUrl, setOnboardUrl] = useState<string | null>(null)
    const [currentStepIndex, setCurrentStepIndex] = useState(0)

    const currentStep = useMemo(
      () =>
        connectionFlowDetails.steps.find(
          step => step.order === currentStepIndex,
        ),
      [connectionFlowDetails, currentStepIndex],
    )

    const isLoading = useMemo(
      () =>
        isCompetingConnecitonFlowInProgress ||
        isDataLoading ||
        isCompanyUpdateInProgress,
      [
        isDataLoading,
        isCompetingConnecitonFlowInProgress,
        isCompanyUpdateInProgress,
      ],
    )

    const { loadComponent, unloadComponent, renderLazyComponent } =
      useLazyComponent()

    useImperativeHandle(ref, () => ({
      refetchEverything: () => {
        void refetchEverything()
      },
    }))

    const handleConnect = useCallback(
      async (id: string, callback?: CallableFunction) => {
        try {
          const { uri, companyConnectorId } = await connect(id)
          const connectorService = serviceConnectorMap[id]
          if (connectorService) {
            loadComponent(connectorService.component, {
              isOpen: true,
              uri,
              service: id,
              companyConnectorId,
              onClose: unloadComponent,
            })
            return
          }

          setOnboardUrl(uri)
        } catch (error) {
          toast.error("Connection failed")
        } finally {
          void callback?.()
        }
      },
      [connect],
    )

    const openConnectionLinkDialog = (connector: Connector) => {
      loadComponent(ConnectorLinkDialog, {
        connectorId: connector.id,
        imgSrc: connector.icon,
        service: connector.name,
        onClose: unloadComponent,
      })
    }

    const openConfirmDialog = (
      companyConnectorId: string,
      serviceName: string,
    ) => {
      const props = confirmationDialogPropsMap[serviceName]
      if (props) {
        loadComponent(ConfirmConnectionDialog, {
          isOpen: true,
          companyConnectorId,
          onClose: unloadComponent,
          ...props,
        })
      }
    }

    const handleComplete = useCallback(() => {
      const { totalCompleted } = connectionFlowDetails
      const hasMinimumConnections = totalCompleted >= MIN_CONNECTION_COUNT

      if (!isGoogleAnalyticsCompleted || !hasMinimumConnections) {
        setFlowState("skipped")
        return
      }

      try {
        setFlowState("completed")
      } catch (error) {
        toast.error("Failed to complete connection flow")
      }
    }, [connectionFlowDetails, isGoogleAnalyticsCompleted])

    const handleCompleteConnectionFlow = () => completeConnectionFlow()

    const handleRedirect = async () => {
      await handleCompleteConnectionFlow()
      navigate("/")
    }

    const handleSkipConnection = async (service: string) => {
      try {
        await skipServiceAsync(service)
      } catch (error) {
        toast.error("Failed to skip service connection.")
        throw error
      }
    }

    const handleUndoSkip = async (service: string) => {
      try {
        await undoServiceSkipAsync(service)
      } catch (error) {
        toast.error("Failed to undo skip operation over service connection.")
        throw error
      }
    }

    const handleNext = () => setCurrentStepIndex(prev => prev + 1)
    const handlePrev = () => setCurrentStepIndex(prev => prev - 1)

    const isLastStep =
      currentStep?.order === connectionFlowDetails.steps.length - 1

    const nextDisabled = useMemo(() => {
      const isAllProcessed = currentStep?.connectors.every(connector => {
        const isResolved = !UNRESOLVED_CONNECTOR_STATUSES.includes(
          connector.status,
        )

        const isDelegated = connector.connectionLink
          ? isFuture(connector.connectionLink.expiresAt)
          : false
        return connector.isSkipped || isResolved || (isDelegated && !isLastStep)
      })
      return !isAllProcessed
    }, [isLastStep, currentStep])

    const openSuccessDialog = (companyConnectorId: string, service: string) => {
      const companyConnector = connectedServices.find(
        c => c.serviceName === service,
      )
      if (!companyConnector) {
        toast.error(`Oops... Seems like this service is not ready yet.`)
        return
      }

      const props = confirmationDialogPropsMap[service]
      if (props) {
        const details: Omit<typeof props, "title"> & { title?: string } = {
          ...props,
        }
        delete details.title
        loadComponent(SuccessConnectionDialog, {
          title: `Your connection to ${details.label} is pending!`,
          isOpen: true,
          onClose: unloadComponent,
          companyConnectorId,
          ...details,
        })
      }
    }

    const handleCompanyConnectionStatus = useCallback(
      (
        companyConnectorId: string,
        connectorType: ConnectorTypeEnum = ConnectorTypeEnum.FIVETRAN,
      ) => {
        const companyConnector = connectedServices.find(
          c => c.id === companyConnectorId,
        )

        if (companyConnector && connectorType === ConnectorTypeEnum.CUSTOM) {
          openConfirmDialog(companyConnector.id, companyConnector.serviceName)
        } else if (
          companyConnector &&
          connectorType === ConnectorTypeEnum.FIVETRAN
        ) {
          openSuccessDialog(companyConnector.id, companyConnector.serviceName)
        }

        setStateParam(null)
      },
      [connectedServices],
    )

    const handleIgnoreFlow = async () => {
      await updateCompanyMetadata({
        skipOnboardingFlow: true,
      })
    }

    useEffect(() => {
      if (onboardUrl) {
        window.location.href = onboardUrl
      }
    }, [onboardUrl])

    useEffect(() => {
      if (connectionFlowDetails.defaultStep !== currentStepIndex) {
        setCurrentStepIndex(connectionFlowDetails.defaultStep)
      }
    }, [connectionFlowDetails.defaultStep])

    useEffect(() => {
      const connectorType = stateParam?.type
      const companyConnectorId = stateParam?.companyConnectorId
      if (companyConnectorId && connectedServices?.length) {
        handleCompanyConnectionStatus(companyConnectorId, connectorType)
        window.history.replaceState({}, "", `${location.pathname}`)
      }
    }, [connectedServices, stateParam, handleCompanyConnectionStatus])

    useEffect(() => {
      if (flowState === "completed" || flowState === "skipped") {
        setTimeout(() => {
          handleCompleteConnectionFlow().catch(() => null)
        }, 10000)
      }
    }, [flowState])

    if (flowState === "completed") {
      return (
        <PendingDataConnection
          onDone={handleCompleteConnectionFlow}
          inProgress={isCompetingConnecitonFlowInProgress}
        />
      )
    } else if (flowState === "skipped") {
      return (
        <SkippedDataConnection
          allSkipped={connectionFlowDetails.allConnectorsSkipped ?? false}
          onContinue={handleRedirect}
          actionInProgress={isCompetingConnecitonFlowInProgress}
        />
      )
    }

    return (
      <ContentBox
        title="Onboard your data into Marathon to officially secure your spot on the waitlist for our private beta"
        subTitle={currentStep?.title}
        classes={{
          content: "max-w-full",
          contentContainer: "lg:px-10",
        }}
      >
        {!isLoading ? (
          <div>
            <StepsList
              currentStep={currentStep}
              onSetDialog={openConnectionLinkDialog}
              onConnect={handleConnect}
              onSkip={handleSkipConnection}
              onUndo={handleUndoSkip}
              isSkipInProgress={isSkipInProgress}
            />
            <CamelProgressBar
              total={connectionFlowDetails.totalConnectors}
              completed={connectionFlowDetails.totalCompleted}
            />
            <div className="flex gap-4 w-full">
              <Button
                className="mx-auto w-48 mt-8"
                onClick={handlePrev}
                disabled={currentStepIndex < 1}
              >
                Previous
              </Button>
              <Button
                className="mx-auto w-48 mt-8"
                onClick={isLastStep ? handleComplete : handleNext}
                isLoading={isCompetingConnecitonFlowInProgress}
                disabled={nextDisabled}
              >
                {isLastStep ? "Complete" : "Next"}
              </Button>
            </div>
            <div className="absolute left-1/2 -translate-x-1/2 -bottom-10">
              <button
                className="font-medium text-white/40"
                onClick={handleIgnoreFlow}
              >
                Ignore connection flow
              </button>
            </div>
            {renderLazyComponent()}
          </div>
        ) : (
          <Loader />
        )}
      </ContentBox>
    )
  },
)
