export default function useIntercom() {
  const disableIntercom = () => {
    // @ts-expect-error: Intercom is not part of window interface
    window.Intercom?.("shutdown")
  }
  const enableIntercom = () => {
    // @ts-expect-error: Intercom is not part of window interface
    window.Intercom?.("boot", {
      // @ts-expect-error: Intercom is not part of window interface
      ...(window.IntercomSettings ?? {}),
    })
  }
  return {
    disableIntercom,
    enableIntercom,
  }
}
