import { ChevronDownSvg } from "../../svg/arrow"
import { WarnSvg } from "../../svg/essentials"
import Tooltip from "../Tooltip"

export default function PeriodPickerDropDownIcons({ isPartialPeriod = false }) {
  return (
    <span className="flex items-center gap-2">
      {isPartialPeriod ? (
        <Tooltip
          text="Partial period"
          classes={{
            wrapper: "min-w-30",
          }}
        >
          <WarnSvg size={24} fill="orange" className="cursor-pointer" />
        </Tooltip>
      ) : null}
      <ChevronDownSvg className="cursor-pointer" />
    </span>
  )
}
