export const URL_WIDGET_PARAM_KEY = "widget"
export const URL_CAMPAIGN_ID_PARAM_KEY = "campaign_id"
export const URL_ADSET_ID_PARAM_KEY = "adset_id"
export const URL_FILTER_PARAM_KEY = "filters"
export const URL_SORT_PARAM_KEY = "sort"
export const URL_COLUMN_KEYS_PARAM_KEY = "columns"
export const URL_COLUMN_ORDER_PARAM_KEY = "corder"
export const URL_COLUMN_PINNING_PARAM_KEY = "pinned"
export const URL_SOURCE_KEYS_PARAM_KEY = "sources"
export const URL_PAGINATION_STATE = "pages"
export const URL_COMPANY_ID = "_c_id"
