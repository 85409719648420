import { XMarkIcon } from "@heroicons/react/20/solid"
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline"
import { MouseEventHandler, MouseEvent } from "react"
import { Link } from "react-router-dom"
import { Card } from "~/ui-rtk/components/ui/common"
import { type TSetupGuideCard } from "~/ui-rtk/hooks/setup-guide"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

type TSetupGuideSectionCardProps = {
  card: TSetupGuideCard
  idx: number
  onCancel: (card: TSetupGuideCard) => void
}

export default function SetupGuideSectionCard({
  card,
  idx,
  onCancel,
}: TSetupGuideSectionCardProps) {
  const navigate = useStableNavigate()
  const handleCancel: MouseEventHandler<SVGElement> = (e: MouseEvent) => {
    e.stopPropagation()
    onCancel(card)
  }

  const handleOpen = () => {
    navigate(card.linkTo)
  }

  return (
    <div className="setup-guide-section-card h-full">
      <Card
        rounded
        className="p-4 w-75 text-base font-semibold text-white border bg-basic-blue/20 border-basic-blue h-full flex flex-col justify-between"
      >
        <div>
          <div
            onClick={handleOpen}
            className="flex justify-between items-center"
          >
            <div className="grow">
              {String(idx).padStart(2, "0")}: {card.label}
            </div>
            <div className="grow-0">
              <XMarkIcon
                onClick={handleCancel}
                className="text-white w-6 h-6 cursor-pointer"
              />
            </div>
          </div>
          <div className="mt-3 text-white/50">{card.description}</div>
        </div>
        <div className="mt-6 flex justify-between items-center">
          <div className="flex gap-1">
            {card.isCompleted ? (
              <div className="text-success flex gap-1 items-center">
                <CheckCircleIcon className="w-4 h-4" />
                <span>Complete</span>
              </div>
            ) : (
              <div className="text-white/50">Not Started</div>
            )}
          </div>
          <div>
            <Link to={card.linkTo}>
              <ArrowTopRightOnSquareIcon className="text-white w-4 h-4 cursor-pointer" />
            </Link>
          </div>
        </div>
      </Card>
    </div>
  )
}
