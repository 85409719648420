import {
  useMediaCategoryControllerGetMediaCategoryQuery,
  useMediaCategoryControllerPostMediaCategoriesMutation,
} from "~/ui-rtk/api/mediaCategoryApi.ts"
import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import { TSource } from "~/ui-rtk/constants/table-visualization-types"
import { Card, Loader } from "~/ui-rtk/components/ui/common"
import { H5 } from "~/ui-rtk/components/ui/typography"
import useSources from "~/ui-rtk/hooks/sources"
import { useStats } from "../hooks/stats"
import BrandValueOverSpendProgressBar from "../components/BrandValueOverSpendProgressBar"
import ValueOverTimeInfoBlock from "../components/ValueOverTimeInfoBlock"
import CampaignDetailsValueOverTimeChart from "../components/ValueOverTimeChart"
import { useEffect, useMemo, useState } from "react"
import {
  CAMPAIGN_MEDIA_CATEGORIES_OPTIONS,
  TCampaignAdSetAdDetailsProps,
  TCampaignMediaOption,
  VALUE_OVER_TIME_KEY_MAP,
  VALUE_OVER_TIME_LABEL_MAP,
  VALUE_OVER_TIME_MODE,
} from "../constants"
import { useCampaignQubeQuery } from "./hooks/cube-query"
import ValueOverTimeModeSelector from "../components/ValueOverTimeModeSelector"
import StatsTable from "../components/StatsTable"
import { renderDetailsCaption } from "../utils"
import { DetailsTitle } from "../components/DetailsHeader"
import ListBox from "~/ui-rtk/components/ui/common/ListBox/ListBox"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { URL_CAMPAIGN_ID_PARAM_KEY } from "~/ui-rtk/constants/url-params"
import { URL_WIDGET_PARAMS } from "../../dashboards/BrandMediaOptimizationDashboard/constants"
import { ArrowTopRightOnSquare } from "~/ui-rtk/components/ui/svg/arrow/ArrowTopRightOnSquare"
import NavigationButton from "~/ui-rtk/components/ui/controls/NavigationButton/NavigationButton"
import { QUERY_PARAMS } from "~/ui-rtk/hooks/period"

export default function CampaignDetails(props: TCampaignAdSetAdDetailsProps) {
  const { sources } = useSources()
  const { metric, date, granularity, dateRange } = props
  const { totalStats, metaStats } = useStats(metric)
  const [category, setCategory] = useState<string | null | undefined>(undefined)
  const campaignName = metric.campaign_name
  const campaignId = metric.campaign_id
  const sourceName = metric.media_channel?.toUpperCase()
  const source = sources.get(sourceName)
  const { allCampaignsDetails, valueOverTimeDetails } = useCampaignQubeQuery(
    campaignId,
    date,
    granularity,
  )

  const demo = useAppSelector(selectIsEnabled)

  const [createMediaCategory] =
    useMediaCategoryControllerPostMediaCategoriesMutation()

  const {
    data: mediaCategory,
    isLoading,
    isFetching,
    refetch,
  } = useMediaCategoryControllerGetMediaCategoryQuery(
    {
      id: campaignId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const [valueOverTimeMode, setValueOverTimeMode] = useState(
    VALUE_OVER_TIME_MODE.TOTAL,
  )

  useEffect(() => {
    if (mediaCategory?.category) {
      setCategory(mediaCategory.category)
    } else {
      setCategory(null)
    }
  }, [mediaCategory])

  const categoryUpdate = async (value: TCampaignMediaOption) => {
    await createMediaCategory({
      body: {
        mediaId: campaignId,
        type: "campaign",
        channel: metric.media_channel,
        category: value,
      },
    })
    setCategory(value)
    await refetch()
  }

  const viewChildrenAdditionalUrlParams = useMemo(
    () =>
      `${URL_CAMPAIGN_ID_PARAM_KEY}=${campaignId}&${QUERY_PARAMS.DATE_START}=${dateRange[0]}&${QUERY_PARAMS.DATE_END}=${dateRange[1]}&${QUERY_PARAMS.GRANULARITY}=${granularity}`,
    [dateRange, campaignId],
  )

  return (
    <div className="campaign-details w-145">
      <DetailsTitle
        title={campaignName ?? ""}
        classes={{ container: "mb-4" }}
        demoMode={demo.isEnabled}
      />
      <div>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <ListBox
            options={CAMPAIGN_MEDIA_CATEGORIES_OPTIONS}
            onSelect={categoryUpdate}
            value={category}
            label="Category:"
            className="border border-basic-blue rounded-lg"
            placeholder="Please select a Category"
          />
        )}
      </div>
      <Card
        rounded
        className="mt-3 bg-basic-dark-blue border border-basic-blue"
      >
        <div className="grid grid-cols-8">
          <div className="col-span-7">
            <H5 className={cn("font-bold", demo.isEnabled && "blur-md")}>
              {campaignName}
            </H5>
          </div>
          <div className="flex items-center justify-end">
            {renderSourceIcon({
              name: source?.name,
              icon: source?.icon,
            } as TSource)}
          </div>
        </div>
        <div className="mt-3 text-sm">
          {renderDetailsCaption(
            metric,
            allCampaignsDetails ?? {},
            "campaign",
            sourceName.toLowerCase(),
          )}
        </div>
        <BrandValueOverSpendProgressBar
          metric={metric}
          allDetails={allCampaignsDetails ?? {}}
          label="campaign"
        />
      </Card>
      <div className="mt-3 grid grid-cols-2 gap-2">
        <NavigationButton
          to={`?widget=${URL_WIDGET_PARAMS.ADSET}&${viewChildrenAdditionalUrlParams}`}
        >
          <ArrowTopRightOnSquare fill="white" size={20} /> View AdSets
        </NavigationButton>
        <NavigationButton
          to={`?widget=${URL_WIDGET_PARAMS.AD}&${viewChildrenAdditionalUrlParams}`}
        >
          <ArrowTopRightOnSquare fill="white" size={20} /> View Ads
        </NavigationButton>
      </div>
      <Card
        rounded
        className="mt-3 bg-basic-dark-blue border border-basic-blue"
      >
        <H5 className="font-bold">Stats</H5>
        <StatsTable totalStats={totalStats} metaStats={metaStats} />
      </Card>
      <Card
        rounded
        className="mt-3 bg-basic-dark-blue border border-basic-blue"
      >
        <div className="grid grid-cols-2 gap-2">
          <div className="text-start">
            <H5 className="font-bold">Value over time</H5>
          </div>
          <div className="text-end">
            <ValueOverTimeModeSelector
              mode={valueOverTimeMode}
              setMode={(mode: VALUE_OVER_TIME_MODE) =>
                setValueOverTimeMode(mode)
              }
            />
          </div>
        </div>

        {valueOverTimeDetails && (
          <>
            <div className="mt-3 grid grid-cols-3 gap-1">
              {[
                valueOverTimeDetails.info30days,
                valueOverTimeDetails.info60days,
                valueOverTimeDetails.info90days,
              ].map(info => (
                <ValueOverTimeInfoBlock
                  key={info.label}
                  info={info}
                  label={info.label}
                  dataLabel={VALUE_OVER_TIME_LABEL_MAP[valueOverTimeMode]}
                  dataKey={VALUE_OVER_TIME_KEY_MAP[valueOverTimeMode]}
                />
              ))}
            </div>
            <div className="mt-3">
              <CampaignDetailsValueOverTimeChart
                metrics={valueOverTimeDetails.chartData}
                dataKey={VALUE_OVER_TIME_KEY_MAP[valueOverTimeMode]}
                label="campaign"
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}
