import { URL_COLUMN_KEYS_PARAM_KEY } from "../constants/url-params"
import { useUrlParamsStateArray } from "./urlParamsState"

export default function useUrlColumnKeys(
  chartId = "",
  defaultColumnKeys: string[] = [],
) {
  const urlKey = `${chartId}_${URL_COLUMN_KEYS_PARAM_KEY}`
  const { state: columnKeys, setState: setColumnKeys } = useUrlParamsStateArray<
    Array<string>
  >(urlKey, defaultColumnKeys)

  return {
    urlKey,
    columnKeys,
    setColumnKeys,
  }
}
