import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import useConnect from "./connect"
import HomeDashboard from "~/ui-rtk/components/features/dashboards/HomeDashboard/HomeDashboard"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

const dateRestrictions = { maxDate: dayjs().subtract(7, "day").toDate() }

export const IS_HOME_PAGE_ENABLED = false

export default function HomePage() {
  const { content } = useConnect()

  return (
    <div className="h-full p-6 bg-basic-black text-basic-light">
      <WithLoader isLoading={false}>
        <HomeDashboard
          slug={content.slug}
          title={content.title}
          dateRangeRestrictions={dateRestrictions}
          items={content.items}
        />
      </WithLoader>
    </div>
  )
}
