import { useEffect, useState } from "react"

export default function useMobileView() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 550)

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 550)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  return isMobile
}
