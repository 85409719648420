import camelCase from "lodash/camelCase"
import startCase from "lodash/startCase"
import { CSS, type Transform } from "@dnd-kit/utilities"
import { CSSProperties } from "react"

import {
  isNumber,
  convertNumber,
  ConvertNumberType,
} from "~/ui-rtk/utils/number-utils"

import type {
  TableColumnType,
  TMetric,
  TSources,
} from "~/ui-rtk/constants/table-visualization-types"
import { Column } from "@tanstack/react-table"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export const formatValue = (
  rawValue: string | number,
  type: TableColumnType,
  sourceImages?: Map<string, { name: string; icon: string }>,
) => {
  const value =
    type !== "title" && type !== "image" && type !== "text"
      ? parseFloat(rawValue as never)
      : rawValue

  if (isNumber(value)) {
    return convertNumber(type as ConvertNumberType, value)
  }

  const isString = typeof value === "string"

  if (type === "date") {
    return dayjs(rawValue).format("ddd, ll")
  } else if (isString && type === "title") {
    return startCase(camelCase(value))
  } else if (isString && type === "image") {
    return value && sourceImages?.has(value.toUpperCase()) ? (
      <img
        src={sourceImages.get(value.toUpperCase())?.icon}
        alt={sourceImages.get(value.toUpperCase())?.name}
        title={sourceImages.get(value.toUpperCase())?.name}
        className="max-w-6 max-h-6"
      />
    ) : null
  } else if (isString && type === "text") {
    return value ? `${value.substring(0, 30)}...` : "N/A"
  }

  return rawValue
}

export function isColumnSortable(columnType: TableColumnType) {
  return [
    "number",
    "currency",
    "percent",
    "title",
    "text",
    "date",
    "coef",
    "count",
  ].includes(columnType ?? "")
}

export function isColumnFilterable(columnType: TableColumnType) {
  if (!columnType) {
    return false
  }

  return [
    "number",
    "currency",
    "percent",
    "title",
    "text",
    "date",
    "count",
  ].includes(columnType)
}

export const safeFormat = (
  cellValue: any,
  columnType: TableColumnType,
  sources?: TSources,
) => {
  const isUndefined = cellValue === null || cellValue === undefined
  const isNumeric = columnType === "number" || columnType === "currency"
  const isBrokenNumber = Number.isNaN(cellValue) || cellValue === Infinity
  if (isNumeric && (isUndefined || isBrokenNumber)) {
    return formatValue(0, columnType, sources)
  }

  if (columnType === "date") {
    return dayjs(cellValue).format("YYYY-MM-DD")
  }

  if (isUndefined) {
    return "-"
  }

  return formatValue(cellValue, columnType, sources)
}

/**
 * Inspired by https://tanstack.com/table/latest/docs/framework/react/examples/column-pinning-sticky
 *
 * Quote:
 *  These are the important styles to make sticky column pinning work!
 *  Apply styles like this using your CSS strategy of choice with this kind of logic to head cells, data cells, footer cells, etc.
 *  View the index.css file for more needed styles such as border-collapse: separate
 *
 * @param column
 * @returns {
 *  ...styles
 * }
 */
const lastBoxShadow = "-4px 0 4px -4px gray inset"
const firstBoxShadow = "4px 0 4px -4px gray inset"
/* eslint-disable no-nested-ternary */
export const getCommonPinningOrderingStyles = (
  column: Column<TMetric>,
  isDragging: boolean,
  transform: Transform | null,
): CSSProperties => {
  const isPinned = column.getIsPinned()
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left")
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right")

  return {
    boxShadow: isLastLeftPinnedColumn
      ? lastBoxShadow
      : isFirstRightPinnedColumn
        ? firstBoxShadow
        : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    opacity: isDragging ? 0.8 : isPinned ? 0.95 : 1,
    position: isPinned ? "sticky" : "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
  }
}
/* eslint-enable no-nested-ternary */
