import { SOURCE_IDS } from "~/ui-rtk/constants/sources"
import { type TLastUpdateDateProps } from "~/ui-rtk/hooks/cube"
import useSources from "~/ui-rtk/hooks/sources"

export const BRAND_MEDIA_SOCIAL_POSTS_TABLES = {
  ALL_POSTS: "ALL_POSTS",
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  TIKTOK: "TIKTOK",
}

export type TBrandMediaSocialPostsTableId =
  keyof typeof BRAND_MEDIA_SOCIAL_POSTS_TABLES

export type TBrandMediaSocialPostsTable = {
  id: TBrandMediaSocialPostsTableId
  label: string
  widget: string
  summaryWidget: string
  mediaChannel?: string
  requiredSources: string[]
}

const content = {
  id: "cltes8yua000008jv2xgg9fc5",
  slug: "/brand-media/organic-social-posts",
  icon: "NULL",
  title: "Organic Social Posts",
  parent: {
    slug: "/brand-media",
    title: "Brand Media",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 30.1,
  content: {
    items: {
      [BRAND_MEDIA_SOCIAL_POSTS_TABLES.ALL_POSTS]: {
        id: BRAND_MEDIA_SOCIAL_POSTS_TABLES.ALL_POSTS,
        label: "All posts",
        widget: "brand_media_organic_social_posts_table",
        summaryWidget: "brand_media_organic_social_posts_table_summary",
        requiredSources: [
          SOURCE_IDS.FACEBOOK,
          SOURCE_IDS.INSTAGRAM,
          SOURCE_IDS.TIKTOK,
        ],
      },
      [BRAND_MEDIA_SOCIAL_POSTS_TABLES.FACEBOOK]: {
        id: BRAND_MEDIA_SOCIAL_POSTS_TABLES.FACEBOOK,
        label: "Facebook",
        widget: "brand_media_organic_social_posts_table",
        summaryWidget: "brand_media_organic_social_posts_table_summary",
        requiredSources: [SOURCE_IDS.FACEBOOK, SOURCE_IDS.INSTAGRAM],
        mediaChannel: "facebook",
      },
      [BRAND_MEDIA_SOCIAL_POSTS_TABLES.INSTAGRAM]: {
        id: BRAND_MEDIA_SOCIAL_POSTS_TABLES.INSTAGRAM,
        label: "Instagram",
        widget: "brand_media_organic_social_posts_table",
        summaryWidget: "brand_media_organic_social_posts_table_summary",
        requiredSources: [SOURCE_IDS.FACEBOOK, SOURCE_IDS.INSTAGRAM],
        mediaChannel: "instagram",
      },
      [BRAND_MEDIA_SOCIAL_POSTS_TABLES.TIKTOK]: {
        id: BRAND_MEDIA_SOCIAL_POSTS_TABLES.TIKTOK,
        label: "Tiktok",
        widget: "brand_media_organic_social_posts_table",
        summaryWidget: "brand_media_organic_social_posts_table_summary",
        requiredSources: [SOURCE_IDS.TIKTOK],
        mediaChannel: "tiktok",
      },
    } as Record<TBrandMediaSocialPostsTableId, TBrandMediaSocialPostsTable>,
    title: "Organic Social Posts",
    lastDateChipProps: {
      dateMetric:
        "company_last_updated_date.brand_media_organic_posts_max_date",
    } as TLastUpdateDateProps,
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  return {
    content,
    sources,
    isLoading: isSourcesDataLoading,
  }
}
