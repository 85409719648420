import React, { useEffect, useState } from "react"
import { useSearchParams, Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useCompanyConnectorControllerCompleteCompanyConnectorOnboardingMutation as useCompleteCompanyConnectorOnboarding } from "~/ui-rtk/api/companyConnectorApi"
import { Loader } from "~/ui-rtk/components/ui/common"
import {
  isBadRequestError,
  isUnauthorizedError,
  isInternalServerError,
} from "~/ui-rtk/utils/http-utils"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"

type AdhocFivetranConnectionProps = {
  redirectTo?: string
}

const AdhocFivetranConnection: React.FC<AdhocFivetranConnectionProps> = ({
  redirectTo = "/",
}) => {
  const [text, setText] = useState("Completing...")

  const navigate = useStableNavigate()
  const [searchParams] = useSearchParams()
  const [completeAsync, { isSuccess }] = useCompleteCompanyConnectorOnboarding()

  const fivetranId = searchParams.get("id")

  const showSuccessMessage = () => {
    toast.success("Connection was successfully established!", {
      toastId: `onboarding-success-${fivetranId}`,
    })
  }

  const showErrorMessage = () => {
    toast.error("Failed to complete the onboarding", {
      toastId: `onboarding-failed-${fivetranId}`,
    })
  }

  useEffect(() => {
    const completeOnboarding = async (id: string) => {
      try {
        const completeOnboardingDto = { fivetranId: id }
        await completeAsync({ completeOnboardingDto }).unwrap()

        if (isSuccess) showSuccessMessage()
      } catch (error) {
        if (
          isBadRequestError(error) ||
          isUnauthorizedError(error) ||
          isInternalServerError(error)
        ) {
          showErrorMessage()
        }
      } finally {
        setText("Redirecting...")
        navigate(redirectTo)
      }
    }

    if (fivetranId) {
      void completeOnboarding(fivetranId)
    }
  }, [fivetranId, isSuccess])

  if (!fivetranId) {
    return <Navigate to={redirectTo} />
  }

  return (
    <div className="mx-5 flex items-center gap-2">
      <p>{text}</p>
      <Loader />
    </div>
  )
}

export default AdhocFivetranConnection
