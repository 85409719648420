import { SORT_BY } from "~/ui-rtk/constants/brand-media"
import { SOURCE_IDS } from "~/ui-rtk/constants/sources"
import { TLastUpdateDateProps } from "~/ui-rtk/hooks/cube"
import useSources from "~/ui-rtk/hooks/sources"

export const OVERVIEW_CHARTS = {
  FOLLOWERS: "FOLLOWERS",
  SEARCH: "SEARCH",
  SHARES: "SHARES",
  COMMENTS: "COMMENTS",
  LIKES: "LIKES",
  SAVES: "SAVES",
  PROFILE_VIEWS: "PROFILE_VIEWS",
  MENTIONS: "MENTIONS",
  SUBSCRIBERS: "SUBSCRIBERS",
}

export type TOverviewChartId = keyof typeof OVERVIEW_CHARTS

export type TCustomSource = {
  dataKey: string
  name: string
  requiredSource: string
}

export type TOverviewChart = {
  id: TOverviewChartId
  label: string
  widget: string
  requiredSources: string[]
  showPostsLink?: boolean
  showAdsLink?: boolean
  sortBy?: string
  customSources?: TCustomSource[]
  lastDateChipProps: TLastUpdateDateProps
}

export const URL_WIDGET_PARAM_KEY = "widget"
export const URL_SOURCES_PARAM_KEY = "sources"

const content = {
  id: "clr6xtoan0006qt5451d3crm9",
  slug: "/brand-health/overview",
  icon: "BarChartIcon",
  title: "Overview",
  parent: {
    slug: "/brand-health",
    title: "Brand Health",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 1.1,
  content: {
    charts: {
      [OVERVIEW_CHARTS.FOLLOWERS]: {
        id: OVERVIEW_CHARTS.FOLLOWERS,
        label: "New Followers",
        widget: "total_followers_stacked_chart",
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.instagram_insights_follows",
            "metrics.instagram_business_follows",
            "metrics.tiktok_profile_follows",
            "metrics.tiktok_ads_follows",
            "metrics.facebook_pages_follows",
            "metrics.youtube_channel_follows",
            "metrics.facebook_ads_follows",
          ],
        },
        customSources: [
          {
            dataKey: "metrics.instagram_insights_follows",
            name: "Total Profile Follows",
            requiredSource: SOURCE_IDS.INSTAGRAM,
          },
          {
            dataKey: "metrics.instagram_business_follows",
            name: "IG Follows from Posts",
            requiredSource: SOURCE_IDS.INSTAGRAM,
          },
          {
            dataKey: "metrics.tiktok_profile_follows",
            name: "Tiktok",
            requiredSource: SOURCE_IDS.TIKTOK,
          },
          {
            name: "TikTok Ads",
            dataKey: "metrics.tiktok_ads_follows",
            requiredSource: SOURCE_IDS.TIKTOK_ADS,
          },
          {
            name: "Facebook",
            dataKey: "metrics.facebook_pages_follows",
            requiredSource: SOURCE_IDS.FACEBOOK,
          },
          {
            name: "YouTube",
            dataKey: "metrics.youtube_channel_follows",
            requiredSource: SOURCE_IDS.YOUTUBE,
          },
          {
            name: "Facebook Ads",
            dataKey: "metrics.facebook_ads_follows",
            requiredSource: SOURCE_IDS.FACEBOOK_ADS,
          },
        ],
        showPostsLink: true,
        showAdsLink: true,
        sortBy: SORT_BY.FOLLOWER,
      },
      [OVERVIEW_CHARTS.SUBSCRIBERS]: {
        id: OVERVIEW_CHARTS.SUBSCRIBERS,
        label: "Subscribers",
        widget: "total_new_subscribers_stacked_chart",
        requiredSources: [SOURCE_IDS.KLAVIYO, SOURCE_IDS.ATTENTIVE],
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.attentive_sms_subscribers",
            "metrics.klaviyo_email_subscribers",
          ],
        },
      },
      [OVERVIEW_CHARTS.SEARCH]: {
        id: OVERVIEW_CHARTS.SEARCH,
        label: "Search",
        widget: "branded_search_totals_stacked_chart",
        requiredSources: [SOURCE_IDS.GOOGLE_ADS, SOURCE_IDS.GOOGLE_SEARCH],
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.google_search_organic_branded_search_impressions",
            "metrics.google_ads_paid_branded_search_impressions",
            "metrics.google_ads_paid_branded_search_total_spend",
            "metrics.google_search_organic_branded_search_clicks",
            "metrics.google_ads_paid_branded_search_clicks",
          ],
        },
        customSources: [
          {
            dataKey: "metrics.google_search_organic_branded_search_impressions",
            name: "Google Organic Impressions",
            requiredSource: SOURCE_IDS.GOOGLE_SEARCH,
          },
          {
            dataKey: "metrics.google_ads_paid_branded_search_impressions",
            name: "Google Ads Impressions",
            requiredSource: SOURCE_IDS.GOOGLE_ADS,
          },
          {
            dataKey: "metrics.google_search_organic_branded_search_clicks",
            name: "Google Organic Clicks",
            requiredSource: SOURCE_IDS.GOOGLE_SEARCH,
          },
          {
            dataKey: "metrics.google_ads_paid_branded_search_clicks",
            name: "Google Ads Clicks",
            requiredSource: SOURCE_IDS.GOOGLE_ADS,
          },
        ],
      },
      [OVERVIEW_CHARTS.SHARES]: {
        id: OVERVIEW_CHARTS.SHARES,
        label: "Shares",
        widget: "total_shares_stacked_chart",
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.tiktok_videos_shares",
            "metrics.tiktok_ads_shares",
            "metrics.instagram_business_shares",
            "metrics.instagram_ads_shares",
            "metrics.facebook_pages_shares",
            "metrics.facebook_ads_shares",
            "metrics.youtube_channel_shares",
          ],
        },
        requiredSources: [
          SOURCE_IDS.INSTAGRAM,
          SOURCE_IDS.INSTAGRAM_ADS,
          SOURCE_IDS.FACEBOOK,
          SOURCE_IDS.TIKTOK,
          SOURCE_IDS.TIKTOK_ADS,
          SOURCE_IDS.YOUTUBE,
          SOURCE_IDS.FACEBOOK_ADS,
        ],
        showPostsLink: true,
        showAdsLink: true,
        sortBy: SORT_BY.SHARES,
      },
      [OVERVIEW_CHARTS.COMMENTS]: {
        id: OVERVIEW_CHARTS.COMMENTS,
        label: "Comments",
        widget: "total_comments_stacked_chart",
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.instagram_business_comments",
            "metrics.instagram_ads_comments",
            "metrics.tiktok_videos_comments",
            "metrics.tiktok_ads_comments",
            "metrics.facebook_pages_comments",
            "metrics.facebook_ads_comments",
            "metrics.youtube_channel_comments",
            "metrics.total_comments",
          ],
        },
        requiredSources: [
          SOURCE_IDS.INSTAGRAM,
          SOURCE_IDS.INSTAGRAM_ADS,
          SOURCE_IDS.FACEBOOK,
          SOURCE_IDS.FACEBOOK_ADS,
          SOURCE_IDS.TIKTOK,
          SOURCE_IDS.TIKTOK_ADS,
          SOURCE_IDS.YOUTUBE,
        ],
        showPostsLink: true,
        showAdsLink: true,
        sortBy: SORT_BY.COMMENTS,
      },
      [OVERVIEW_CHARTS.LIKES]: {
        id: OVERVIEW_CHARTS.LIKES,
        label: "Likes",
        widget: "total_likes_stacked_chart",
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.instagram_business_likes",
            "metrics.instagram_ads_likes",
            "metrics.tiktok_videos_likes",
            "metrics.tiktok_ads_likes",
            "metrics.facebook_pages_likes",
            "metrics.facebook_ads_likes",
            "metrics.youtube_channel_likes",
            "metrics.total_likes",
          ],
        },
        requiredSources: [
          SOURCE_IDS.INSTAGRAM,
          SOURCE_IDS.INSTAGRAM_ADS,
          SOURCE_IDS.FACEBOOK,
          SOURCE_IDS.FACEBOOK_ADS,
          SOURCE_IDS.TIKTOK,
          SOURCE_IDS.TIKTOK_ADS,
          SOURCE_IDS.YOUTUBE,
        ],
        showPostsLink: true,
        showAdsLink: true,
        sortBy: SORT_BY.REACTIONS,
      },
      [OVERVIEW_CHARTS.SAVES]: {
        id: OVERVIEW_CHARTS.SAVES,
        label: "Saves",
        widget: "total_saves_stacked_chart",
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.instagram_business_saves",
            "metrics.instagram_ads_saves",
            "metrics.tiktok_ads_saves",
          ],
        },
        requiredSources: [
          SOURCE_IDS.INSTAGRAM,
          SOURCE_IDS.INSTAGRAM_ADS,
          SOURCE_IDS.TIKTOK_ADS,
        ],
        showPostsLink: true,
        showAdsLink: true,
        sortBy: SORT_BY.SAVES,
      },
      [OVERVIEW_CHARTS.PROFILE_VIEWS]: {
        id: OVERVIEW_CHARTS.PROFILE_VIEWS,
        label: "Profile Views",
        widget: "total_social_profile_views_stacked_chart",
        lastDateChipProps: {
          dateMetric: "metrics.date",
          filterMetrics: [
            "metrics.instagram_business_profile_views",
            "metrics.tiktok_profile_views",
            "metrics.facebook_pages_views",
            "metrics.total_social_profile_views",
          ],
        },
        requiredSources: [
          SOURCE_IDS.INSTAGRAM,
          SOURCE_IDS.FACEBOOK,
          SOURCE_IDS.TIKTOK,
        ],
        showPostsLink: false,
        showAdsLink: false,
        // sortBy: SORT_BY.PROFILE_VIEWS,
      },
      // [OVERVIEW_CHARTS.MENTIONS]: {
      //   id: OVERVIEW_CHARTS.MENTIONS,
      //   label: "Mentions",
      //   lastDateChipProps: {
      //     dateMetric: "metrics.date",
      //     filterMetrics: [
      //       "metrics.facebook_pages_mentions",
      //     ],
      //   },
      //   widget: "facebook_mentions_area_chart",
      //   requiredSources: [SOURCE_IDS.FACEBOOK],
      // },
    } as Record<TOverviewChartId, TOverviewChart>,
    title: "Overview",
    periodPicker: {
      visible: true,
    },
  },
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  return {
    content,
    sources,
    isLoading: isSourcesDataLoading,
  }
}
