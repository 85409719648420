import { toast } from "react-toastify"
import { useAuthControllerConfirmEmailMutation } from "~/ui-rtk/api/authApi"

export const useConnect = () => {
  const [confirmEmailAsync] = useAuthControllerConfirmEmailMutation()

  const confirmEmail = async (token: string) => {
    try {
      await confirmEmailAsync({
        confirmEmailDto: {
          token,
        },
      }).unwrap()
    } catch (error) {
      toast.error(JSON.stringify(error), {
        toastId: "onboarding-confirm-user-email-id",
      })
    }
  }

  return { confirmEmail }
}
