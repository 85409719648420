import { Select } from "~/ui-rtk/components/ui/controls"
import { GRANULARITIES } from "../../constants"
import { useCallback } from "react"
import WeekPicker from "~/ui-rtk/components/ui/controls/WeekPicker"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"
import MonthPicker from "~/ui-rtk/components/ui/controls/MonthPicker/MonthPicker"
import QuarterPicker from "~/ui-rtk/components/ui/controls/QuarterPicker/QuarterPicker"
import YearPicker from "~/ui-rtk/components/ui/controls/YearPicker/YearPicker"
import { useDefaultPeriodByDateAndGranularity } from "~/ui-rtk/hooks/date-range"

export type TPeriodPickerProps = {
  dateRange: [Date, Date]
  onDateRangeChange: (dateRange: [Date, Date]) => void
  maxDateRestriction: Date
  granularity: BrandMediaAggregationType
  setGranularity: (agg: BrandMediaAggregationType) => void
  isPartialPeriod?: boolean
}

export default function PeriodPicker({
  dateRange,
  granularity,
  setGranularity,
  onDateRangeChange,
  maxDateRestriction,
  isPartialPeriod = false,
}: TPeriodPickerProps) {
  const [startOfPeriod] = useDefaultPeriodByDateAndGranularity(
    dateRange[1],
    granularity,
  )

  const handleChangeGranularity = (granularity: BrandMediaAggregationType) =>
    setGranularity(granularity)

  const handleChangePeriod = useCallback(
    (startOfPeriod: Date) => {
      const [, endOfPeriod] = useDefaultPeriodByDateAndGranularity(
        startOfPeriod,
        granularity,
      )
      void onDateRangeChange([startOfPeriod, endOfPeriod])
    },
    [granularity, dateRange],
  )

  const renderPeriodPicker = useCallback(() => {
    switch (granularity) {
      case BrandMediaAggregationType.WEEKLY: {
        return (
          <WeekPicker
            isPartialPeriod={isPartialPeriod}
            startOfWeek={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
      case BrandMediaAggregationType.MONTHLY: {
        return (
          <MonthPicker
            isPartialPeriod={isPartialPeriod}
            startOfMonth={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
      case BrandMediaAggregationType.QUARTERLY: {
        return (
          <QuarterPicker
            isPartialPeriod={isPartialPeriod}
            startOfQuarter={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
      case BrandMediaAggregationType.YEARLY: {
        return (
          <YearPicker
            isPartialPeriod={isPartialPeriod}
            startOfYear={startOfPeriod}
            onChange={handleChangePeriod}
            maxDate={maxDateRestriction}
          />
        )
      }
    }
  }, [granularity, dateRange])

  return (
    <div className="flex gap-2 items-end">
      <Select
        className="h-full text-base"
        options={GRANULARITIES}
        value={granularity}
        onChange={ev =>
          handleChangeGranularity(
            (ev.target as HTMLSelectElement).value as BrandMediaAggregationType,
          )
        }
      />
      {renderPeriodPicker()}
    </div>
  )
}
