import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import useSources from "~/ui-rtk/hooks/sources"
import { getContentWidgetIds } from "~/ui-rtk/utils/content"
import { useVisualizationsMap } from "~/ui-rtk/hooks/visualizations"

const content = {
  id: "clrtjgjl7000kgl54esksbn0k",
  slug: "/brand-health/owned-and-organic",
  icon: null,
  title: "Owned & Organic",
  parent: {
    slug: "/brand-health",
    title: "Brand Health",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 1.9,
  content: {
    items: [
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "revenue_break_down_stacked_chart",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "min-h-64",
        grid: 1,
        items: [
          {
            widget: "session_paid_triggered_and_baseline_traffic_data_table",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "h-120",
        grid: 1,
        items: [
          {
            widget: "baseline_traffic_vs_revenue_stacked_chart",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        className: "h-50",
        grid: 3,
        items: [
          {
            widget: "sessions_from_organic_sessions",
          },
          {
            widget: "sessions_from_direct_sessions",
          },
          {
            widget: "sessions_from_organic_referal_sessions",
          },
        ],
      },
      {
        className: "h-10",
        grid: 1,
        items: [
          {
            widget: "SPACER",
          },
        ],
      },
      {
        grid: 1,
        items: [
          {
            className: "min-h-200",
            widget: "session_source_and_medium_traffic_data_table",
          },
        ],
      },
    ],
    title: "Owned & Organic",
    periodPicker: {
      visible: true,
    },
    commonGranularityPicker: true,
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  const widgetIds = getContentWidgetIds(
    content.content.items as TDashboardItemsSet,
  )
  const { visualizations } = useVisualizationsMap(widgetIds)

  const props = {
    commonGranularityPicker: content.content.commonGranularityPicker,
  }

  return {
    content,
    sources,
    visualizations,
    props,
    isLoading: isSourcesDataLoading,
  }
}
