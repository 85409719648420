import { useSearchParams } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { useConnect } from "./connect"
import { useEffect, useState } from "react"
import TokenExpiredView from "~/ui-rtk/pages/TokenExpiredView"

export default function ConfirmEmail() {
  const { confirmEmail } = useConnect()

  const navigate = useStableNavigate()
  const [searchParams] = useSearchParams()
  const [message, setMessage] = useState("Confirming...")

  const token = searchParams.get("nonce")
  if (!token) {
    return <TokenExpiredView />
  }

  const redirect = () => {
    setTimeout(() => navigate("/"), 3000)
  }

  useEffect(() => {
    const beginAction = async (token: string) => {
      try {
        await confirmEmail(token)
        void setMessage("Success! Redirecting...")
        void redirect()
      } catch (error) {
        void setMessage("Confirmation failed. Redirecting...")
        void redirect()
      }
    }

    if (token) {
      void beginAction(token)
    }
  }, [token])

  return <div className="fixed top-10 left-2">{message}</div>
}
