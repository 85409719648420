import { useMemo } from "react"

import { Chart, TableVisualization } from "~/ui-rtk/components/ui/charts"
import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import {
  TableVisualizationColumn,
  TSource,
} from "~/ui-rtk/constants/table-visualization-types"
import { DashboardBreadcrumbs } from "~/ui-rtk/components/ui/common"
import {
  Button,
  MultiSelect,
  PeriodPicker,
} from "~/ui-rtk/components/ui/controls"
import { TMultiSelectOption } from "~/ui-rtk/components/ui/controls/MultiSelect"
import {
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
} from "~/ui-rtk/constants/charts"
import { DRAWER_TYPE, TDrawerConfig } from "~/ui-rtk/constants/drawer"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import useDrawer from "~/ui-rtk/hooks/drawer"
import useSources from "~/ui-rtk/hooks/sources"
import {
  BRAND_MEDIA_SOCIAL_POSTS_TABLES,
  type TBrandMediaSocialPostsTableId,
  type TBrandMediaSocialPostsTable,
} from "~/ui-rtk/pages/BrandMedia/OrganicSocialPosts/connect"
import { TCubeFilterOptions } from "~/ui-rtk/shared/types/charts"
import BrandMediaSocialPostsSortedSection from "./components/SortedSection/SortedSection"
import { Link } from "react-router-dom"
import { DATE_FORMAT, usePeriod } from "~/ui-rtk/hooks/period"
import useDate from "~/ui-rtk/hooks/date"
import {
  type TLastUpdateDateProps,
  useLastUpdateDate,
} from "~/ui-rtk/hooks/cube"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { URL_WIDGET_PARAM_KEY } from "~/ui-rtk/constants/url-params"
import { LastUpdatedChip } from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import useUrlColumnKeys from "~/ui-rtk/hooks/url-column-keys"

type TBrandMediaSocialPostsDashboardCharts = Record<
  TBrandMediaSocialPostsTableId,
  TBrandMediaSocialPostsTable
>

type TSocialPostsDashboardProps = {
  slug: string
  title: string
  parent: {
    slug: string
    title: string
  }
  items: TBrandMediaSocialPostsDashboardCharts
  lastDateChipProps: TLastUpdateDateProps
}

const BEST_WORST_ENABLED = false

const dayjs = useDate()
const maxDateRestriction = dayjs().subtract(7, "day").toDate()

export const BrandMediaSocialPostsDashboard: React.FC<
  TSocialPostsDashboardProps
> = ({ items, slug, title, parent, lastDateChipProps }) => {
  const { params, setParams } = useURLSearchParams()
  const { sources } = useSources()
  const { setPeriod } = usePeriod()
  const { openDrawer } = useDrawer()
  const { dateMetric, filterMetrics } = lastDateChipProps

  const { lastDate, isLoading: isLastUpdateDateLoading } = useLastUpdateDate({
    dateMetric,
    filterMetrics,
  })
  const { dateRange, compareRange } = useMinMaxDateRange(
    undefined,
    lastDate ? new Date(lastDate) : maxDateRestriction,
  )
  const { granularity } = usePeriod()

  const bestWorstEnabled = BEST_WORST_ENABLED || params.enable_best_worst

  const visibleTabs: TBrandMediaSocialPostsDashboardCharts = useMemo(
    () =>
      Object.keys(items)
        .filter(chartId => {
          const { requiredSources } =
            items[chartId as unknown as TBrandMediaSocialPostsTableId]
          return (
            !requiredSources?.length ||
            requiredSources?.some((source: string) => sources?.has(source))
          )
        })
        .reduce(
          (filteredCharts, chartId) => {
            filteredCharts[
              chartId as unknown as TBrandMediaSocialPostsTableId
            ] = items[chartId as unknown as TBrandMediaSocialPostsTableId]
            return filteredCharts
          },
          {} as Record<
            TBrandMediaSocialPostsTableId,
            TBrandMediaSocialPostsTable
          >,
        ),
    [items],
  )

  const defaultVisibleChart = useMemo(
    () =>
      visibleTabs[
        Object.keys(visibleTabs)[0] as unknown as TBrandMediaSocialPostsTableId
      ],
    [visibleTabs],
  )
  const widgetURLSearchParam =
    params[URL_WIDGET_PARAM_KEY] ?? defaultVisibleChart?.id

  const activeWidget = useMemo(() => {
    const widgetId =
      widgetURLSearchParam?.toUpperCase() as TBrandMediaSocialPostsTableId

    if (widgetURLSearchParam && visibleTabs[widgetId]) {
      return visibleTabs[widgetId]
    }
    return defaultVisibleChart
  }, [widgetURLSearchParam])

  const chartProps = useMemo(
    () => VISUALIZATIONS_MAP[activeWidget?.widget]?.props,
    [activeWidget],
  )

  const defaultColumnKeys = useMemo(() => {
    const props = VISUALIZATIONS_MAP[activeWidget?.widget]?.props
    return props?.columnConfig.reduce(
      (
        acc: string[],
        column: TableVisualizationColumn & { disabled: boolean },
      ) => {
        if (!column.disabled) {
          acc.push(column.key)
        }
        return acc
      },
      [],
    )
  }, [activeWidget])

  const queryPrefix = chartProps?.queryPrefix

  const chartPropsSorting = useMemo(
    () =>
      chartProps?.sortBy
        ? [
            {
              id: chartProps.sortBy,
              desc: true,
            },
          ]
        : [],
    [chartProps],
  )

  const { columnKeys, setColumnKeys } = useUrlColumnKeys(
    activeWidget?.widget,
    defaultColumnKeys,
  )

  const columnOptions = useMemo(() => {
    const options = chartProps?.columnConfig.map(
      (column: TableVisualizationColumn) =>
        ({
          id: column.key,
          label: column.header,
        }) as TMultiSelectOption,
    )

    return options || []
  }, [chartProps])

  const cubeFilters: TCubeFilterOptions | null = useMemo(() => {
    if (activeWidget?.id === BRAND_MEDIA_SOCIAL_POSTS_TABLES.ALL_POSTS) {
      return null
    }

    const mediaChannel = activeWidget?.mediaChannel

    return {
      and: [
        {
          dimension: `company_media_metrics_posts.media_channel`,
          operator: "equals",
          values: [mediaChannel || ""],
        },
      ],
    }
  }, [queryPrefix, activeWidget])

  const renderChannelIcon = (tab: TBrandMediaSocialPostsTable) => {
    if (!tab.mediaChannel) {
      return null
    }

    const source = sources.get(tab?.mediaChannel.toUpperCase())
    return (
      <>
        {renderSourceIcon({
          name: source?.name,
          icon: source?.icon,
        } as TSource)}
      </>
    )
  }

  const changePeriod = (dateRange: [Date, Date]) => {
    const [start, end] = dateRange.map(date => dayjs(date).format(DATE_FORMAT))

    void setPeriod(start, end)
  }

  const handleSetWidget = (widgetId: string) => {
    setParams({
      [URL_WIDGET_PARAM_KEY]: widgetId.toLowerCase(),
    })
  }

  const handleOpenDrawer = (config: Partial<TDrawerConfig>) => {
    const drawerType = DRAWER_TYPE.CampaingAdSetAdDetails
    const component = "PostDetails"

    openDrawer({
      ...config,
      props: {
        ...config.props,
        component,
        widget: activeWidget?.widget,
      },
      type: drawerType,
    } as TDrawerConfig)
  }

  return (
    <>
      <DashboardBreadcrumbs slug={slug} title={title} parent={parent} />
      {bestWorstEnabled && (
        <div className="mt-3">
          <BrandMediaSocialPostsSortedSection
            activeWidget={
              visibleTabs[
                BRAND_MEDIA_SOCIAL_POSTS_TABLES.ALL_POSTS as TBrandMediaSocialPostsTableId
              ]
            }
            dateRange={dateRange}
          />
        </div>
      )}
      <div className="flex justify-between gap-2 mt-4">
        <div className="join max-h-14">
          {Object.values(visibleTabs).map(tab => (
            <Button
              variant={{ variant: "solid", color: "blue" }}
              key={tab.id}
              title={tab.label}
              className={`join-item border text-3.5 px-4 py-3 rounded-md ${tab.id === activeWidget?.id ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
              onClick={() => handleSetWidget(tab.id)}
            >
              {renderChannelIcon(tab)} {tab.label}
            </Button>
          ))}
        </div>
        <div className="flex justify-end align-center gap-2">
          <div className="flex align-center">
            <LastUpdatedChip
              lastDate={lastDate}
              isLoading={isLastUpdateDateLoading}
            >
              Max date available in Page:
            </LastUpdatedChip>
          </div>
          <MultiSelect
            label="Columns"
            options={columnOptions as TMultiSelectOption[]}
            value={columnOptions.filter((option: TMultiSelectOption) =>
              (columnKeys as string[]).includes(option.id),
            )}
            onChange={(options: TMultiSelectOption[]) =>
              setColumnKeys(options.map(({ id }) => id))
            }
            className="text-3.5"
          />
          <PeriodPicker
            dateRange={dateRange}
            onChange={changePeriod}
            classes={{ input: "py-3.5" }}
          />
        </div>
      </div>
      <div className="mt-3">
        {activeWidget ? (
          <Chart
            {...chartProps}
            chartId={activeWidget?.widget}
            className="min-h-120"
            summaryWidget={activeWidget?.summaryWidget}
            widgetType={VISUALIZATION_TYPE.TableVisualization}
            component={TableVisualization}
            dateRange={dateRange}
            compareRange={compareRange ?? undefined}
            granularity={granularity}
            sources={sources}
            defaultSorting={chartPropsSorting}
            requiredSources={activeWidget?.requiredSources}
            hideAggSelector={true}
            setDataAggType={() => undefined}
            cubeFilters={cubeFilters}
            useLocalStoragePagination={!activeWidget?.summaryWidget}
            columnConfig={chartProps.columnConfig.filter(
              ({ key }: { key: string }) =>
                (columnKeys as string[])?.includes(key),
            )}
            openDrawer={handleOpenDrawer}
          />
        ) : (
          <div>
            It seems like you don’t have active connection to show anything on
            this page. Please{" "}
            <Link
              className="link"
              to="/setup/connectors"
              title="Connector page"
            >
              review your connections.
            </Link>
          </div>
        )}
      </div>
    </>
  )
}
