import { AggregationType, BrandMediaAggregationType } from "../constants/charts"
import { usePeriod } from "./period"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

type TReturnType = {
  dateRange: [Date, Date]
  compareRange: [Date, Date] | null
}

const defaultMaxDate = dayjs().subtract(3, "day").toDate()

export const useMinMaxDateRange = (
  minDate?: Date,
  maxDate?: Date,
  defaultGranularity?: BrandMediaAggregationType | AggregationType,
  resetPeriodOnGranularityChange = false,
): TReturnType => {
  const { lastDate } = useLastUpdateDate()
  const { period: dateRange, comparePeriod: compareRange } = usePeriod(
    defaultGranularity,
    resetPeriodOnGranularityChange,
  )
  const maxDateWithDefault =
    maxDate ?? (lastDate ? new Date(lastDate) : undefined) ?? defaultMaxDate
  let adjustedStartDate: Date = dayjs(dateRange[0]).toDate()
  let adjustedEndDate: Date = dayjs(dateRange[1]).toDate()
  let compareRangeAdjusted: [Date, Date] | null = null

  if (minDate && adjustedStartDate < minDate) {
    adjustedStartDate = minDate
  }

  if (maxDateWithDefault && adjustedEndDate > maxDateWithDefault) {
    adjustedEndDate = maxDateWithDefault
  }

  if (compareRange && compareRange[0] && compareRange[1]) {
    compareRangeAdjusted = [
      dayjs(compareRange[0]).toDate(),
      dayjs(compareRange[1]).toDate(),
    ]
  }

  return {
    dateRange: [adjustedStartDate, adjustedEndDate],
    compareRange: compareRangeAdjusted,
  }
}

export const useDefaultPeriodByDateAndGranularity = (
  date: Date,
  granularity: BrandMediaAggregationType | AggregationType,
) => {
  const baseDate = dayjs(date)

  const startOfPeriod = (() => {
    switch (granularity) {
      case AggregationType.DAILY: {
        return baseDate.toDate()
      }
      case BrandMediaAggregationType.WEEKLY: {
        return baseDate.weekday(0).toDate()
      }
      case BrandMediaAggregationType.MONTHLY: {
        return baseDate.startOf("month").toDate()
      }
      case BrandMediaAggregationType.QUARTERLY: {
        return baseDate.startOf("quarter").toDate()
      }
      case BrandMediaAggregationType.YEARLY: {
        return baseDate.startOf("year").toDate()
      }
      default: {
        throw new Error("Wrong granularity")
      }
    }
  })()

  const endOfPeriod = (() => {
    let endOfPeriod: Date
    switch (granularity) {
      case AggregationType.DAILY: {
        return baseDate.toDate()
      }
      case BrandMediaAggregationType.WEEKLY: {
        endOfPeriod = baseDate.add(6, "day").toDate()
        break
      }
      case BrandMediaAggregationType.MONTHLY: {
        endOfPeriod = baseDate.endOf("month").toDate()
        break
      }
      case BrandMediaAggregationType.QUARTERLY: {
        endOfPeriod = baseDate.endOf("quarter").toDate()
        break
      }
      case BrandMediaAggregationType.YEARLY: {
        endOfPeriod = baseDate.endOf("year").toDate()
        break
      }
      default: {
        throw new Error("Wrong granularity")
      }
    }
    return endOfPeriod
  })()

  return [startOfPeriod, endOfPeriod]
}
