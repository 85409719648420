import { useMemo, useState } from "react"
import { useCompanyConnectorControllerGetCompanyConnectorsQuery as useGetCompanyConnectorsQuery } from "~/ui-rtk/api/companyConnectorApi"
import { CONNECTORS_IDS } from "~/ui-rtk/constants/sources"
import { TRadioToggleOption } from "~/ui-rtk/components/ui/controls/RadioToggle/RadioToggle"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { toast } from "react-toastify"
import { hasErrorMessage } from "~/ui-rtk/utils/http-utils"
import { ConnectionStatus } from "~/ui-rtk/api/types"
import {
  POSTHOG_FEATURES,
  type TPosthogFeatureKey,
} from "~/ui-rtk/constants/feature-flags"
import usePostHogFeatureFlags from "~/ui-rtk/hooks/posthog-feaures"
import { useCompanyMetadataUpdater } from "~/ui-rtk/hooks/company-updater"

const REVENUE_SOURCES_KEYS = {
  [CONNECTORS_IDS.SHOPIFY]: CONNECTORS_IDS.SHOPIFY,
  [CONNECTORS_IDS.GOOGLE_ANALYTICS_4]: CONNECTORS_IDS.GOOGLE_ANALYTICS_4,
  [CONNECTORS_IDS.AMAZON_SELLING_PARTNER]:
    CONNECTORS_IDS.AMAZON_SELLING_PARTNER,
}

type RevenueSourceKey = keyof typeof REVENUE_SOURCES_KEYS
type RevenueSourceValue = {
  id: string
  name: string
  icon: {
    src: string
    alt: string
  }
}

export type TRevenueSourceFormValues = {
  revenueSource?: string
}

const REVENUE_SOURCES_INFO: {
  [key in RevenueSourceKey]: RevenueSourceValue
} = {
  [CONNECTORS_IDS.SHOPIFY]: {
    id: CONNECTORS_IDS.SHOPIFY,
    name: "Shopify",
    icon: {
      src: "/assets/img/connectors/shopify.png",
      alt: "Shopify icon",
    },
  },
  [CONNECTORS_IDS.GOOGLE_ANALYTICS_4]: {
    id: CONNECTORS_IDS.GOOGLE_ANALYTICS_4,
    name: "Google Analytics",
    icon: {
      src: "/assets/img/connectors/ga.png",
      alt: "Google Analytics Icon",
    },
  },
}

const FEATURE_FLAGGED_REVENUE_SOURCE_INFO: {
  [key in RevenueSourceKey]: RevenueSourceValue & {
    featureFlag: TPosthogFeatureKey
  }
} = {
  [CONNECTORS_IDS.AMAZON_SELLING_PARTNER]: {
    id: CONNECTORS_IDS.AMAZON_SELLING_PARTNER,
    name: "Amazon Estimates",
    featureFlag: POSTHOG_FEATURES.AMAZON_REVENUE_SOURCE,
    icon: {
      src: "/assets/img/connectors/amazon_selling_partner.svg",
      alt: "Amazon Selling Partenr Icon",
    },
  },
}

export const useConnect = () => {
  const {
    data: companyConnectors,
    isLoading: isCompanyConnectorsLoading,
    isError: isCompanyConnectorsError,
  } = useGetCompanyConnectorsQuery()
  const { updateCompanyMetadata } = useCompanyMetadataUpdater()

  const sourceInfo: {
    [key in RevenueSourceKey]: RevenueSourceValue
  } = {
    ...REVENUE_SOURCES_INFO,
  }

  Object.keys(FEATURE_FLAGGED_REVENUE_SOURCE_INFO).forEach(key => {
    const source = FEATURE_FLAGGED_REVENUE_SOURCE_INFO[key]
    if (usePostHogFeatureFlags(source.featureFlag)) {
      const { featureFlag: _, ...sourceInfoToAdd } = source
      sourceInfo[source.id] = {
        ...sourceInfoToAdd,
      }
    }
  })

  const currentCompany = useAppSelector(selectCurrentCompany)
  const { revenueSource } = (currentCompany?.metadata ?? {}) as {
    revenueSource?: string
  }

  const initialValues: TRevenueSourceFormValues = {
    revenueSource,
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSaveSelectedSource = async (val: TRevenueSourceFormValues) => {
    const metadata = {
      ...(currentCompany.metadata ?? {}),
      ...val,
    }

    void setIsSubmitting(true)

    try {
      await updateCompanyMetadata(metadata)
      toast.success("Revenue source was updated successfully", {
        toastId: "revenue-source-update-success",
      })
    } catch (error) {
      if (hasErrorMessage(error)) {
        toast.error(error.data.message, {
          toastId: "revenue-source-update-error",
        })
      }
    } finally {
      void setIsSubmitting(false)
    }
  }

  const isError = isCompanyConnectorsError
  const isLoading = isCompanyConnectorsLoading

  const { sources } = useMemo(
    () => ({
      sources: Object.keys(sourceInfo).map((key: RevenueSourceKey) => {
        const revenueSourceInfo = sourceInfo[key]
        const connector = companyConnectors?.find(c => c.serviceName === key)
        return {
          label: revenueSourceInfo.name,
          value: revenueSourceInfo.id,
          icon: revenueSourceInfo.icon,
          disabled: connector?.status !== ConnectionStatus.CONNECTED,
        } as TRadioToggleOption
      }),
    }),
    [companyConnectors],
  )

  return {
    sources,
    initialValues,
    isError,
    isLoading,
    isSubmitting,
    handleSaveSelectedSource,
  }
}
