import { URL_COLUMN_ORDER_PARAM_KEY } from "../constants/url-params"
import { useUrlParamsStateArray } from "./urlParamsState"

export default function useUrlColumnOrder(
  chartId = "",
  defaultColumnOrder: string[] = [],
) {
  const urlKey = `${chartId}_${URL_COLUMN_ORDER_PARAM_KEY}`
  const { state: columnOrder, setState: setColumnOrder } =
    useUrlParamsStateArray<Array<string>>(
      `${chartId}_${URL_COLUMN_ORDER_PARAM_KEY}`,
      defaultColumnOrder,
    )

  return {
    urlKey,
    columnOrder,
    setColumnOrder,
  }
}
