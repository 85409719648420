import { ElementType, useMemo } from "react"

import { useAppSelector } from "~/ui-rtk/app/hooks"
import { H2 } from "~/ui-rtk/components/ui/typography"

import { PeriodPicker } from "../../../ui/controls"
import {
  AggregationType,
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
} from "~/ui-rtk/constants/charts"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"

import "./mind-share.scss"
import RichTooltip from "../../../ui/common/RichTooltip"
import Card from "../../../ui/common/Card"
import { InfoSvg } from "~/ui-rtk/components/ui/svg/essentials"
import Chart from "~/ui-rtk/components/ui/charts/Chart"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import {
  TMindShareChart,
  URL_WIDGET_PARAM_KEY,
} from "~/ui-rtk/pages/BrandHealth/MindShare/connect"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { ComposedChart } from "../../../ui/charts"
import { MultiChartItem } from "../../../ui/charts/MultiChartItem/MultiChartItem"
import { startCase } from "lodash"
import Total from "./components/Total"
import { usePeriod, DATE_FORMAT } from "~/ui-rtk/hooks/period"
import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import useDate from "~/ui-rtk/hooks/date"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { LastUpdatedChip } from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"

type TDashboardCharts = Record<string, TMindShareChart>

export interface TBrandsHealthMindShareDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  charts: TDashboardCharts
  parent: {
    slug: string
    title: string
  }
}

type TBrandHealthMindShareWidget = TMindShareChart & {
  component: ElementType
  groupValue?: string
  label?: string
  slug: string
}

const dayjs = useDate()

export default function BrandsHealthMindShareDashboard({
  title,
  charts,
  periodPicker,
  slug,
  parent,
}: TBrandsHealthMindShareDashboardProps) {
  const { params, setParams } = useURLSearchParams()
  const isPeriodPickerVisible = periodPicker?.visible ?? false

  const currentCompany = useAppSelector(selectCurrentCompany)
  const { keywords } = (currentCompany?.metadata ?? {}) as { keywords: any }
  const categories = [...(keywords?.google?.categories ?? [])]

  const visibleTabs = useMemo(
    () =>
      Object.keys(charts).reduce(
        (
          plainCharts: Record<string, TBrandHealthMindShareWidget>,
          key: string,
        ) => {
          const chart = charts[key]
          if (chart.multi) {
            categories.forEach(category => {
              plainCharts[`${key}_${category}`.toUpperCase()] = {
                ...chart,
                slug: `${key}_${category}`,
                component: MultiChartItem,
                groupValue: category,
                label: chart.widgetLabelPrefix
                  ? `${chart.widgetLabelPrefix} ${startCase(category)}`
                  : startCase(category),
              }
            })
            return plainCharts
          }
          plainCharts[key] = {
            ...chart,
            slug: chart.id,
            component: ComposedChart,
          }
          return plainCharts
        },
        {} as Record<string, TBrandHealthMindShareWidget>,
      ),
    [charts],
  )

  const defaultVisibleTab = useMemo(
    () => visibleTabs[Object.keys(visibleTabs)[0]],
    [visibleTabs],
  )

  const widgetURLSearchParam =
    decodeURI(params[URL_WIDGET_PARAM_KEY]) ?? defaultVisibleTab.id

  const activeWidget = useMemo(() => {
    const widgetId = widgetURLSearchParam?.toUpperCase()

    if (widgetId && visibleTabs[widgetId]) {
      return visibleTabs[widgetId]
    }
    return defaultVisibleTab
  }, [widgetURLSearchParam, visibleTabs])

  const { dateMetric, filterMetrics } = activeWidget.lastDateChipProps

  const { lastDate, isLoading: isLastUpdateDateLoading } = useLastUpdateDate({
    dateMetric,
    filterMetrics,
  })

  const { dateRange, compareRange } = useMinMaxDateRange(
    undefined,
    new Date(lastDate ?? ""),
  )
  const { granularity, setPeriod, setComparePeriod, setGranularity } =
    usePeriod()

  const chartProps = useMemo(
    () => VISUALIZATIONS_MAP[activeWidget.widget]?.props,
    [activeWidget],
  )

  const handleChangePeriods = (
    dateRange: [Date, Date],
    compareDateRange: [Date, Date] | null,
  ) => {
    setPeriod(
      dayjs(dateRange[0]).format(DATE_FORMAT),
      dayjs(dateRange[1]).format(DATE_FORMAT),
    )

    if (compareDateRange && compareDateRange.length > 0) {
      setComparePeriod(
        dayjs(compareDateRange[0]).format(DATE_FORMAT),
        dayjs(compareDateRange[1]).format(DATE_FORMAT),
      )
    } else {
      setComparePeriod()
    }
  }

  const handleSetDataAggType = (aggType: AggregationType) => {
    setGranularity(aggType)
  }

  const handleSetWidget = (widgetId: string) => {
    setParams({
      [URL_WIDGET_PARAM_KEY]: encodeURI(widgetId.toLowerCase()),
    })
  }

  const demo = useAppSelector(selectIsEnabled)

  return (
    <>
      <div className="flex justify-between">
        <div className="text-start">
          <H2>
            <Breadcrumbs
              items={[
                {
                  slug: parent.slug,
                  name: parent.title,
                },
                {
                  slug,
                  name: title,
                },
              ]}
            />
          </H2>
        </div>
        <div className="flex justify-end align-center gap-2">
          <div className="flex align-center">
            <LastUpdatedChip
              lastDate={lastDate}
              isLoading={isLastUpdateDateLoading}
            >
              Max date available in Page:
            </LastUpdatedChip>
          </div>
          <PeriodPicker
            dateRange={dateRange}
            compareRange={compareRange}
            opacity={isPeriodPickerVisible ? 1 : 0.5}
            onChange={handleChangePeriods}
            comparisonEnabled={
              VISUALIZATIONS_MAP[activeWidget.widget].props.comparisonEnabled ??
              false
            }
          />
        </div>
      </div>
      <div>
        <p className="inline-block text-base text-gray-700 leading-relaxed mt-6 mb-4 border border-vibrant-blue rounded-lg p-3">
          Google Keywords data updates on our platform occur monthly. It may
          take 4-6 weeks for new data to be reflected, based on Google's release
          schedule.
        </p>
      </div>
      <div className="ui-rtk-overview-dashboard border-b border-basic-blue join">
        {Object.values(visibleTabs).map(tab => (
          <div
            className={cn(
              "flex justify-between pt-3 pb-2.5 px-4 rounded-t-md border-b hover:bg-basic-dark-blue hover:border-b-basic-blue border-b-transparent",
              {
                "border-b-[4px] border-basic-blue bg-basic-blue/30 font-bold":
                  tab.slug === activeWidget?.slug,
                "blur-md":
                  demo.isEnabled &&
                  !["TOTAL", "BRAND_SHARE"].includes(tab.slug),
              },
            )}
            role="button"
            key={tab.slug}
            onClick={() => handleSetWidget(tab.slug)}
          >
            {tab.label}
            {tab.id === activeWidget?.id &&
              VISUALIZATIONS_MAP[activeWidget.widget].description && (
                <div className="ms-1">
                  <RichTooltip
                    content={
                      <Card
                        rounded
                        className="min-w-128 max-w-180 whitespace-normal text-start leading-5"
                      >
                        {VISUALIZATIONS_MAP[activeWidget.widget].description}
                      </Card>
                    }
                  >
                    <InfoSvg size={16} fill="white" />
                  </RichTooltip>
                </div>
              )}
          </div>
        ))}
      </div>
      <div className="mt-3">
        <Chart
          chartId={activeWidget.widget}
          className="h-[calc(100vh-300px)]"
          widgetType={VISUALIZATION_TYPE.ComposedChart}
          component={activeWidget.component}
          dateRange={dateRange}
          groupValue={activeWidget.groupValue}
          compareRange={compareRange ?? undefined}
          granularity={granularity}
          hideAggSelector={true}
          setDataAggType={handleSetDataAggType}
          totalComponent={Total}
          hideTooltipTitle={true}
          {...chartProps}
        />
      </div>
    </>
  )
}
