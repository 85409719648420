import { type TLastUpdateDateProps } from "~/ui-rtk/hooks/cube"
import useSources from "~/ui-rtk/hooks/sources"

export const MIND_SHARE_CHARTS = {
  TOTAL: "TOTAL",
  BRAND_SHARE: "BRAND_SHARE",
  CATEGORY_SHARE: "CATEGORY_SHARE",
}

export type TMindShareChartId = keyof typeof MIND_SHARE_CHARTS

export type TMindShareChart = {
  id: TMindShareChartId
  label: string
  widget: string
  multi?: boolean
  lastDateChipProps: TLastUpdateDateProps
  widgetLabelPrefix?: string
}

const lastDateChipProps = {
  dateMetric: "company_last_updated_date.brand_health_mind_share_max_date",
}

export const URL_WIDGET_PARAM_KEY = "widget"

const content = {
  id: "clrf9bec6000iqt54dhnl74e6",
  slug: "/brand-health/mind-share",
  icon: null,
  title: "Mind Share",
  parent: {
    slug: "/brand-health",
    title: "Brand Health",
  },
  metadata: {
    visibleToRoles: ["SUPER_ADMIN"],
  },
  order: 1.8,
  content: {
    charts: {
      [MIND_SHARE_CHARTS.TOTAL]: {
        id: MIND_SHARE_CHARTS.TOTAL,
        label: "Total",
        widget: "google_keywords_share_of_search_total_composed_chart",
        lastDateChipProps,
      },
      [MIND_SHARE_CHARTS.BRAND_SHARE]: {
        id: MIND_SHARE_CHARTS.BRAND_SHARE,
        label: "No Category",
        widget: "google_keywords_share_of_search_brands_share_composed_chart",
        lastDateChipProps,
      },
      [MIND_SHARE_CHARTS.CATEGORY_SHARE]: {
        id: MIND_SHARE_CHARTS.CATEGORY_SHARE,
        label: "Search",
        widget: "google_keywords_share_of_search_by_category_multi_chart",
        multi: true,
        lastDateChipProps,
        widgetLabelPrefix: "Category:",
      },
    } as Record<TMindShareChartId, TMindShareChart>,
    title: "Mind Share",
    periodPicker: {
      visible: true,
    },
  },
  items: [],
}

export default function useConnect() {
  const { sources, isLoading: isSourcesDataLoading } = useSources()

  return {
    content,
    sources,
    isLoading: isSourcesDataLoading,
  }
}
