import { CONNECTORS_IDS, type TConnectorId } from "~/ui-rtk/constants/sources"

export const CONNECTION_FLOW_STEPS = [
  {
    order: 0,
    title: "First, let's bring in your DTC ecomm analytics data",
    requiredConnectors: [CONNECTORS_IDS.GOOGLE_ANALYTICS_4],
  },
  {
    order: 1,
    title: "Next, let's bring in all your Facebook data",
    requiredConnectors: [
      CONNECTORS_IDS.FACEBOOK_ADS,
      CONNECTORS_IDS.FACEBOOK_PAGES,
    ],
  },
  {
    order: 2,
    title:
      "Instagram is up next. We need to make two connections to get a complete data set!",
    requiredConnectors: [
      CONNECTORS_IDS.INSTAGRAM_INSIGHTS,
      CONNECTORS_IDS.INSTAGRAM_BUSINESS,
    ],
  },
  {
    order: 3,
    title: "Next let's onboard your Tiktok Ads data",
    requiredConnectors: [CONNECTORS_IDS.TIKTOK_ADS],
  },
  {
    order: 4,
    title:
      "And, finally, let's bring on your Tiktok Organic data - note: the login here will be for your company's business Tiktok profile - not a personal account",
    requiredConnectors: [CONNECTORS_IDS.TIKTOK],
  },
]

export const ESSENTIAL_CONNECTION_FLOW_CONNECTORS_IDS: TConnectorId[] =
  CONNECTION_FLOW_STEPS.reduce(
    (acc, step) => [...acc, ...step.requiredConnectors],
    [] as TConnectorId[],
  )
