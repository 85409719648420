import { SessionRole } from "~/api/dto/auth/session-role"
import { useAppSelector } from "../app/hooks"
import { selectCurrentCompany } from "../app/selectors/company.selector"
import {
  selectSubscriptionData,
  selectUserSessionRole,
} from "../app/selectors/user.selector"
import { ACL } from "../utils/ACL"
import { useMemo } from "react"

export default function useAccess() {
  const sessionRole = useAppSelector(selectUserSessionRole)
  const currentCompany = useAppSelector(selectCurrentCompany)
  const subscriptionData = useAppSelector(selectSubscriptionData)

  const isSuperAdmin = useMemo(
    () => sessionRole === SessionRole.SUPER_ADMIN,
    [sessionRole],
  )

  const isAccessGranted = useMemo(
    () => ACL.isAccessGranted(currentCompany, subscriptionData),
    [currentCompany],
  )

  const hasAccess = useMemo(
    () => isSuperAdmin || isAccessGranted,
    [isSuperAdmin, isAccessGranted],
  )

  const isBillingEnabled = useMemo(
    () => Boolean(currentCompany?.metadata?.isBillingEnabled),
    [currentCompany],
  )

  return {
    hasAccess,
    isSuperAdmin,
    isAccessGranted,
    isBillingEnabled,
  }
}
