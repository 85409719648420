import {
  TimeDimensionGranularity,
  type Query as TCubeQuery,
  ResultSet,
} from "@cubejs-client/core"
import {
  useCubeQuery as _useCubeQuery,
  type UseCubeQueryOptions,
} from "@cubejs-client/react"
import { useMemo } from "react"
import { LocalStorageKeys, useLocalStorage } from "~/ui-rtk/utils/storage"
import { MetricsMapping, TMetric } from "../constants/metrics-mapping"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { type TCubeFilterOptions } from "../shared/types/charts"
import { useAuthControllerCurrentUserQuery } from "~/ui-rtk/api/authApi"
import {
  addSorting,
  getPivotData,
  polyfillCubeQueryWithTimeDimension,
} from "../utils/cube"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

type TWidget = keyof typeof MetricsMapping
const IN_MEMORY_SUMMARY_CACHE: Record<string, any> = {}
const IN_MEMORY_QUERY_CACHE: Record<string, TCubeQuery> = {}

const addPrefix = (obj: any): any => {
  const shouldApply = (v: string) =>
    v.startsWith("metrics.") ||
    v.startsWith("company_last_updated_date") ||
    v.startsWith("company_media_metrics_monthly_ads") ||
    v.startsWith("company_media_metrics_monthly_adsets") ||
    v.startsWith("company_media_metrics_monthly_campaigns") ||
    v.startsWith("company_media_metrics_posts") ||
    v.startsWith("company_media_metrics_quarterly_ads") ||
    v.startsWith("company_media_metrics_quarterly_adsets") ||
    v.startsWith("company_media_metrics_quarterly_campaigns") ||
    v.startsWith("company_media_metrics_weekly_ads") ||
    v.startsWith("company_media_metrics_weekly_adsets") ||
    v.startsWith("company_media_metrics_weekly_campaigns") ||
    v.startsWith("company_media_metrics_yearly_ads") ||
    v.startsWith("company_media_metrics_yearly_adsets") ||
    v.startsWith("company_media_metrics_yearly_campaigns") ||
    v.startsWith("company_mind_share_metrics_monthly") ||
    v.startsWith("company_modeled_metrics_monthly") ||
    v.startsWith("company_revenue_metrics_daily")

  if (typeof obj === "string" && shouldApply(obj)) {
    return `cache_${obj}`
  } else if (Array.isArray(obj)) {
    return obj.map(addPrefix)
  } else if (typeof obj === "object" && obj !== null) {
    const modifiedObject: Record<string, any> = {}
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const modifiedKey = shouldApply(key) ? `cache_${key}` : key
        modifiedObject[modifiedKey] = addPrefix(obj[key])
      }
    }
    return modifiedObject
  }
  return obj
}

const removePrefix = (obj: any): any => {
  const shouldRemove = (v: string) =>
    v.startsWith("cache_metrics") ||
    v.startsWith("cache_company_last_updated_date") ||
    v.startsWith("cache_company_media_metrics_monthly_ads") ||
    v.startsWith("cache_company_media_metrics_monthly_adsets") ||
    v.startsWith("cache_company_media_metrics_monthly_campaigns") ||
    v.startsWith("cache_company_media_metrics_posts") ||
    v.startsWith("cache_company_media_metrics_quarterly_ads") ||
    v.startsWith("cache_company_media_metrics_quarterly_adsets") ||
    v.startsWith("cache_company_media_metrics_quarterly_campaigns") ||
    v.startsWith("cache_company_media_metrics_weekly_ads") ||
    v.startsWith("cache_company_media_metrics_weekly_adsets") ||
    v.startsWith("cache_company_media_metrics_weekly_campaigns") ||
    v.startsWith("cache_company_media_metrics_yearly_ads") ||
    v.startsWith("cache_company_media_metrics_yearly_adsets") ||
    v.startsWith("cache_company_media_metrics_yearly_campaigns") ||
    v.startsWith("cache_company_mind_share_metrics_monthly") ||
    v.startsWith("cache_company_modeled_metrics_monthly") ||
    v.startsWith("cache_company_revenue_metrics_daily")

  if (typeof obj === "string" && shouldRemove(obj)) {
    return obj.replace(/^cache_/, "")
  } else if (Array.isArray(obj)) {
    return obj.map(removePrefix)
  } else if (typeof obj === "object" && obj !== null) {
    const modifiedObject: Record<string, any> = {}
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const modifiedKey = shouldRemove(key) ? key.replace(/^cache_/, "") : key
        modifiedObject[modifiedKey] = removePrefix(obj[key])
      }
    }
    return modifiedObject
  }
  return obj
}

export const useCubeQuery = (...args: Parameters<typeof _useCubeQuery>) => {
  const { data } = useAuthControllerCurrentUserQuery()
  const [useAthena] = useLocalStorage<string>(LocalStorageKeys.USE_ATHENA)
  // this is essential knowledge x == null is equivalent to x === null || x === undefined
  // eslint-disable-next-line eqeqeq
  if (data?.user?.dataSource == "LATEST" || useAthena != null) {
    return _useCubeQuery(...args)
  }

  let result = _useCubeQuery(addPrefix(args[0]) as TCubeQuery, args[1])

  result = {
    ...result,
    previousQuery: removePrefix(result.previousQuery),
  }
  if (result.resultSet != null) {
    result.resultSet = new ResultSet(
      // @ts-expect-error: resultSet is private
      removePrefix(result.resultSet?.loadResponse),
      // @ts-expect-error: resultSet is private
      result.resultSet?.options,
    )
  }
  return result
}

const generateKey = (
  companyId: string,
  widget: string,
  startDate: string,
  endDate: string,
  cubeFilters?: TCubeFilterOptions,
  cubeQueryParams?: Record<string, string>,
) =>
  `${companyId}.${widget}.${startDate}.${endDate}.f${JSON.stringify(cubeFilters)}.p${JSON.stringify(cubeQueryParams)}`

export function cubeQuery(customQuery: TCubeQuery, skip = false) {
  const queryOptions: UseCubeQueryOptions = useMemo(
    () => ({
      skip,
    }),
    [],
  )

  const {
    resultSet: result,
    isLoading,
    refetch,
  } = useCubeQuery(customQuery, queryOptions)

  return {
    isLoading,
    result,
    refetch,
  }
}

export function useSummary(
  widget: TWidget,
  dateRange: [Date, Date],
  cubeFilters?: TCubeFilterOptions,
  cubeQueryParams?: {
    granularity: TimeDimensionGranularity
  },
) {
  const currentCompany = useAppSelector(selectCurrentCompany)
  if (!currentCompany) {
    throw new Error("No current company")
  }

  const { id: companyId } = currentCompany

  const startDate = dayjs(dateRange[0]).format("YYYY-MM-DD")
  const endDate = dayjs(dateRange[1]).format("YYYY-MM-DD")
  const key = generateKey(
    companyId,
    widget,
    startDate,
    endDate,
    cubeFilters,
    cubeQueryParams as Record<string, string>,
  )

  const polyfillTimeDimensionPayload = {
    dateRange,
  }

  let query: TCubeQuery
  if (!IN_MEMORY_QUERY_CACHE[key]) {
    const { q } = MetricsMapping[widget]

    const cubeQ = typeof q === "function" ? q(cubeQueryParams ?? {}) : q

    query = polyfillCubeQueryWithTimeDimension(
      cubeQ as TMetric,
      polyfillTimeDimensionPayload,
    )

    query.limit = 1

    if (cubeFilters) {
      if (typeof cubeFilters === "object" && !Array.isArray(cubeFilters)) {
        const existingFilters = query.filters ?? []
        query.filters = [...existingFilters]

        if (cubeFilters?.and) {
          query.filters.push({ and: cubeFilters.and })
        }

        if (cubeFilters?.extraFilters) {
          query.filters.push(...cubeFilters.extraFilters)
        }
      }
    }

    IN_MEMORY_QUERY_CACHE[key] = query
  } else {
    query = IN_MEMORY_QUERY_CACHE[key]
  }

  const { result, isLoading } = cubeQuery(query, !!IN_MEMORY_SUMMARY_CACHE[key])

  if (result) {
    const res = getPivotData(result, query)
    const { postProcess } = MetricsMapping[widget]
    const processedResult = postProcess
      ? postProcess(res, cubeQueryParams ?? {})[0]
      : res
    IN_MEMORY_SUMMARY_CACHE[key] = processedResult
  }

  return {
    isLoading: Boolean(isLoading),
    summary: IN_MEMORY_SUMMARY_CACHE[key]
      ? IN_MEMORY_SUMMARY_CACHE[key][0]
      : null,
  }
}

export function useSummaries({
  widget,
  cubeFilters,
  dateRange,
  compareRange,
  cubeQueryParams,
}: {
  widget: TWidget
  dateRange: [Date, Date]
  compareRange?: [Date, Date]
  cubeFilters?: TCubeFilterOptions
  cubeQueryParams?: {
    granularity: TimeDimensionGranularity
  }
}) {
  const { summary: summaryData, isLoading } =
    widget && dateRange
      ? useSummary(widget, dateRange, cubeFilters, cubeQueryParams)
      : { summary: null, isLoading: false }

  const { summary: compareSummaryData, isLoading: isLoadingCompare } =
    compareRange && widget
      ? useSummary(widget, compareRange, cubeFilters, cubeQueryParams)
      : { summary: null, isLoading: false }

  return {
    summaryData,
    compareSummaryData,
    isLoading: isLoading || isLoadingCompare,
  }
}

const defaultMetric = "company_last_updated_date.general_max_date"

export type TLastUpdateDateProps = {
  dateMetric: string
  filterMetrics?: string[]
}

export function useLastUpdateDate(
  { dateMetric, filterMetrics }: TLastUpdateDateProps = {
    dateMetric: defaultMetric,
  },
) {
  const query = addSorting(
    {
      limit: 2,
      dimensions: [dateMetric],
      filters: filterMetrics
        ? [
            {
              or: filterMetrics.map((filter: string) => ({
                member: filter,
                operator: "set",
              })),
            },
          ]
        : undefined,
    },
    [
      {
        id: dateMetric,
        desc: true,
      },
    ],
  )
  const { result, isLoading, refetch } = cubeQuery(query)
  let lastDate
  if (result) {
    const parsedDate = getPivotData(result, query)
    lastDate = parsedDate?.[1]?.[dateMetric] ?? parsedDate?.[0]?.[dateMetric]
  }

  return {
    isLoading,
    lastDate: lastDate
      ? dayjs(lastDate as string).format("MMM D, YYYY")
      : undefined,
    refetch,
  }
}
