import { useMemo } from "react"

import useSources from "~/ui-rtk/hooks/sources"
import {
  OVERVIEW_CHARTS,
  URL_SOURCES_PARAM_KEY,
  URL_WIDGET_PARAM_KEY,
  type TOverviewChart,
  type TOverviewChartId,
} from "~/ui-rtk/pages/BrandHealth/Overview/connect"
import { H2 } from "~/ui-rtk/components/ui/typography"
import { Button, PeriodPicker } from "~/ui-rtk/components/ui/controls"
import {
  AggregationType,
  AGG_TYPES,
  VISUALIZATION_TYPE,
  VISUALIZATIONS_MAP,
} from "~/ui-rtk/constants/charts"
import Breadcrumbs from "~/ui-rtk/components/layout/Breadcrumbs"

import { useDateRanges } from "~/ui-rtk/utils/date-utils"
import RichTooltip from "~/ui-rtk/components/ui/common/RichTooltip"
import Card from "~/ui-rtk/components/ui/common/Card"
import { InfoSvg } from "~/ui-rtk/components/ui/svg/essentials"
import Chart from "~/ui-rtk/components/ui/charts/Chart"
import ComposedSimpleChart from "~/ui-rtk/components/ui/charts/ComposedSimpleChart/ComposedSimpleChart"
import { useMinMaxDateRange } from "~/ui-rtk/hooks/date-range"
import { DATE_FORMAT, usePeriod } from "~/ui-rtk/hooks/period"

import useDate from "~/ui-rtk/hooks/date"
import { useURLSearchParams } from "~/ui-rtk/hooks/url"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { useUrlParamsStateArray } from "~/ui-rtk/hooks/urlParamsState"
import { LastUpdatedChip } from "~/ui-rtk/components/ui/common/LastUpdatedChip/LastUpdatedChip"
import { useLastUpdateDate } from "~/ui-rtk/hooks/cube"
const dayjs = useDate()

type TOverviewDashboardCharts = Record<TOverviewChartId, TOverviewChart>

export interface TOverviewDashboardProps {
  title: string
  slug: string
  periodPicker?: { visible: boolean }
  charts: TOverviewDashboardCharts
  parent: {
    slug: string
    title: string
  }
}

export default function BrandHealthOverviewDashboard({
  title,
  charts,
  periodPicker,
  slug,
  parent,
}: TOverviewDashboardProps) {
  const { params, setParams } = useURLSearchParams()
  const { sources } = useSources()
  const isPeriodPickerVisible = periodPicker?.visible ?? false

  const { minAggType, maxAggType } = useDateRanges()

  const visibleCharts: Record<TOverviewChartId, TOverviewChart> = useMemo(
    () =>
      Object.keys(charts)
        .filter(chartId => {
          const { requiredSources } =
            charts[chartId as unknown as TOverviewChartId]
          return (
            !requiredSources?.length ||
            requiredSources?.some((source: string) => sources?.has(source))
          )
        })
        .reduce(
          (filteredCharts, chartId) => {
            filteredCharts[chartId as unknown as TOverviewChartId] =
              charts[chartId as unknown as TOverviewChartId]
            return filteredCharts
          },
          {} as Record<TOverviewChartId, TOverviewChart>,
        ),
    [charts],
  )

  const defaultVisibleChart = useMemo(
    () =>
      visibleCharts[
        Object.keys(visibleCharts)[0] as unknown as TOverviewChartId
      ],
    [visibleCharts],
  )

  const widgetURLSearchParam =
    params[URL_WIDGET_PARAM_KEY] ?? defaultVisibleChart?.id

  const activeWidget = useMemo(() => {
    const widgetId =
      widgetURLSearchParam?.toUpperCase() as keyof typeof OVERVIEW_CHARTS

    if (widgetURLSearchParam && OVERVIEW_CHARTS[widgetId]) {
      return charts[widgetId]
    }
    return defaultVisibleChart
  }, [widgetURLSearchParam])

  const { dateMetric, filterMetrics } = activeWidget.lastDateChipProps

  const { lastDate, isLoading: isLastUpdateDateLoading } = useLastUpdateDate({
    dateMetric,
    filterMetrics,
  })
  const { dateRange, compareRange } = useMinMaxDateRange(
    undefined,
    new Date(lastDate ?? ""),
  )
  const { granularity, setPeriod, setComparePeriod, setGranularity } =
    usePeriod()

  const { state: selectedSources, setState: setSelectedSources } =
    useUrlParamsStateArray<string[]>(URL_SOURCES_PARAM_KEY)

  const chartProps = useMemo(
    () => VISUALIZATIONS_MAP[activeWidget.widget]?.props,
    [activeWidget],
  )

  const sortBy = useMemo(() => activeWidget.sortBy, [activeWidget])
  // ToDo:
  const dataAggTypeOnly = null

  const aggregationGroups = useMemo(
    () =>
      AGG_TYPES.filter(({ type }, index) => {
        // if data agg type is set in config, hide all other options
        if (dataAggTypeOnly) {
          return dataAggTypeOnly === type
        }
        // if min agg type is weekly, hide daily
        if (minAggType === AggregationType.WEEKLY && index === 0) {
          return false
        }
        // if max agg type is daily, hide weekly and monthly
        if (maxAggType === AggregationType.DAILY && index > 0) {
          return false
        }
        // if max agg type is weekly, hide monthly
        if (maxAggType === AggregationType.WEEKLY && index === 2) {
          return false
        }
        return true
      }),
    [minAggType, maxAggType, dataAggTypeOnly],
  )

  const handleChangePeriods = (
    dateRange: [Date, Date],
    compareDateRange: [Date, Date] | null,
  ) => {
    setPeriod(
      dayjs(dateRange[0]).format(DATE_FORMAT),
      dayjs(dateRange[1]).format(DATE_FORMAT),
    )

    if (compareDateRange && compareDateRange.length > 0) {
      setComparePeriod(
        dayjs(compareDateRange[0]).format(DATE_FORMAT),
        dayjs(compareDateRange[1]).format(DATE_FORMAT),
      )
    } else {
      setComparePeriod()
    }
  }

  const handleSetDataAggType = (aggType: AggregationType) => {
    setGranularity(aggType)
  }

  const handleSetWidget = (widgetId: string) => {
    setParams({
      [URL_WIDGET_PARAM_KEY]: widgetId.toLowerCase(),
    })
  }

  const showComparisonInPicker = useMemo(() => {
    const visualizationProps = VISUALIZATIONS_MAP[activeWidget.widget]?.props
    return Boolean(visualizationProps?.comparisonEnabled)
  }, [activeWidget])

  return (
    <>
      <div className="flex justify-between">
        <div className="text-start">
          <H2>
            <Breadcrumbs
              items={[
                {
                  slug: parent.slug,
                  name: parent.title,
                },
                {
                  slug,
                  name: title,
                },
              ]}
            />
          </H2>
        </div>
        <div className="flex justify-end align-center gap-2">
          <div className="flex align-center">
            <LastUpdatedChip
              lastDate={lastDate}
              isLoading={isLastUpdateDateLoading}
            >
              Max date available in Page:
            </LastUpdatedChip>
          </div>
          <div className="join max-h-14">
            {aggregationGroups.length > 1 && !chartProps.dataAggTypeOnly
              ? aggregationGroups.map(({ type, long }) => (
                  <Button
                    variant={{ variant: "solid", color: "blue" }}
                    key={type}
                    title={long}
                    className={`join-item border text-3.5 px-4 py-3 rounded-md ${type === granularity ? "bg-basic-blue" : "bg-basic-dark-blue"}`}
                    onClick={() => handleSetDataAggType(type)}
                  >
                    {long}
                  </Button>
                ))
              : null}
          </div>
          <div>
            <PeriodPicker
              dateRange={dateRange}
              compareRange={compareRange}
              opacity={isPeriodPickerVisible ? 1 : 0.5}
              onChange={handleChangePeriods}
              comparisonEnabled={showComparisonInPicker}
            />
          </div>
        </div>
      </div>
      <div className="ui-rtk-overview-dashboard border-b border-basic-blue join">
        {Object.values(visibleCharts).map(tab => (
          <div
            className={cn(
              "flex font-bold justify-between pt-3 pb-2.5 px-4 rounded-t-md border-b hover:bg-basic-dark-blue hover:border-b-basic-blue border-b-transparent cursor-pointer",
              {
                "border-b-[4px] border-basic-blue bg-basic-blue/30 font-bold":
                  tab.id === activeWidget?.id,
              },
            )}
            role="button"
            key={tab.id}
            onClick={() => handleSetWidget(tab.id)}
          >
            {tab.label}
            {tab.id === activeWidget?.id &&
              VISUALIZATIONS_MAP[activeWidget.widget].description && (
                <div className="ms-1">
                  <RichTooltip
                    content={
                      <Card
                        rounded
                        className="min-w-128 max-w-180 whitespace-normal text-start leading-5"
                      >
                        {VISUALIZATIONS_MAP[activeWidget.widget].description}
                      </Card>
                    }
                  >
                    <InfoSvg size={16} fill="white" />
                  </RichTooltip>
                </div>
              )}
          </div>
        ))}
      </div>
      <Card rounded className="px-5 mt-3 bg-basic-dark-blue">
        <Chart
          chartId={activeWidget.widget}
          className="h-[calc(100vh-300px)]"
          widgetType={VISUALIZATION_TYPE.ComposedChart}
          component={ComposedSimpleChart}
          dateRange={dateRange}
          compareRange={compareRange ?? undefined}
          granularity={granularity}
          sources={sources}
          requiredSources={activeWidget?.requiredSources}
          customSources={activeWidget?.customSources}
          hideAggSelector={true}
          setDataAggType={handleSetDataAggType}
          showPostsLink={activeWidget.showPostsLink ?? false}
          showAdsLink={activeWidget.showAdsLink ?? false}
          sortBy={sortBy}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          {...chartProps}
        />
      </Card>
    </>
  )
}
