import React, { useCallback, useMemo } from "react"
import { CellContext } from "@tanstack/react-table"

import {
  formatValue,
  safeFormat,
} from "~/ui-rtk/components/ui/charts/TableVisualization/utils"
import type {
  TMetric,
  TSources,
  TableVisualizationColumn,
} from "~/ui-rtk/constants/table-visualization-types"
import { URLValidator } from "~/ui-rtk/utils/validators"
import { renderImage, renderSourceIcon } from "./utils"
import { ImageSvg } from "~/ui-rtk/components/ui/svg/essentials"
import ValueWithDelta from "~/ui-rtk/components/ui/common/ValueWithDelta/ValueWithDelta"
import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { CaptionPreviewNumber } from "~/ui-rtk/components/ui/common/CaptionPreviewNumber"
import {
  ConvertNumberTypeArr,
  type ConvertNumberType,
} from "~/ui-rtk/utils/number-utils"

type TCellProps = {
  sources?: TSources
  column: TableVisualizationColumn
  cellContext: CellContext<TMetric, any>
  compareWith?: {
    key: string | undefined
    data: TMetric[] | null
  }
}

export const Cell: React.FC<TCellProps> = ({
  sources,
  column,
  cellContext,
  compareWith,
}) => {
  const cellValue = cellContext.getValue()
  const compareRow = useMemo(() => {
    const compareUniqueKey = compareWith?.key
    if (!compareUniqueKey) {
      return null
    }

    const cellRowValue = cellContext.row.getValue(compareUniqueKey)
    return compareWith.data?.find(
      compareRow => compareRow[compareUniqueKey] === cellRowValue,
    )
  }, [compareWith?.key, compareWith?.data, cellContext.row])

  const renderCell = useCallback(() => {
    if (typeof cellValue === "object" && cellValue !== null) {
      return cellValue
    }

    if (column.type !== "image") {
      if (ConvertNumberTypeArr.includes(column.type as ConvertNumberType)) {
        return (
          <CaptionPreviewNumber
            value={cellValue}
            columnType={column.type as ConvertNumberType}
          />
        )
      }
      return safeFormat(cellValue, column.type, sources)
    }

    if (sources?.has(cellValue?.toUpperCase())) {
      const sourceKey = cellValue.toUpperCase()
      const source = sources.get(sourceKey)
      if (source) {
        return renderSourceIcon(source)
      }
      return "--"
    }

    if (cellValue && URLValidator.validate(cellValue)) {
      return renderImage(cellValue, "Post Preview")
    }

    return <ImageSvg size={42} fill="basic.grey.inactive" />
  }, [cellValue, column, sources])

  const cellClasses = cn([column.classes?.cell, "text-xl text-ellipsis"])

  return (
    <div className={cellClasses}>
      {renderCell()}
      {compareWith &&
      ["currency", "number", "percent", "coef"].includes(`${column.type}`) ? (
        <span className="text-gray-light">
          {compareRow ? (
            <div className="flex gap-2 text-sm">
              <ValueWithDelta
                actualValue={cellValue}
                comparedValue={compareRow[column.key]}
                hideActualValue
              />
              <span>
                prev:{" "}
                {formatValue(compareRow[column.key], column.type, undefined)}
              </span>
            </div>
          ) : (
            " (N/A)"
          )}
        </span>
      ) : null}
    </div>
  )
}
