import Dashboard from "~/ui-rtk/components/features/dashboards/Dashboard"
import useConnect from "./connect"
import { TDashboardItemsSet } from "~/ui-rtk/constants/dashboard"
import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"

export default function BrandsValueOverview() {
  const { content, visualizations, isLoading } = useConnect()

  return (
    <WithLoader isLoading={isLoading}>
      <Dashboard
        slug={content.slug}
        visualizations={visualizations}
        items={content.content.items as TDashboardItemsSet}
        title={content.content.title}
        parent={content.parent}
        lastDateChipProps={content.content.lastDateChipProps}
        periodPicker={content.content.periodPicker}
      />
    </WithLoader>
  )
}
