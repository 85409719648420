import { type PaginationState } from "@tanstack/react-table"
import { URL_PAGINATION_STATE } from "../constants/url-params"
import useUrlParamsState from "./urlParamsState"
import { Dispatch, SetStateAction } from "react"
import { isFunctionAction } from "../utils/function-utils"

export default function useUrlPagination(
  chartId = "",
  defaultItemsPerPage = 10,
) {
  const urlKey = `${chartId}_${URL_PAGINATION_STATE}`
  const { state, setState } = useUrlParamsState<PaginationState>(urlKey, {
    pageIndex: 0,
    pageSize: defaultItemsPerPage,
  })

  const pagination = {
    pageIndex: +state.pageIndex,
    pageSize: +state.pageSize,
  }

  const setPagination: Dispatch<SetStateAction<PaginationState>> = (
    action: SetStateAction<PaginationState>,
  ) => {
    const newState = isFunctionAction(action) ? action(pagination) : action

    setState(newState)
  }

  return {
    urlKey,
    pagination,
    setPagination,
  }
}
