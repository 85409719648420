import React, { useState } from "react"
import ReactCardFlip from "react-card-flip"
import useMobileView from "~/ui-rtk/hooks/mobile-view"

type FlipCardProps = {
  children: [React.ReactNode, React.ReactNode]
}

const FlipCard: React.FC<FlipCardProps> = ({ children }) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const isMobile = useMobileView()

  const handleMouseEnter = () => {
    setIsFlipped(true)
  }

  const handleMouseLeave = () => {
    setIsFlipped(false)
  }

  const handleToggleFlip = () => {
    setIsFlipped(prev => !prev)
  }

  return (
    <div
      className="w-full"
      onMouseEnter={!isMobile ? handleMouseEnter : undefined}
      onMouseOver={!isMobile ? handleMouseEnter : undefined}
      onMouseLeave={!isMobile ? handleMouseLeave : undefined}
      onClick={isMobile ? handleToggleFlip : undefined}
    >
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        {children}
      </ReactCardFlip>
    </div>
  )
}

export default FlipCard
