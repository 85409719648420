import { useCallback, useState } from "react"
import { toast } from "react-toastify"

import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import { useAuthControllerCurrentUserQuery } from "~/ui-rtk/api/authApi"
import { useCompanyControllerUpdateCompanyMutation } from "~/ui-rtk/api/companyApi"
import { CompanyDto, CompanyMetadataDto } from "~/ui-rtk/api/types"

export default function useCompanyUpdater() {
  const currentCompany = useAppSelector(selectCurrentCompany)
  const { refetch, isUninitialized } = useAuthControllerCurrentUserQuery()
  const [inProgress, setInProgress] = useState(false)
  const [updateCompany] = useCompanyControllerUpdateCompanyMutation()

  const updateCompanyHandler = useCallback(async (dto: Partial<CompanyDto>) => {
    if (!currentCompany?.id) {
      return false
    }
    setInProgress(true)
    let company: CompanyDto | null = null
    try {
      company = await updateCompany({
        id: currentCompany.id,
        updateCompanyDto: dto,
      }).unwrap()
      if (!isUninitialized) {
        await refetch()
      }
    } catch (error) {
      console.error(">>>useCompanyUpdater error", error)
      toast.error("Unknown issue occured", {
        toastId: "update-company-handler",
      })
      return false
    }
    setInProgress(false)
    return company
  }, [])

  return {
    updateCompany: updateCompanyHandler,
    inProgress,
  }
}

export function useCompanyMetadataUpdater() {
  const currentCompany = useAppSelector(selectCurrentCompany)
  const { updateCompany, inProgress } = useCompanyUpdater()

  const handleUpdateCompanyMetadat = useCallback(
    (metadata: CompanyMetadataDto) => {
      const dto = {
        metadata: {
          ...(currentCompany?.metadata ?? {}),
          ...metadata,
        },
      }
      return updateCompany(dto)
    },
    [currentCompany, updateCompany],
  )

  return {
    updateCompanyMetadata: handleUpdateCompanyMetadat,
    inProgress,
  }
}
