import WithLoader from "~/ui-rtk/components/layout/WithLoader/WithLoader"
import { BrandMediaSocialPostsDashboard } from "~/ui-rtk/components/features/dashboards"

import useConnect from "./connect"

export default function PageBrandMediaOrganicSocialPosts() {
  const { content, isLoading } = useConnect()

  return (
    <WithLoader isLoading={isLoading}>
      <BrandMediaSocialPostsDashboard
        slug={content.slug}
        items={content.content.items}
        title={content.title}
        parent={content.parent}
        lastDateChipProps={content.content.lastDateChipProps}
      />
    </WithLoader>
  )
}
