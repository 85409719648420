import { useCallback, useMemo } from "react"

import {
  type Query as TCubeQuery,
  Filter,
  ResultSet,
  TimeDimensionGranularity,
} from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP, getCubeQueryBase } from "../../constants"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import useCampaignAdSetAdDetails, {
  getWeekYearMonthQuarterYearFilters,
  prepareAllItemsDetails,
  prepareValueOverTimeData,
  useAllCampaignsAdSetsAdsDetails,
} from "~/ui-rtk/hooks/campaign-adset-ad-details"
import { getPivotData } from "~/ui-rtk/utils/cube"

import useDate from "~/ui-rtk/hooks/date"

import {
  GranularityMapping,
  MetricsMapping,
} from "~/ui-rtk/constants/metrics-mapping"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"
import { getBaseCubeFilters } from "../../../dashboards/BrandMediaOptimizationDashboard/utils"

const dayjs = useDate()

const defaultPrefixKey = "company_media_metrics_weekly_adsets"

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

const ALL_CAMPAIGNS = "ALL"

export const useAllAdsetsCubeQuery = (
  date: string,
  granularity: BrandMediaAggregationType,
) => {
  const { postProcess, getCubePrefix } =
    MetricsMapping.brand_media_optimization_adsets_table
  const prefixKey = getCubePrefix
    ? getCubePrefix({
        granularity: GranularityMapping[granularity],
      })
    : defaultPrefixKey
  const currentCompany = useAppSelector(selectCurrentCompany)
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const campaignId = ALL_CAMPAIGNS
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}.${date}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdSetsFilters = useMemo(
    () => getWeekYearMonthQuarterYearFilters(date, prefixKey, granularity),
    [prefixKey, date, granularity],
  )

  const allAdSetsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [...campaignQuery.dimensions, campaignIdKey],
        filters: allAdSetsFilters,
      }
    }
    return requests[queryAllCampaignKey]
  }, [
    queryAllCampaignKey,
    allAdSetsFilters,
    campaignQuery,
    purchaseKey,
    campaignIdKey,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allAdSetsSet, isLoading } =
    useAllCampaignsAdSetsAdsDetails(allAdSetsQuery)

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdSetsSet) {
    const pivotData = getPivotData(
      allAdSetsSet as ResultSet<any>,
      allAdSetsQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData
    results[cacheKey].allAdSetsDetails =
      prepareAllItemsDetails(processedPivotData)
  }

  const { allAdSetsDetails } = results[cacheKey]

  return { allAdSetsDetails, isLoading }
}

export const useAdSetQubeQuery = (
  date: string,
  adSetId: string,
  campaignId = ALL_CAMPAIGNS,
  granularity: BrandMediaAggregationType,
) => {
  const { postProcess, getCubePrefix } =
    MetricsMapping.brand_media_optimization_adsets_table
  const prefixKey = getCubePrefix
    ? getCubePrefix({
        granularity: GranularityMapping[granularity],
      })
    : defaultPrefixKey
  const currentCompany = useAppSelector(selectCurrentCompany)
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}.${date}`
  const queryAllAdSetsKey = `${cacheKey}.all`
  const queryValueOverTimeKey = `${cacheKey}.${adSetId}.value`

  const yearKey = `${prefixKey}.year`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const adSetIdKey = `${prefixKey}.adset_id`
  const purchaseKey = `${prefixKey}.purchases`

  let customKey: string
  switch (granularity) {
    case BrandMediaAggregationType.WEEKLY: {
      customKey = `${prefixKey}.week`
      break
    }
    case BrandMediaAggregationType.MONTHLY: {
      customKey = `${prefixKey}.month`
      break
    }
    case BrandMediaAggregationType.QUARTERLY: {
      customKey = `${prefixKey}.quarter`
      break
    }
    case BrandMediaAggregationType.YEARLY: {
      customKey = ""
      break
    }
    default: {
      throw new Error("Wrong granularity")
    }
  }

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdSetsFilters = useMemo(() => {
    const filters = getBaseCubeFilters(
      prefixKey,
      dayjs(date).toDate(),
      granularity,
    )
    if (campaignId !== ALL_CAMPAIGNS) {
      filters.and.push({
        dimension: campaignIdKey,
        operator: "equals",
        values: [campaignId],
      })
    }
    return filters
  }, [prefixKey, date, granularity, campaignId, campaignIdKey])

  const allAdSetsQuery = useMemo(() => {
    if (!requests[queryAllAdSetsKey]) {
      requests[queryAllAdSetsKey] = {
        ...campaignQuery,
        dimensions: [...campaignQuery.dimensions, adSetIdKey],
        filters: [allAdSetsFilters as Filter],
      }
    }
    return requests[queryAllAdSetsKey]
  }, [queryAllAdSetsKey, campaignQuery, adSetIdKey, allAdSetsFilters])

  const valueOverTimeFilters = useMemo(() => {
    const filter: Filter = {
      and: getWeekYearMonthQuarterYearFilters(date, prefixKey, granularity),
    }

    if (campaignId !== ALL_CAMPAIGNS) {
      filter.and.push({
        dimension: campaignIdKey,
        operator: "equals",
        values: [campaignId],
      })
    }

    return filter
  }, [date, campaignId, granularity, prefixKey, campaignIdKey])

  const valueOverTimeQuery = useMemo(() => {
    if (!requests[queryValueOverTimeKey]) {
      requests[queryValueOverTimeKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [
          ...campaignQuery.dimensions,
          customKey,
          yearKey,
          adSetIdKey,
        ].filter(key => !!key),
        filters: [valueOverTimeFilters as Filter],
      }
    }
    return requests[queryValueOverTimeKey]
  }, [
    queryValueOverTimeKey,
    valueOverTimeFilters,
    customKey,
    campaignQuery,
    campaignIdKey,
    yearKey,
    purchaseKey,
    adSetIdKey,
  ])

  const {
    [CUBE_QUERY_KEYS_MAP.ALL]: allAdSetsSet,
    [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeSet,
    isLoading,
  } = useCampaignAdSetAdDetails({
    [CUBE_QUERY_KEYS_MAP.ALL]: allAdSetsQuery,
    [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeQuery,
  })

  const isCurrent = useCallback(
    (metric: any) => metric.adset_id === adSetId,
    [adSetId],
  )

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdSetsSet) {
    const pivotData = getPivotData(
      allAdSetsSet as ResultSet<any>,
      allAdSetsQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData
    results[cacheKey].allAdSetsDetails =
      prepareAllItemsDetails(processedPivotData)
  }

  if (valueOverTimeSet) {
    const pivotData = getPivotData(
      valueOverTimeSet as ResultSet<any>,
      valueOverTimeQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData

    try {
      results[cacheKey].valueOverTimeDetails = prepareValueOverTimeData(
        processedPivotData,
        isCurrent,
        granularity,
      )
    } catch (e) {
      console.error(e)
    }
  }

  const { allAdSetsDetails, valueOverTimeDetails } = results[cacheKey]

  return { allAdSetsDetails, valueOverTimeDetails, isLoading }
}
