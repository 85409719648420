import numeral from "numeral"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  useMediaCategoryControllerGetMediaCategoryQuery,
  useMediaCategoryControllerPostMediaCategoriesMutation,
} from "~/ui-rtk/api/mediaCategoryApi.ts"

import useSources from "~/ui-rtk/hooks/sources"
import { calcDelta } from "~/ui-rtk/utils/chart-utils"

import { DetailsTitle } from "../components/DetailsHeader"
import { Card, Loader } from "~/ui-rtk/components/ui/common"
import { Image } from "~/ui-rtk/components/ui/common/Image"
import { H5 } from "~/ui-rtk/components/ui/typography"
import ValueOverTimeInfoBlock from "../components/ValueOverTimeInfoBlock"
import CampaignDetailsValueOverTimeChart from "../components/ValueOverTimeChart"

import { usePostQubeQuery } from "./hooks/cube-query"

import {
  POST_MEDIA_CATEGORIES_OPTIONS,
  TCampaignAdSetAdDetailsProps,
  TPostMediaOption,
} from "../constants"

import { selectIsEnabled } from "~/ui-rtk/app/selectors/demo.selector"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

import useDate from "~/ui-rtk/hooks/date"
import ListBox from "~/ui-rtk/components/ui/common/ListBox/ListBox"
const dayjs = useDate()

const prefixKey = "company_media_metrics_posts"

export default function PostDetails(props: TCampaignAdSetAdDetailsProps) {
  const { sources } = useSources()
  const { metric, widget } = props
  const postCaption = metric[`${prefixKey}.post_caption`]
  const postId = metric[`${prefixKey}.post_id`]
  const previewLink = metric[`${prefixKey}.post_preview`]
  const postLink = metric[`${prefixKey}.post_link`]
  const postDate = metric[`${prefixKey}.post_date`]
  const sourceName = metric[`${prefixKey}.media_channel`]?.toUpperCase()

  const reach = metric[`${prefixKey}.reach`]
  const follows = metric[`${prefixKey}.follows`]
  const shares = metric[`${prefixKey}.shares`]
  const saves = metric[`${prefixKey}.saves`]
  const comments = metric[`${prefixKey}.comments`]
  const likes = metric[`${prefixKey}.likes`]

  const brandValue = metric[`${prefixKey}.brand_value`]
  const brandValuePerReach = reach > 0 ? brandValue / reach : 0

  const source = sources.get(sourceName)

  const { allPostsDetails, valueOverTimeDetails } = usePostQubeQuery(
    postId,
    widget,
  )

  const averageBrandValue =
    (allPostsDetails?.total ?? 0) > 0
      ? allPostsDetails?.brandValue / allPostsDetails?.total
      : 0

  const delta = calcDelta(brandValue, averageBrandValue)

  const [captionExpanded, setExpanded] = useState(false)

  const [category, setCategory] = useState<string | null | undefined>(undefined)

  const demo = useAppSelector(selectIsEnabled)

  const [createMediaCategory] =
    useMediaCategoryControllerPostMediaCategoriesMutation()

  const {
    data: mediaCategory,
    isLoading: mediaCategoryLoading,
    isFetching: mediaCategoryFetching,
    refetch,
  } = useMediaCategoryControllerGetMediaCategoryQuery(
    {
      id: postId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    if (mediaCategory?.category) {
      setCategory(mediaCategory.category)
    } else {
      setCategory(null)
    }
  }, [mediaCategory])

  const categoryUpdate = async (value: TPostMediaOption) => {
    await createMediaCategory({
      body: {
        mediaId: postId,
        type: "ad",
        channel: metric[`${prefixKey}.media_channel`],
        category: value,
      },
    })
    setCategory(value)
    await refetch()
  }

  return (
    <div className="post-details w-145">
      <DetailsTitle
        id={postId}
        title={postCaption ?? ""}
        classes={{ container: "mb-4" }}
        demoMode={demo.isEnabled}
      />
      <div className="col-span-7">
        {mediaCategoryLoading || mediaCategoryFetching ? (
          <Loader />
        ) : (
          <ListBox
            options={POST_MEDIA_CATEGORIES_OPTIONS}
            onSelect={categoryUpdate}
            value={category}
            label="Category"
            placeholder="Please select a Category"
          />
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <Card
          rounded
          className="bg-background-dark border border-basic-grey-inactive p-5"
        >
          <Image
            classes={{
              image: demo.isEnabled ? "blur-md" : "",
              wrapper: demo.isEnabled ? "blur-md" : "",
            }}
            src={previewLink}
            alt={postCaption}
          />
          <div>
            <div className={cn(demo.isEnabled && "blur-sm")}>
              {!captionExpanded && `${postCaption?.substring(0, 60)}...`}
              {captionExpanded && postCaption}
            </div>
            <div className="grid grid-cols-2 justify-between">
              <p
                className="text-white font-bold py-2 px-4 rounded cursor-pointer"
                onClick={() => setExpanded(!captionExpanded)}
              >
                {!captionExpanded && "See More"}
                {captionExpanded && "Collapse"}
              </p>
              <p
                className="text-white font-bold py-2 px-4 rounded cursor-pointer"
                onClick={() => setExpanded(!captionExpanded)}
              >
                <Link to={postLink} target="_blank" className="text-blue">
                  See Post
                </Link>
              </p>
            </div>
            {}
          </div>
        </Card>
        <div>
          <H5 className="font-semibold">Brand Value</H5>
          <div className="rounded-xl border border-cyan bg-cyan/15 p-5 grid grid-cols-3 gap-2 text-sm text-cyan mt-2">
            <div>All Time:</div>
            <div className="col-span-2 grid grid-cols-2 gap-2">
              <div className="font-semibold">
                ${numeral(brandValuePerReach).format("0.00a")}
              </div>
              <div>/Reach</div>
              <div className="font-semibold">
                ${numeral(brandValue).format("0.00a")}
              </div>
              <div>Total</div>
              <div className="font-semibold">
                {delta && delta > 0 ? "+" : ""}
                {delta?.toFixed(0)}%
              </div>
              <div>Average Post</div>
            </div>
          </div>
        </div>
      </div>
      <Card
        rounded
        className="bg-background-dark border border-basic-grey-inactive mt-4 p-5"
      >
        <H5 className="font-semibold">Details</H5>
        <div className="grid grid-cols-2 mt-2">
          {/* <div>
           <div className="text-sm">Content Type</div>
           <div className="font-semibold">Single Image</div>
           </div> */}
          <div>
            <div className="text-sm">Active Dates</div>
            <div className="font-semibold">
              {dayjs(postDate).format("MM/DD/YY")} - Present
            </div>
          </div>
          <div>
            <div className="text-sm">Channel</div>
            <div className="capitalize font-semibold">{source?.name}</div>
          </div>
          {/* <div>
           <div className="text-sm">Placement</div>
           <div className="font-semibold">Feed</div>
           </div> */}
        </div>
      </Card>
      <Card
        rounded
        className="bg-background-dark border border-basic-grey-inactive mt-4 p-5"
      >
        <H5 className="font-semibold">Engagements</H5>
        <div className="grid grid-cols-6 mt-2">
          <div>
            <div className="text-sm">Reach</div>
            <div className="font-semibold">
              {numeral(reach).format("0.00a")}
            </div>
          </div>
          <div>
            <div className="text-sm">Follows</div>
            <div className="font-semibold">
              {numeral(follows).format("0.00a")}
            </div>
          </div>
          <div>
            <div className="text-sm">Shares</div>
            <div className="font-semibold">
              {numeral(shares).format("0.00a")}
            </div>
          </div>
          <div>
            <div className="text-sm">Saves</div>
            <div className="font-semibold">
              {numeral(saves).format("0.00a")}
            </div>
          </div>
          <div>
            <div className="text-sm">Comments</div>
            <div className="font-semibold">
              {numeral(comments).format("0.00a")}
            </div>
          </div>
          <div>
            <div className="text-sm">Likes</div>
            <div className="font-semibold">
              {numeral(likes).format("0.00a")}
            </div>
          </div>
        </div>
      </Card>
      {valueOverTimeDetails && (
        <Card
          rounded
          className="bg-background-dark border border-basic-grey-inactive mt-4 p-5"
        >
          <>
            <div className="mt-3 grid grid-cols-3 gap-1">
              {[
                valueOverTimeDetails.info30days,
                valueOverTimeDetails.info60days,
                valueOverTimeDetails.info90days,
              ].map(info => (
                <ValueOverTimeInfoBlock
                  key={info.label}
                  info={info}
                  label={info.label}
                  dataLabel="Brand Value"
                  dataKey="brandValue"
                />
              ))}
            </div>
            <div className="mt-3">
              <CampaignDetailsValueOverTimeChart
                metrics={valueOverTimeDetails.chartData}
                dataKey="brandValue"
                label="post"
              />
            </div>
          </>
        </Card>
      )}
    </div>
  )
}
