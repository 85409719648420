import { PropsWithChildren } from "react"
import { CloseSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { ThemeColors } from "~/ui-rtk/themes/types"
import { cn } from "~/ui-rtk/utils/tailwind-utils"

export type TChipClasses = Partial<{
  container: string
  icon: string
  iconFill: ThemeColors
}>

type TChipProps = {
  className?: string
  color?: string
  classes?: TChipClasses
  onRemove?: () => void
}

export default function Chip({
  className,
  children,
  onRemove,
  classes,
}: PropsWithChildren<TChipProps>) {
  const cx = [
    "py-1.5 px-3 rounded-lg whitespace-nowrap text-nowrap flex items-center gap-1",
    className,
  ]
    .join(" ")
    .trim()
  return (
    <span className={cn(cx, classes?.container)}>
      {children}
      {onRemove && (
        <CloseSvg
          size={16}
          className={cn("cursor-pointer", classes?.icon)}
          fill={classes?.iconFill}
          onClick={onRemove}
        />
      )}
    </span>
  )
}
