import { ContentBox } from "~/ui-rtk/components/ui/common"
import { Button } from "~/ui-rtk/components/ui/controls"

const SkippedDataConnection = ({
  allSkipped = false,
  actionInProgress = false,
  onContinue,
}: {
  allSkipped?: boolean
  actionInProgress?: boolean
  onContinue: () => void
}) => {
  const button = (
    <Button
      className="w-full"
      isLoading={actionInProgress}
      onClick={onContinue}
    >
      Continue
    </Button>
  )
  return allSkipped ? (
    <ContentBox
      title="Looks like you skipped all the connectors!"
      subTitle="Click here to head straight to our connectors overview page to finish your onboarding!"
      classes={{ content: "max-w-200" }}
    >
      {button}
    </ContentBox>
  ) : (
    <ContentBox
      title="Thanks for completing your onboarding flow"
      subTitle="Please check back later to ensure your sent connections have been successfully completed!"
      classes={{ content: "max-w-200" }}
    >
      {button}
    </ContentBox>
  )
}

export default SkippedDataConnection
