import {
  AccountInfoDto,
  ConnectionStatus,
  ConnectorDto,
  ReleaseCycle,
} from "~/ui-rtk/api/types"
import type { ConnectorStatus } from "~/ui-rtk/shared/types/connectors"
import { CONNECTORS_IDS } from "../constants/sources"

export const isAccountInfo = (accountInfo?: AccountInfoDto): boolean =>
  Boolean(accountInfo?.accountName) || Boolean(accountInfo?.metric)

const excludedConnectorIds = [
  CONNECTORS_IDS.GOOGLE_KEYWORD_SEARCH,
  CONNECTORS_IDS.GOOGLE_ANALYTICS,
]

export const isConnectorExcluded = (connectorId: string) =>
  excludedConnectorIds.includes(connectorId)

export const isVisibleToCompany = (
  connector: ConnectorDto,
  companyId: string,
) => {
  if (connector.releaseCycle !== ReleaseCycle.BETA) {
    return true
  }

  return Boolean(connector.metadata?.visibleToCompanyIds?.includes(companyId))
}

export const mapConnectorStatus = (
  status?: ConnectionStatus,
): ConnectorStatus => {
  if (!status || status === ConnectionStatus.NOT_CONNECTED) {
    return "NOT_CONNECTED"
  }
  if (status === ConnectionStatus.PENDING_RECONNECT) {
    return "RECONNECT"
  }
  if (
    status === ConnectionStatus.CONNECTING ||
    status === ConnectionStatus.PENDING_SCHEMA_UPDATE
  ) {
    return "PENDING"
  }
  if (status === ConnectionStatus.CONNECTED) {
    return "CONNECTED"
  }
  return "FAILED"
}
