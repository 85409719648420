import React from "react"
import { ColumnOrderState, HeaderGroup } from "@tanstack/react-table"
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable"

import { SummaryCell } from "./SummaryCell"

import type { TMetric } from "~/ui-rtk/constants/table-visualization-types"

type TTableSummaryProps = {
  headerGroups: HeaderGroup<TMetric>[]
  metric: TMetric
  columnOrder: ColumnOrderState
  compareMetric?: TMetric
  totalLabelColumn?: string
  isLoading?: boolean
}

export const TableSummary: React.FC<TTableSummaryProps> = ({
  headerGroups,
  metric,
  columnOrder,
  compareMetric,
  totalLabelColumn,
  isLoading = false,
}) => (
  <tfoot className="w-full border-b border-b-basic-blue bg-dark-blue sticky bottom-0 z-20">
    {headerGroups.map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => (
          <SortableContext
            key={header.id}
            items={columnOrder}
            strategy={horizontalListSortingStrategy}
          >
            <SummaryCell
              key={header.id}
              header={header}
              totalLabelColumn={totalLabelColumn}
              value={metric[header.id] ?? "-"}
              isLoading={isLoading}
              compareValue={
                compareMetric ? (compareMetric[header.id] ?? "- -") : null
              }
            />
          </SortableContext>
        ))}
      </tr>
    ))}
  </tfoot>
)
