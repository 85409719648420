import { Dispatch, SetStateAction, useMemo } from "react"
import { URL_COLUMN_PINNING_PARAM_KEY } from "~/ui-rtk/constants/url-params"
import { ColumnPinningState } from "@tanstack/react-table"
import { isFunctionAction } from "~/ui-rtk/utils/function-utils"
import { useUrlParamsStateArray } from "./urlParamsState"

export default function useUrlPinnedColumns(chartId = "") {
  const urlKey = `${chartId}_${URL_COLUMN_PINNING_PARAM_KEY}`
  const { state: rawPinnedColumns, setState: setPinnedColumnsUpdater } =
    useUrlParamsStateArray<Array<string>>(urlKey)
  const pinnedColumns = useMemo(
    () => ({
      left: rawPinnedColumns ?? [],
      right: [],
    }),
    [rawPinnedColumns],
  )

  const setPinnedColumns: Dispatch<SetStateAction<ColumnPinningState>> = (
    action: SetStateAction<ColumnPinningState>,
  ) => {
    const newState = isFunctionAction(action)
      ? action(pinnedColumns as ColumnPinningState)
      : action

    setPinnedColumnsUpdater(newState.left ?? [])
  }

  return { urlKey, pinnedColumns, setPinnedColumns }
}
