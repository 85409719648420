import { useMemo } from "react"
import { TColumnFiltersState } from "~/ui-rtk/constants/table-visualization-types"
import { useUrlParamsStateArray } from "./urlParamsState"
import { URL_FILTER_PARAM_KEY } from "~/ui-rtk/constants/url-params"

export default function useUrlColumnFilters(chartId = "") {
  const urlKey = `${chartId}_${URL_FILTER_PARAM_KEY}`
  const { state: rawColumnFilters, setState: setColumnFilters } =
    useUrlParamsStateArray<TColumnFiltersState | undefined>(urlKey)

  const columnFilters = useMemo(
    () => rawColumnFilters as TColumnFiltersState,
    [rawColumnFilters],
  )

  return { urlKey, columnFilters, setColumnFilters }
}
