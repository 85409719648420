import { Route, Routes, Navigate } from "react-router-dom"

import SignIn from "./SignIn"
import Docs from "./Docs"
import ForgotPassword from "./ForgotPassword/index"
import ResetPassword from "./ResetPassword"
import InvitePage from "./InvitePage"
import ConfirmEmail from "./ConfirmEmail/index"
import { PricingDetails } from "./PricingDetails"
import { CreateAccount } from "./Onboarding/BrandInfo"

import { guestRoutes, sharedRoutes } from "~/ui-rtk/constants/routes"
import OAuthConnection from "./OAuthConnection"
import AuthenticateConnectorGuest from "./AuthenticateConnectorGuest"
import { AdhocFivetranConnection } from "./AdhocFivetranConnection"

export default function Guest() {
  return (
    <Routes>
      <Route path={guestRoutes.SIGN_IN} element={<SignIn />} />
      <Route path={guestRoutes.CREATE_ACCOUNT} element={<CreateAccount />} />

      <Route
        path={guestRoutes.CREATE_CONNECTION}
        element={<OAuthConnection />}
      />
      <Route
        path={guestRoutes.AUTHENTICATE_CONNECTOR}
        element={<AuthenticateConnectorGuest />}
      />
      <Route
        path={sharedRoutes.FIVETRAN_CONNECTION}
        element={<AdhocFivetranConnection />}
      />

      <Route path={guestRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={guestRoutes.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={guestRoutes.CONFIRM_EMAIL} element={<ConfirmEmail />} />
      <Route path={guestRoutes.INVITE} element={<InvitePage />} />
      <Route path={guestRoutes.PRICING_DETAILS} element={<PricingDetails />} />
      <Route path={guestRoutes.DOCS} element={<Docs />} />
      <Route path="*" element={<Navigate to={guestRoutes.SIGN_IN} replace />} />
    </Routes>
  )
}
