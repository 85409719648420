import { startCase, camelCase } from "lodash"
import { useMemo } from "react"

import { LOADING_DATA } from "../constants"
import type {
  TMetric,
  TableVisualizationColumn,
} from "~/ui-rtk/constants/table-visualization-types"

export const useColumns = (
  columnConfig: TableVisualizationColumn[] | undefined,
  noData: boolean,
  data: TMetric[],
) =>
  useMemo<TableVisualizationColumn[]>(
    () =>
      columnConfig ||
      (noData ? Object.keys(LOADING_DATA[0]) : Object.keys(data[0])).map(
        col => ({
          key: col,
          header: startCase(camelCase(col)),
          type: "auto",
          id: col,
        }),
      ),
    [columnConfig, noData, data],
  )
