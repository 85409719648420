import { type ConnectorStatus } from "~/ui-rtk/shared/types/connectors"
import { type ConnectorMetadata } from "~/ui-rtk/api/types"

export type ConnectionLink = {
  recipientEmail: string
  createdAt: Date
  expiresAt: Date
}

export type Connector = {
  id: string
  name: string
  icon: string
  isSkipped: boolean
  connectionLink?: ConnectionLink
  status: ConnectorStatus
  metadata: ConnectorMetadata
}

export const SOURCE_IDS = {
  AMAZON_ADS: "AMAZON_ADS",
  AMAZON: "AMAZON",
  AMAZON_VENDOR: "AMAZON_VENDOR",
  ATTENTIVE: "ATTENTIVE",
  CRISP: "CRISP",
  FACEBOOK_ADS: "FACEBOOK_ADS",
  INSTAGRAM_ADS: "INSTAGRAM_ADS",
  FACEBOOK: "FACEBOOK",
  GOOGLE_ADS: "GOOGLE_ADS",
  GOOGLE_ANALYTICS: "GOOGLE_ANALYTICS",
  GOOGLE_KEYWORDS: "GOOGLE_KEYWORDS",
  GOOGLE_SEARCH: "GOOGLE_SEARCH",
  INSTAGRAM: "INSTAGRAM",
  KLAVIYO: "KLAVIYO",
  META: "META",
  SEPHORA: "SEPHORA",
  SHOPIFY: "SHOPIFY",
  SPS: "SPS",
  TARGET: "TARGET",
  TIKTOK: "TIKTOK",
  TIKTOK_ADS: "TIKTOK_ADS",
  YOTPO: "YOTPO",
  YOUTUBE: "YOUTUBE",
}

export const SOURCES_MAP = {
  [SOURCE_IDS.AMAZON_ADS]: {
    id: SOURCE_IDS.AMAZON_ADS,
    icon: "/assets/img/sources/amazon_ads.svg",
    name: "Amazon Ads",
  },
  [SOURCE_IDS.AMAZON]: {
    id: SOURCE_IDS.AMAZON,
    icon: "/assets/img/sources/amazon_selling_partner.svg",
    name: "Amazon",
  },
  [SOURCE_IDS.AMAZON_VENDOR]: {
    id: SOURCE_IDS.AMAZON_VENDOR,
    icon: "/assets/img/sources/amazon_selling_partner.svg",
    name: "Amazon Selling Partner - Vendor",
  },
  [SOURCE_IDS.ATTENTIVE]: {
    id: SOURCE_IDS.ATTENTIVE,
    icon: "/assets/img/sources/attentive.jpg",
    name: "Attentive",
  },
  [SOURCE_IDS.CRISP]: {
    id: SOURCE_IDS.CRISP,
    icon: "/assets/img/sources/crisp.png",
    name: "Crisp",
  },
  [SOURCE_IDS.FACEBOOK_ADS]: {
    id: SOURCE_IDS.FACEBOOK_ADS,
    icon: "/assets/img/sources/facebook_ads.png",
    name: "Facebook Ads",
  },
  [SOURCE_IDS.INSTAGRAM_ADS]: {
    id: SOURCE_IDS.INSTAGRAM_ADS,
    icon: "/assets/img/sources/instagram.png",
    name: "Instagram Ads",
  },
  [SOURCE_IDS.FACEBOOK]: {
    id: SOURCE_IDS.FACEBOOK,
    icon: "/assets/img/sources/facebook_pages.png",
    name: "Facebook",
  },
  [SOURCE_IDS.GOOGLE_ADS]: {
    id: SOURCE_IDS.GOOGLE_ADS,
    icon: "/assets/img/sources/google_ads.png",
    name: "Google Ads",
  },
  [SOURCE_IDS.GOOGLE_ANALYTICS]: {
    id: SOURCE_IDS.GOOGLE_ANALYTICS,
    icon: "/assets/img/sources/ga.png",
    name: "Google Analytics",
  },
  [SOURCE_IDS.GOOGLE_KEYWORDS]: {
    id: SOURCE_IDS.GOOGLE_KEYWORDS,
    icon: "/assets/img/sources/google_keyword_search.png",
    name: "Google Keywords",
  },
  [SOURCE_IDS.GOOGLE_SEARCH]: {
    id: SOURCE_IDS.GOOGLE_SEARCH,
    icon: "/assets/img/sources/google_search.png",
    name: "Google Search",
  },
  [SOURCE_IDS.INSTAGRAM]: {
    id: SOURCE_IDS.INSTAGRAM,
    icon: "/assets/img/sources/instagram.png",
    name: "Instagram",
  },
  [SOURCE_IDS.KLAVIYO]: {
    id: SOURCE_IDS.KLAVIYO,
    icon: "/assets/img/sources/klavio.png",
    name: "Klaviyo",
  },
  [SOURCE_IDS.META]: {
    id: SOURCE_IDS.META,
    icon: "/assets/img/sources/meta.svg",
    name: "Meta",
  },
  [SOURCE_IDS.SEPHORA]: {
    id: SOURCE_IDS.SEPHORA,
    icon: "/assets/img/sources/sephora.png",
    name: "Sephora",
  },
  [SOURCE_IDS.SHOPIFY]: {
    id: SOURCE_IDS.SHOPIFY,
    icon: "/assets/img/sources/shopify.png",
    name: "Shopify",
  },
  [SOURCE_IDS.SPS]: {
    id: SOURCE_IDS.SPS,
    icon: "/assets/img/sources/sps.svg",
    name: "SPS",
  },
  [SOURCE_IDS.TARGET]: {
    id: SOURCE_IDS.TARGET,
    icon: "/assets/img/sources/target.svg",
    name: "Target",
  },
  [SOURCE_IDS.TIKTOK]: {
    id: SOURCE_IDS.TIKTOK,
    icon: "/assets/img/sources/tiktok.svg",
    name: "TikTok",
  },
  [SOURCE_IDS.TIKTOK_ADS]: {
    id: SOURCE_IDS.TIKTOK_ADS,
    icon: "/assets/img/sources/tiktok.svg",
    name: "TikTok Ads",
  },
  [SOURCE_IDS.YOTPO]: {
    id: SOURCE_IDS.YOTPO,
    icon: "/assets/img/sources/yotpo.png",
    name: "Yotpo",
  },
  [SOURCE_IDS.YOUTUBE]: {
    id: SOURCE_IDS.YOUTUBE,
    icon: "/assets/img/sources/youtube.svg",
    name: "YouTube",
  },
}

export const CONNECTORS_IDS = {
  AMAZON_ADS: "amazon_ads",
  AMAZON_SELLING_PARTNER: "amazon_selling_partner",
  AMAZON_SELLING_PARTNER_VENDOR: "amazon_selling_partner_vendor",
  ATTENTIVE: "attentive",
  CRISP: "crisp",
  FACEBOOK_ADS: "facebook_ads",
  FACEBOOK_PAGES: "facebook_pages",
  GOOGLE_ADS: "google_ads",
  GOOGLE_ANALYTICS: "google_analytics",
  GOOGLE_ANALYTICS_4: "google_analytics_4",
  GOOGLE_KEYWORD_SEARCH: "google_keyword_search",
  GOOGLE_SEARCH_CONSOLE: "google_search_console",
  INSTAGRAM_BUSINESS: "instagram_business",
  INSTAGRAM_INSIGHTS: "instagram_insights",
  KLAVIYO: "klaviyo",
  SEPHORA: "sephora",
  SHOPIFY: "shopify",
  SPS: "sps",
  TARGET: "target",
  TIKTOK: "tiktok",
  TIKTOK_ADS: "tiktok_ads",
  YOTPO: "yotpo",
  YOUTUBE_ANALYTICS: "youtube_analytics",
}

export type TConnectorIdKey = keyof typeof CONNECTORS_IDS
export type TConnectorId = (typeof CONNECTORS_IDS)[TConnectorIdKey]

export const CONNECTORS_TO_SOURCES_MAP = {
  [CONNECTORS_IDS.AMAZON_ADS]: [SOURCE_IDS.AMAZON_ADS],
  [CONNECTORS_IDS.AMAZON_SELLING_PARTNER]: [SOURCE_IDS.AMAZON],
  [CONNECTORS_IDS.AMAZON_SELLING_PARTNER_VENDOR]: [SOURCE_IDS.AMAZON_VENDOR],
  [CONNECTORS_IDS.ATTENTIVE]: [SOURCE_IDS.ATTENTIVE],
  [CONNECTORS_IDS.CRISP]: [SOURCE_IDS.CRISP],
  [CONNECTORS_IDS.FACEBOOK_ADS]: [
    SOURCE_IDS.FACEBOOK_ADS,
    SOURCE_IDS.INSTAGRAM_ADS,
    SOURCE_IDS.META,
  ],
  [CONNECTORS_IDS.FACEBOOK_PAGES]: [SOURCE_IDS.FACEBOOK],
  [CONNECTORS_IDS.GOOGLE_ADS]: [SOURCE_IDS.GOOGLE_ADS],
  [CONNECTORS_IDS.GOOGLE_ANALYTICS]: [SOURCE_IDS.GOOGLE_ANALYTICS],
  [CONNECTORS_IDS.GOOGLE_ANALYTICS_4]: [SOURCE_IDS.GOOGLE_ANALYTICS],
  [CONNECTORS_IDS.GOOGLE_KEYWORD_SEARCH]: [SOURCE_IDS.GOOGLE_KEYWORDS],
  [CONNECTORS_IDS.GOOGLE_SEARCH_CONSOLE]: [SOURCE_IDS.GOOGLE_SEARCH],
  [CONNECTORS_IDS.INSTAGRAM_BUSINESS]: [SOURCE_IDS.INSTAGRAM],
  [CONNECTORS_IDS.INSTAGRAM_INSIGHTS]: [SOURCE_IDS.INSTAGRAM],
  [CONNECTORS_IDS.KLAVIYO]: [SOURCE_IDS.KLAVIYO],
  [CONNECTORS_IDS.SEPHORA]: [SOURCE_IDS.SEPHORA],
  [CONNECTORS_IDS.SHOPIFY]: [SOURCE_IDS.SHOPIFY],
  [CONNECTORS_IDS.SPS]: [SOURCE_IDS.SPS],
  [CONNECTORS_IDS.TARGET]: [SOURCE_IDS.TARGET],
  [CONNECTORS_IDS.TIKTOK]: [SOURCE_IDS.TIKTOK],
  [CONNECTORS_IDS.TIKTOK_ADS]: [SOURCE_IDS.TIKTOK_ADS],
  [CONNECTORS_IDS.YOTPO]: [SOURCE_IDS.YOTPO],
  [CONNECTORS_IDS.YOUTUBE_ANALYTICS]: [SOURCE_IDS.YOUTUBE],
}
