import { useCallback, useMemo } from "react"
import {
  type Query as TCubeQuery,
  ResultSet,
  TimeDimensionGranularity,
} from "@cubejs-client/core"

import { CUBE_QUERY_KEYS_MAP, getCubeQueryBase } from "../../constants"
import { useAppSelector } from "~/ui-rtk/app/hooks"
import { selectCurrentCompany } from "~/ui-rtk/app/selectors/company.selector"
import useCampaignAdSetAdDetails, {
  getWeekYearMonthQuarterYearFilters,
  prepareAllItemsDetails,
  prepareValueOverTimeData,
  useAllCampaignsAdSetsAdsDetails,
} from "~/ui-rtk/hooks/campaign-adset-ad-details"
import { getPivotData } from "~/ui-rtk/utils/cube"

import {
  GranularityMapping,
  MetricsMapping,
} from "~/ui-rtk/constants/metrics-mapping"
import { BrandMediaAggregationType } from "~/ui-rtk/constants/charts"

const defaultPrefixKey = "company_media_metrics_weekly_ads"

const requests: Record<string, TCubeQuery> = {}
const results: Record<string, any> = {}

const ALL = "ALL"

export const useAllAdsCubeQuery = (
  date: string,
  granularity: BrandMediaAggregationType,
) => {
  const { postProcess, getCubePrefix } =
    MetricsMapping.brand_media_optimization_ads_table
  const prefixKey = getCubePrefix
    ? getCubePrefix({
        granularity: GranularityMapping[granularity],
      })
    : defaultPrefixKey
  const currentCompany = useAppSelector(selectCurrentCompany)
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const campaignId = ALL
  const adSetId = ALL
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}.${adSetId}.${date}`
  const queryAllCampaignKey = `${cacheKey}.all`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const purchaseKey = `${prefixKey}.purchases`

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdsFilters = useMemo(
    () => getWeekYearMonthQuarterYearFilters(date, prefixKey, granularity),
    [prefixKey, date, granularity],
  )

  const allAdsQuery = useMemo(() => {
    if (!requests[queryAllCampaignKey]) {
      requests[queryAllCampaignKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [...campaignQuery.dimensions, campaignIdKey],
        filters: allAdsFilters,
      }
    }
    return requests[queryAllCampaignKey]
  }, [
    queryAllCampaignKey,
    allAdsFilters,
    purchaseKey,
    campaignIdKey,
    campaignQuery,
  ])

  const { [CUBE_QUERY_KEYS_MAP.ALL]: allAdsSet, isLoading } =
    useAllCampaignsAdSetsAdsDetails(allAdsQuery)

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdsSet) {
    const pivotData = getPivotData(allAdsSet as ResultSet<any>, allAdsQuery)
    const processedPivotData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData
    results[cacheKey].allAdsDetails = prepareAllItemsDetails(processedPivotData)
  }

  const { allAdsDetails } = results[cacheKey]

  return { allAdsDetails, isLoading }
}

export const useAdQubeQuery = (
  date: string,
  adId: string,
  adSetId = ALL,
  campaignId = ALL,
  granularity: BrandMediaAggregationType,
) => {
  const { postProcess, getCubePrefix } =
    MetricsMapping.brand_media_optimization_ads_table
  const prefixKey = getCubePrefix
    ? getCubePrefix({
        granularity: GranularityMapping[granularity],
      })
    : defaultPrefixKey
  const currentCompany = useAppSelector(selectCurrentCompany)
  const currentCompanyId = currentCompany?.id ?? "-company-not-set-"
  const cacheKey = `${prefixKey}.${currentCompanyId}.${campaignId}.${adSetId}.${date}`
  const queryAllAdsKey = `${cacheKey}.all`
  const queryValueOverTimeKey = `${cacheKey}.${campaignId}.${adId}.value`

  const yearKey = `${prefixKey}.year`
  const campaignIdKey = `${prefixKey}.campaign_id`
  const adSetIdKey = `${prefixKey}.adset_id`
  const adIdKey = `${prefixKey}.ad_id`
  const purchaseKey = `${prefixKey}.purchases`

  let customKey: string
  switch (granularity) {
    case BrandMediaAggregationType.WEEKLY: {
      customKey = `${prefixKey}.week`
      break
    }
    case BrandMediaAggregationType.MONTHLY: {
      customKey = `${prefixKey}.month`
      break
    }
    case BrandMediaAggregationType.QUARTERLY: {
      customKey = `${prefixKey}.quarter`
      break
    }
    case BrandMediaAggregationType.YEARLY: {
      customKey = ``
      break
    }
    default: {
      throw new Error("Wrong granularity")
    }
  }

  const campaignQuery = getCubeQueryBase(prefixKey)

  const allAdsFilters = useMemo(() => {
    const filters = getWeekYearMonthQuarterYearFilters(
      date,
      prefixKey,
      granularity,
    )
    if (adSetId !== ALL) {
      filters.push({
        dimension: adSetIdKey,
        operator: "equals",
        values: [adSetId],
      })
    } else if (campaignId !== ALL) {
      filters.push({
        dimension: campaignIdKey,
        operator: "equals",
        values: [campaignId],
      })
    }
    return filters
  }, [prefixKey, date, campaignId, adSetId, granularity])

  const allAdsQuery = useMemo(() => {
    if (!requests[queryAllAdsKey]) {
      requests[queryAllAdsKey] = {
        ...campaignQuery,
        dimensions: [...campaignQuery.dimensions, adIdKey],
        filters: allAdsFilters,
      }
    }
    return requests[queryAllAdsKey]
  }, [queryAllAdsKey, adIdKey, allAdsFilters, campaignQuery])

  const valueOverTimeFilters = useMemo(() => {
    const filters = getWeekYearMonthQuarterYearFilters(
      date,
      prefixKey,
      granularity,
    )

    if (adSetId !== ALL) {
      filters.push({
        dimension: adSetIdKey,
        operator: "equals",
        values: [adSetId],
      })
    } else if (campaignId !== ALL) {
      filters.push({
        dimension: campaignIdKey,
        operator: "equals",
        values: [campaignId],
      })
    }

    return filters
  }, [
    date,
    campaignId,
    adSetId,
    granularity,
    prefixKey,
    adSetIdKey,
    campaignIdKey,
  ])

  const valueOverTimeQuery = useMemo(() => {
    if (!requests[queryValueOverTimeKey]) {
      requests[queryValueOverTimeKey] = {
        ...campaignQuery,
        measures: [...campaignQuery.measures, purchaseKey],
        dimensions: [
          ...campaignQuery.dimensions,
          customKey,
          yearKey,
          adIdKey,
        ].filter(key => !!key),
        filters: valueOverTimeFilters,
      }
    }
    return requests[queryValueOverTimeKey]
  }, [
    queryValueOverTimeKey,
    valueOverTimeFilters,
    campaignQuery,
    purchaseKey,
    customKey,
    yearKey,
    adIdKey,
  ])

  const {
    [CUBE_QUERY_KEYS_MAP.ALL]: allAdsSet,
    [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeSet,
    isLoading,
  } = useCampaignAdSetAdDetails({
    [CUBE_QUERY_KEYS_MAP.ALL]: allAdsQuery,
    [CUBE_QUERY_KEYS_MAP.VALUE_OVER_TIME]: valueOverTimeQuery,
  })

  const isCurrent = useCallback((metric: any) => metric.ad_id === adId, [adId])

  if (!results[cacheKey]) {
    results[cacheKey] = {}
  }

  if (allAdsSet) {
    const pivotData = getPivotData(allAdsSet as ResultSet<any>, allAdsQuery)
    const processedPivotData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData
    results[cacheKey].allAdsDetails = prepareAllItemsDetails(processedPivotData)
  }

  if (valueOverTimeSet) {
    const pivotData = getPivotData(
      valueOverTimeSet as ResultSet<any>,
      valueOverTimeQuery,
    )
    const processedPivotData = postProcess
      ? postProcess(pivotData, {
          granularity: GranularityMapping[
            granularity
          ] as TimeDimensionGranularity,
        })[0]
      : pivotData
    try {
      results[cacheKey].valueOverTimeDetails = prepareValueOverTimeData(
        processedPivotData,
        isCurrent,
        granularity,
      )
    } catch (e) {
      console.error(e)
    }
  }

  const { allAdsDetails, valueOverTimeDetails } = results[cacheKey]

  return { allAdsDetails, valueOverTimeDetails, isLoading }
}
