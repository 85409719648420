import { useMemo } from "react"
import { useUrlParamsStateArray } from "./urlParamsState"
import { SortingState } from "@tanstack/react-table"
import { URL_SORT_PARAM_KEY } from "../constants/url-params"

export type TSortingState = {
  id: string
  desc: boolean
}

export default function useUrlSorting(
  chartId = "",
  defaultSorting: TSortingState[] = [],
) {
  const urlKey = `${chartId}_${URL_SORT_PARAM_KEY}`
  const { state: rawSorting, setState: setSorting } =
    useUrlParamsStateArray<SortingState>(urlKey)

  const sorting = useMemo(
    () =>
      (rawSorting ?? []).length > 0
        ? rawSorting.map(sorting => ({
            ...sorting,
            desc: (sorting.desc as unknown as string) === "true",
          }))
        : defaultSorting,
    [rawSorting, defaultSorting],
  )

  return { urlKey, sorting, setSorting }
}
