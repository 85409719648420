import { useSearchParams } from "react-router-dom"

import { GuestLayout } from "~/ui-rtk/components/layout"
import TokenExpiredView from "../TokenExpiredView"
import ResetPasswordForm from "~/ui-rtk/components/features/forms/ResetPasswordForm"
import { useAuthControllerValidateTokenQuery } from "~/ui-rtk/api/authApi"
import { ContentBox } from "~/ui-rtk/components/ui/common"

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get("nonce")
  if (!token) {
    return <TokenExpiredView />
  }

  const { isError } = useAuthControllerValidateTokenQuery({ token })
  if (isError) {
    return <TokenExpiredView />
  }

  return (
    <GuestLayout>
      <ContentBox
        title="Create new password"
        subTitle="Choose a Password and confirm it to procceed"
      >
        <ResetPasswordForm token={token} />
      </ContentBox>
    </GuestLayout>
  )
}

export default ResetPassword
