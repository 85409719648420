import { emptySplitApi as api } from "./emptyApi"
import { CompanyDto, UserDto } from "./types"

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    companyControllerSignup: build.mutation<
      CompanyControllerSignupApiResponse,
      CompanyControllerSignupApiArg
    >({
      query: queryArg => ({
        url: `/company/signup`,
        method: "POST",
        body: queryArg.signupDto,
      }),
    }),
    companyControllerSignUpCompany: build.mutation<
      CompanyControllerSignUpCompanyApiResponse,
      CompanyControllerSignUpCompanyApiArg
    >({
      query: queryArg => ({
        url: "/company/sign-up",
        method: "POST",
        body: queryArg.signUpCompanyDto,
      }),
    }),
    companyControllerCreate: build.mutation<
      CompanyControllerCreateApiResponse,
      CompanyControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/company`,
        method: "POST",
        body: queryArg.createCompanyDto,
      }),
    }),
    companyControllerFindAllCompanies: build.query<
      CompanyControllerFindAllCompaniesApiResponse,
      CompanyControllerFindAllCompaniesApiArg
    >({
      query: () => ({ url: `/company` }),
    }),
    companyControllerGetCompanyById: build.query<
      CompanyControllerGetCompanyByIdApiResponse,
      CompanyControllerGetCompanyByIdApiArg
    >({
      query: queryArg => ({ url: `/company/${queryArg.id}` }),
    }),
    companyControllerUpdateCompany: build.mutation<
      CompanyControllerUpdateCompanyApiResponse,
      CompanyControllerUpdateCompanyApiArg
    >({
      query: queryArg => ({
        url: `/company/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateCompanyDto,
      }),
    }),
    companyControllerRemoveCompany: build.mutation<
      CompanyControllerRemoveCompanyApiResponse,
      CompanyControllerRemoveCompanyApiArg
    >({
      query: queryArg => ({ url: `/company/${queryArg.id}`, method: "DELETE" }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as generatedApi }
export type CompanyControllerSignupApiResponse = /** status 200  */ SignupDto
export type CompanyControllerSignupApiArg = {
  signupDto: SignupDto
}

export type CompanyControllerSignUpCompanyApiResponse = {
  user: UserDto
  company: CompanyDto
}
export type CompanyControllerSignUpCompanyApiArg = {
  signUpCompanyDto: SignUpCompanyDto
}

export type CompanyControllerCreateApiResponse = /** status 200  */ CompanyDto
export type CompanyControllerCreateApiArg = {
  createCompanyDto: CreateCompanyDto
}
export type CompanyControllerFindAllCompaniesApiResponse =
  /** status 200  */ CompanyDto[]
export type CompanyControllerFindAllCompaniesApiArg = void
export type CompanyControllerGetCompanyByIdApiResponse =
  /** status 200  */ CompanyDto
export type CompanyControllerGetCompanyByIdApiArg = {
  id: string
}
export type CompanyControllerUpdateCompanyApiResponse =
  /** status 200  */ CompanyDto
export type CompanyControllerUpdateCompanyApiArg = {
  id: string
  updateCompanyDto: UpdateCompanyDto
}
export type CompanyControllerRemoveCompanyApiResponse =
  /** status 200  */ CompanyDto
export type CompanyControllerRemoveCompanyApiArg = {
  id: string
}
export type SignupDto = {
  companyName: string
  name: string
  email: string
  pid: string
}

export type SignUpCompanyDto = {
  domain: string
  companyName: string
}

export type CreateCompanyDto = {
  name: string
}
export type UpdateCompanyDto = Partial<
  Pick<CompanyDto, "name" | "metadata" | "setupStatus">
>

export const {
  useCompanyControllerSignUpCompanyMutation,
  useCompanyControllerSignupMutation,
  useCompanyControllerCreateMutation,
  useCompanyControllerFindAllCompaniesQuery,
  useCompanyControllerGetCompanyByIdQuery,
  useCompanyControllerUpdateCompanyMutation,
  useCompanyControllerRemoveCompanyMutation,
  useLazyCompanyControllerGetCompanyByIdQuery,
} = injectedRtkApi
