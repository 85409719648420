import { Card } from "~/ui-rtk/components/ui/common"
import { TMetricCardProps } from "../types"
import { H5 } from "~/ui-rtk/components/ui/typography"
import useSources from "~/ui-rtk/hooks/sources"
import { renderSourceIcon } from "~/ui-rtk/components/ui/charts/TableVisualization/components/Cell/utils"
import { TSource } from "~/ui-rtk/constants/table-visualization-types"
import BrandValueOverSpendProgressBar from "../../details/components/BrandValueOverSpendProgressBar"
import { renderDetailsCaption } from "../../details/utils"
import { useCallback } from "react"
import { useAllAdsCubeQuery } from "../../details/AdDetails/hooks/cube-query"
import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

export default function AdCard(props: TMetricCardProps) {
  const { metric, openDrawer, dateRange, sortDesc: isTop, granularity } = props
  const { sources } = useSources()
  const adName = metric.ad_name
  const campaignId = metric.campaign_id
  const sourceName = metric.media_channel?.toUpperCase()
  const source = sources.get(sourceName)
  const innerTitle = adName.length > 50 ? `${adName.slice(0, 40)}...` : adName
  const date = dayjs(dateRange[0]).format("YYYY-MM-DD")

  const { allAdsDetails } = useAllAdsCubeQuery(date, granularity)

  const handleOpenDrawer = useCallback(() => {
    if (openDrawer) {
      openDrawer({
        props: {
          metric,
          date,
        },
        clickSourceId: ".ad-card *",
      })
    }
  }, [campaignId, metric])

  return (
    <Card
      rounded
      className="ad-card mt-3 bg-basic-dark-blue border border-basic-blue"
    >
      <div className="grid grid-cols-10">
        <div className="col-span-9">
          <H5 className="font-bold overflow-hidden text-ellipsis h-11 text-4 leading-2">
            {innerTitle}
          </H5>
        </div>
        <div className="flex items-center justify-end">
          {renderSourceIcon({
            name: source?.name,
            icon: source?.icon,
          } as TSource)}
        </div>
      </div>
      <div className="mt-3 text-sm h-20 leading-1.5 overflow-hidden text-ellipsis">
        {renderDetailsCaption(
          metric,
          allAdsDetails ?? {},
          "ad",
          sourceName.toLowerCase(),
        )}
      </div>
      <BrandValueOverSpendProgressBar
        metric={metric}
        allDetails={allAdsDetails ?? {}}
        label="ad"
        showSpend={!isTop}
      />
      <div
        onClick={handleOpenDrawer}
        className="pt-3 flex justify-end text-xs cursor-pointer"
      >
        <span className="bg-gradient-to-r from-pink-orange-gradient-start to-pink-orange-gradient-end text-transparent bg-clip-text">
          Deep Dive ↗
        </span>
      </div>
    </Card>
  )
}
