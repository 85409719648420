import React, { useCallback, useMemo, useRef } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { useStableNavigate } from "~/ui-rtk/utils/StableNavigateContext"
import { isEmpty } from "lodash"
import { useIdleTimer } from "react-idle-timer"

import { LogoSvg } from "~/ui-rtk/components/ui/svg/business/LogoSvg"
import { ProfileMenu } from "~/ui-rtk/components/layout/Topbar/components"
import { Button } from "~/ui-rtk/components/ui/controls"
import PagePermissionGuard from "~/ui-rtk/components/features/guards/PagePermissionGuard"

import ConfirmEmail from "../ConfirmEmail"
import { PricingDetails } from "../PricingDetails"
import { CheckInbox, AccountDetails } from "./BrandInfo"

import { useSessionHistory } from "./hooks/useSessionHistory"
import {
  guestRoutes,
  sharedRoutes,
  onboardingRoutes,
} from "~/ui-rtk/constants/routes"
import { ConnectionFlowPage, ICanRefetchEverything } from "./ConnectionFlow"
import AuthenticateConnector from "../AuthenticateConnector"
import { AdhocFivetranConnection } from "../AdhocFivetranConnection"
import {
  useConnectionFlowIgnored,
  useOnboardingComplete,
  useOnboardingEnabled,
} from "~/ui-rtk/hooks/onboarding-flow"

type Route = keyof typeof onboardingRoutes

const MARATHONDATA_CO_NAME = "MARATHONDATA_CO_ONBOARDING"

const routeMap = {
  [onboardingRoutes.KEYWORDS]: onboardingRoutes.SOURCES,
  [onboardingRoutes.DATA_PENDING]: onboardingRoutes.KEYWORDS,
  [onboardingRoutes.PACKAGE]: onboardingRoutes.KEYWORDS,
}

const Onboarding = () => {
  const navigate = useStableNavigate()
  const { session, redirectRoute, refetchSession } = useSessionHistory()

  const isOnboardingEnabled = useOnboardingEnabled()
  const isSetupStatusComplete = useOnboardingComplete()
  const isConnectionFlowIgnored = useConnectionFlowIgnored()
  const flowPage = useRef<ICanRefetchEverything>(null)

  const onActive = () => {
    refetchSession().catch(() => null)
    if (flowPage.current) {
      flowPage.current?.refetchEverything()
    }
  }

  useIdleTimer({
    onActive,
    timeout: 5_000, // 5 seconds
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
    name: MARATHONDATA_CO_NAME,
  })

  const withGuard = (children: React.ReactNode, route: Route) => {
    const isAllowed = useMemo(() => {
      if (
        !isOnboardingEnabled ||
        isSetupStatusComplete ||
        isConnectionFlowIgnored
      ) {
        return false
      }
      switch (route) {
        case "CHECK_INBOX":
          return !session?.user.isConfirmed

        case "ACCOUNT_DETAILS": {
          const isConfirmed = Boolean(session?.user.isConfirmed)
          const hasCompany = !isEmpty(session?.currentCompany)
          return isConfirmed && !hasCompany
        }

        default:
          return true
      }
    }, [session])

    return (
      <PagePermissionGuard isAllowed={isAllowed}>
        {children}
      </PagePermissionGuard>
    )
  }

  const handleNavigate = (route: string) => () => {
    navigate(route)
  }

  const renderBackButton = useCallback(() => {
    const pathname = location.pathname
    const prevRoute = routeMap[pathname]
    if (!prevRoute) {
      return
    }

    return (
      <Button variant={{ variant: "text" }} onClick={handleNavigate(prevRoute)}>
        Back
      </Button>
    )
  }, [location])

  return (
    <div className="relative flex flex-col justify-center flex-1 min-h-full px-6 py-12 overflow-hidden lg:px-8 bg-basic-black text-gray-ligh">
      <div className="absolute top-0 left-0 right-0 z-50">
        <header className="flex items-center justify-between px-4 py-3">
          <LogoSvg width={177} height={26} />
          <ProfileMenu config={{ disabledActions: ["settings"] }} />
        </header>
      </div>

      <div>
        <div>{renderBackButton()}</div>

        <Routes>
          <Route
            path={onboardingRoutes.CHECK_INBOX}
            element={withGuard(<CheckInbox />, "CHECK_INBOX")}
          />
          <Route
            path={onboardingRoutes.ACCOUNT_DETAILS}
            element={withGuard(<AccountDetails />, "ACCOUNT_DETAILS")}
          />
          <Route
            path={guestRoutes.PRICING_DETAILS}
            element={<PricingDetails />}
          />
          <Route
            path={onboardingRoutes.CONNECTION_FLOW}
            element={withGuard(
              <ConnectionFlowPage ref={flowPage} />,
              "CONNECTION_FLOW",
            )}
          />
          <Route
            path={onboardingRoutes.AUTHENTICATE_CONNECTOR}
            element={
              <AuthenticateConnector
                redirectTo={onboardingRoutes.CONNECTION_FLOW}
              />
            }
          />
          <Route
            path={sharedRoutes.FIVETRAN_CONNECTION}
            element={
              <AdhocFivetranConnection
                redirectTo={onboardingRoutes.CONNECTION_FLOW}
              />
            }
          />
          <Route path={guestRoutes.CONFIRM_EMAIL} element={<ConfirmEmail />} />
          <Route path="*" element={<Navigate to={redirectRoute} />} />
        </Routes>
      </div>
    </div>
  )
}

export default Onboarding
