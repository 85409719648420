import React, { useMemo, useState } from "react"
import { Column } from "@tanstack/react-table"

// import { ChipField } from "~/ui-rtk/components/ui/controls/ChipField"

import {
  FILTER_OPERATOR,
  TTextOperator,
  TableVisualizationColumnFilter,
  type TMetric,
  type TableVisualizationColumnDef,
} from "~/ui-rtk/constants/table-visualization-types"
import ChipInput from "~/ui-rtk/components/ui/controls/ChipsInput"
import { Select } from "~/ui-rtk/components/ui/controls"
import { Divider } from "~/ui-rtk/components/ui/common"

type SearchFilterProps = {
  column: Column<TMetric, unknown>
  onChange: (key: string, value: string[], operator: TTextOperator) => void
  defaultFilter?: TableVisualizationColumnFilter
}

const KeywordFilter: React.FC<SearchFilterProps> = ({
  column,
  onChange,
  defaultFilter,
}) => {
  const columnDef = column.columnDef as TableVisualizationColumnDef

  const textOperatorOptions = useMemo(
    () =>
      Object.keys(FILTER_OPERATOR).map(key => ({
        value: key,
        label: FILTER_OPERATOR[key as TTextOperator],
      })),
    [],
  )

  const [innerKeywords, setInnerKeywords] = useState<string[]>(
    (defaultFilter?.value as string[]) ?? [],
  )
  const [innerTextOperator, setInnerTextOperator] = useState<TTextOperator>(
    defaultFilter?.operator ?? "contains",
  )

  const handleSelectTextOperator = (value: TTextOperator) => {
    setInnerTextOperator(value)
    if (innerKeywords.length > 0) {
      onChange(columnDef.key, innerKeywords, value)
    }
  }

  const handleSearch = (values: string[]) => {
    setInnerKeywords(values)
    onChange(columnDef.key, values, innerTextOperator)
  }

  return (
    <div className="space-y-3">
      <label className="ml-1 font-semibold">Keywords</label>
      <Select
        defaultValue={innerTextOperator}
        options={textOperatorOptions}
        className="bg-basic-blue"
        onChange={$ev =>
          handleSelectTextOperator(
            ($ev.target as HTMLSelectElement).value as TTextOperator,
          )
        }
      />
      <Divider />
      <ChipInput
        value={innerKeywords}
        inputClassName="bg-basic-blue"
        onChange={handleSearch}
        defaultValue={defaultFilter?.value as string[]}
      />
    </div>
  )
}

export default KeywordFilter
