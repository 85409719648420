import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import DatePicker from "../DatePicker"
import CompareDates from "./components/CompareDates"
import { Button, Checkbox, Input } from "~/ui-rtk/components/ui/controls"
import { Popover } from "~/ui-rtk/components/ui/common"
import { CalendarSvg } from "~/ui-rtk/components/ui/svg/essentials"
import { ChevronDownSvg } from "~/ui-rtk/components/ui/svg/arrow"
import usePeriodPicker from "./hooks/usePeriodPicker"

import { cn } from "~/ui-rtk/utils/tailwind-utils"
import { datePresets } from "./presets"
import { generateDateRange } from "./utils"

import {
  RangeTags,
  type TRangeType,
  type DateOrNull,
  TDateRestrictions,
} from "./types"

import useDate from "~/ui-rtk/hooks/date"
const dayjs = useDate()

import "./index.scss"

export type TPeriodPickerProps = {
  dateRange?: TRangeType
  compareRange?: TRangeType | null
  opacity?: number
  onChange?: (dateRange: TRangeType, compareRange: TRangeType | null) => void
  classes?: Partial<{
    input: string
  }>
  restrictions?: TDateRestrictions
  comparisonEnabled?: boolean
}

const PICKER_DAY_CLASS_NAME = "react-datepicker__day"

const PeriodPicker: React.FC<TPeriodPickerProps> = ({
  opacity,
  dateRange,
  compareRange,
  classes,
  onChange,
  restrictions,
  comparisonEnabled = false,
}) => {
  const startDateRef = useRef<HTMLInputElement>(null)
  const startDateCompareRef = useRef<HTMLInputElement>(null)

  const { state, actions } = usePeriodPicker(
    dateRange,
    compareRange,
    restrictions,
    comparisonEnabled,
  )
  const [delayedUpdate, setDelayedUpdate] = useState(false)

  const inputValue = `${dayjs(state.default.rangeStartDate).format("MMM D, YYYY")} - ${dayjs(state.default.rangeEndDate).format("MMM D, YYYY")}${
    compareRange && state.isCompare
      ? ` vs ${dayjs(state.default.compareStartDate).format("MMM D, YYYY")} - ${dayjs(
          state.default.compareEndDate,
        ).format("MMM D, YYYY")}`
      : ""
  }`

  const updatePeriodPicker = () => {
    onChange?.(
      [
        dayjs(state.rangeStartDate).startOf("day").toDate(),
        dayjs(state.rangeEndDate).startOf("day").toDate(),
      ],
      state.isCompare &&
        state.compareRangeStartDate &&
        state.compareRangeEndDate
        ? [
            dayjs(state.compareRangeStartDate).startOf("day").toDate(),
            dayjs(state.compareRangeEndDate).startOf("day").toDate(),
          ]
        : null,
    )
    void actions.close()
  }

  const handleFocusInput = (tag: RangeTags) => {
    void actions.focusInput(tag)
  }

  const handlePickDate = useCallback(
    (date: DateOrNull) => {
      void actions.updateRange(date, state.focusedInputTag)
    },
    [actions.updateRange, state.focusedInputTag],
  )

  const handleReset = () => {
    void actions.reset()
  }

  const highlightWithRanges: Array<Record<string, Date[]>> = [
    {
      "react-datepicker__day--highlight-range": generateDateRange(
        state.rangeStartDate,
        state.rangeEndDate,
      ),
    },
    {
      "react-datepicker__day--highlight-range-edge": [
        state.rangeStartDate,
        state.rangeEndDate,
      ],
    },
    {
      "react-datepicker__day--highlight-compare-range": state.isCompare
        ? generateDateRange(
            state.compareRangeStartDate,
            state.compareRangeEndDate,
          )
        : [],
    },
  ]

  const handleTab = (idx: number) => {
    if (idx === 0 && startDateCompareRef.current) {
      startDateCompareRef.current.focus()
    } else {
      startDateRef.current?.focus()
    }
  }

  const handlePreviewFieldBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      const classList = event.relatedTarget?.classList
      const isDaySelectionClick = classList?.contains(PICKER_DAY_CLASS_NAME)
      if (!isDaySelectionClick) {
        actions.clearManualChanging()
      }
    },
    [actions.clearManualChanging],
  )

  useEffect(() => {
    if (delayedUpdate) {
      updatePeriodPicker()
      setDelayedUpdate(false)
    }
  }, [delayedUpdate])

  const pickerStyles = useMemo(() => ({ opacity }), [opacity])

  return (
    <div className="rounded-md ui-rtk-period-picker" style={pickerStyles}>
      <Popover
        relative
        handler={() => (
          <Input
            readOnly
            value={inputValue}
            className={cn(
              "rounded-md border-basic-blue",
              state.isCompare ? "min-w-125" : "min-w-75",
              classes?.input,
            )}
            appendNode={<ChevronDownSvg className="cursor-pointer" />}
            prependNode={<CalendarSvg size={18} />}
          />
        )}
      >
        {closePopup => (
          <div className="container px-6 py-4 mt-2 border rounded-2xl border-basic-blue bg-basic-dark-blue">
            <div className="flex gap-10">
              <div className="relative pt-2 border-r border-r-basic-blue space-y-2 w-56">
                <p className="font-bold text-basic-white">Presets</p>
                <ul className="flex flex-col items-start pr-6">
                  {(state.isCompare
                    ? datePresets.compareRangePresets
                    : datePresets.rangePresets
                  ).map(preset => {
                    const isSelected = state.isCompare
                      ? state.comparePreset === preset.id
                      : state.preset === preset.id

                    return (
                      <button
                        key={preset.id}
                        className={cn(
                          "w-full px-3 py-3 font-semibold rounded-md text-start text-basic-grey-inactive hover:bg-basic-blue hover:text-white",
                          isSelected && "bg-basic-blue text-white",
                        )}
                        aria-label={preset.label}
                        onClick={actions.applyPreset(
                          preset,
                          state.focusedInputTag >= RangeTags.compareRangeStart,
                        )}
                      >
                        {preset.label}
                      </button>
                    )
                  })}
                </ul>
              </div>
              <div className="span-2">
                <DatePicker
                  inline
                  monthsShown={2}
                  minDate={state.minDate}
                  maxDate={state.maxDate}
                  highlightDates={highlightWithRanges}
                  selected={dateRange ? dateRange[0] : undefined}
                  onChange={handlePickDate}
                />
              </div>
              <div className="flex flex-col">
                <button
                  onClick={handleReset}
                  className="inline-flex self-end mb-5 font-semibold leading-5 text-basic-grey-inactive"
                >
                  Reset
                </button>
                <div className="space-y-4">
                  <CompareDates
                    startDate={state.rangeStartDate}
                    endDate={state.rangeEndDate}
                    startDateRef={startDateRef}
                    onChange={(
                      date: DateOrNull | undefined,
                      tag: RangeTags,
                      close = false,
                    ) => {
                      if (date) {
                        actions.updateRange(date, tag)
                        if (close) {
                          setDelayedUpdate(true)
                          closePopup()
                        }
                      }
                    }}
                    onFocus={handleFocusInput}
                    onTab={() => handleTab(0)}
                    onBlur={handlePreviewFieldBlur}
                  />
                  {comparisonEnabled && (
                    <>
                      <div className="flex flex-row items-center justify-center gap-2">
                        <Checkbox
                          checked={state.isCompare}
                          onChange={actions.toggleCompare}
                          className="rounded-sm w-4 h-4 checked:bg-blue checked:text-blue"
                        />{" "}
                        <p className="text-basic-white">Compare to</p>
                      </div>
                      {state.isCompare ? (
                        <CompareDates
                          startDate={state.compareRangeStartDate}
                          endDate={state.compareRangeEndDate}
                          startDateRef={startDateCompareRef}
                          onChange={(
                            date: DateOrNull | undefined,
                            tag: RangeTags,
                            close = false,
                          ) => {
                            if (date) {
                              actions.updateRange(date, tag)
                              if (close) {
                                setDelayedUpdate(true)
                                closePopup()
                              }
                            }
                          }}
                          onFocus={handleFocusInput}
                          isComparisonValue
                          onTab={() => {
                            void handleTab(1)
                          }}
                          onBlur={handlePreviewFieldBlur}
                        />
                      ) : null}
                    </>
                  )}
                </div>
                <div className="flex w-full gap-4">
                  <Button
                    className="mt-8 flex-[50%]"
                    size="md"
                    disabled={
                      state.isCompare &&
                      !state.compareRangeEndDate &&
                      !state.compareRangeStartDate
                    }
                    onClick={() => {
                      void updatePeriodPicker()
                      void closePopup()
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    className="mt-8 flex-[50%] border border-basic-blue text-basic-grey-inactive hover:bg-basic-blue hover:text-basic-white"
                    size="md"
                    variant={{ variant: "text" }}
                    disabled={
                      state.isCompare &&
                      !state.compareRangeEndDate &&
                      !state.compareRangeStartDate
                    }
                    onClick={closePopup}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popover>
    </div>
  )
}

export default PeriodPicker
