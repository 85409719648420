import { useMemo, useState } from "react"
import { ProductFeature } from "~/api/services/product/enums"
import { useProductControllerGetAllQuery } from "~/ui-rtk/api/productApi"
import {
  CreateCheckoutSessionDto,
  useSubscriptionControllerCreateCheckoutSessionMutation,
} from "~/ui-rtk/api/subscriptionApi"
import { SubscriptionProduct } from "~/ui-rtk/components/features/guards/SubscriptionGuard/components/SubscriptionDialog/components/SubscriptionProduct/SubscriptionProduct"
import { useCompanyRevenueQuery } from "~/ui-rtk/components/features/guards/SubscriptionGuard/components/SubscriptionDialog/connect"
import { getPricingTier } from "~/ui-rtk/components/features/guards/SubscriptionGuard/components/SubscriptionDialog/utils"
import { isNumeric } from "~/ui-rtk/utils/format"
import { type Query as TCubeQuery } from "@cubejs-client/core"
import { useCubeQuery } from "~/ui-rtk/hooks/cube"
import { getPivotData } from "~/ui-rtk/utils/cube"
import {
  generalPostProcess,
  MetricsMapping,
} from "~/ui-rtk/constants/metrics-mapping"

function queryTheCube(query: TCubeQuery) {
  const { resultSet, isLoading } = useCubeQuery(query)

  const dataSource = useMemo(() => {
    if (!resultSet) {
      return null
    }

    const columns = resultSet.tableColumns()
    const pivotData = getPivotData(resultSet, query)
    return generalPostProcess(pivotData, columns)
  }, [resultSet])

  return { isLoading, dataSource }
}

export default function useConnect() {
  const [createCheckoutSessionAsync] =
    useSubscriptionControllerCreateCheckoutSessionMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data = [], isLoading: isProductsLoading } =
    useProductControllerGetAllQuery()

  const {
    dataSource,
    revenue,
    isLoading: isRevenueLoading,
  } = useCompanyRevenueQuery()

  const product: SubscriptionProduct | null = useMemo(() => {
    if (!data || !dataSource) {
      return null
    }

    const product = data.find(p => p.metadata.featureId === ProductFeature.BASE)
    if (!product) {
      return null
    }

    let price: number | null = null
    if (typeof revenue === "number" && isNumeric(revenue)) {
      const tier = getPricingTier(revenue, product?.tiers)
      price = tier?.amount || null
    }

    return { ...product, price }
  }, [data, revenue, dataSource])

  const createCheckoutSession = async (dto: CreateCheckoutSessionDto) => {
    setIsSubmitting(true)
    try {
      const { checkoutUrl } = await createCheckoutSessionAsync({
        createCheckoutSessionDto: dto,
      }).unwrap()

      window.location.href = checkoutUrl
    } finally {
      setIsSubmitting(false)
    }
  }

  const { dataSource: organicMedia, isLoading: isLoadingOrganicMedia } =
    queryTheCube({
      ...MetricsMapping.subscribe_page_organic_media_card.q,
    } as TCubeQuery)

  const { dataSource: paidMedia, isLoading: isLoadingPaidMedia } = queryTheCube(
    {
      ...MetricsMapping.subscribe_page_paid_media_card.q,
    } as TCubeQuery,
  )

  const { dataSource: brandValue, isLoading: isLoadingBrandValue } =
    queryTheCube({
      ...MetricsMapping.subscribe_page_brand_value_card.q,
    } as TCubeQuery)

  return {
    createCheckoutSession,
    isSubmitting,
    price: product?.price,
    organicMedia,
    isLoadingOrganicMedia,
    paidMedia,
    isLoadingPaidMedia,
    brandValue,
    isLoadingBrandValue,
    isLoading: isProductsLoading || isRevenueLoading,
  }
}
